import React, { useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import { ReactComponent as PlusIcon } from "../images/plus.svg";
import { UserContext } from "../contexts";
import { useMutation } from "@apollo/client";
import { UPDATE_USER_IMAGE } from "../mutations";
import { parseError } from "../errors";
import { base64Encode } from "../upload";

const UserImageForm = props => {

  const { formClass, headingClass, buttonClass } = props;

  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState(null);
  const [user, setUser] = useContext(UserContext);
  const ref = useRef();

  const imgSrc = file ? URL.createObjectURL(file) : (user.image && file !== "") ? `${process.env.REACT_APP_MEDIA}/${user.image}` : "";

  const circleClass = "w-32 h-32 rounded-full";

  const fileChanged = e => {
    setFile(e.target.files[0]);
  }

  const clear = () => {
    setFile("");
    setErrors(null);
  }

  const [updateImage, updateImageMutation] = useMutation(UPDATE_USER_IMAGE, {
    onCompleted: data => {
      setUser(data.updateUserImage.user);
      setFile(null);
      ref.current.value = "";
    },
    onError: error => setErrors(parseError(error))
  });

  const onSubmit = async e => {
    e.preventDefault();
    const isbase64 = ["true", "yes"].includes((process.env.REACT_APP_USE_BASE64 || "").toLowerCase());
    const blob = isbase64 ? await base64Encode(file) : file; 
    if (file && file.size > 2097152) {
      setErrors({validation: {"image": ["Image must be less than 2MB"]}})
    } else {
      updateImage({
        variables: {image: blob}
      });
    }
  }

  const validation = errors?.validation || {};

  return (
    <form className={formClass} onSubmit={onSubmit}>

      <div className={headingClass}>Profile Picture</div>

      <div className={`bg-gray-200 relative z-10 -mb-4 ${circleClass}`}>
        <label className={`flex absolute top-0 left-0 z-20 transition-all justify-center items-center group cursor-pointer ${imgSrc ? "hover:bg-[#E0E0E040]" : ""} ${circleClass} ${errors ? "border-2 border-[#E90E3A] border-opacity-100" : "border border-opacity-20"}`} htmlFor="photo">
          <PlusIcon
            className={`w-20 h-20 transition-opacity ${imgSrc ? "opacity-0 group-hover:opacity-70" : "opacity-50 group-hover:opacity-80"}`}
            fill="#A9A9A9" alt=""
          />
        </label>
        <input
          type="file" className="w-px h-px opacity-0" id="photo" ref={ref}
          accept="image/png, image/gif, image/jpeg"
          onChange={fileChanged}
        />
        {imgSrc && <img src={imgSrc} alt="" className={`absolute top-0 left-0 object-cover ${circleClass}`} />}
      </div>

      {validation.image &&  (
        <div className="mt-6 max-w-xs sm:ml-36 sm:absolute sm:top-28 sm:-mb-12">
          {validation.image.map((error, index) => <div key={index} className="text-xs text-[#E90E3A]">{error}</div>)}
        </div>
      )}

      <div className="flex gap-2 items-end">
        <Button className={buttonClass} type="submit" loading={updateImageMutation.loading}>
          Save Photo
        </Button>
        {imgSrc && (
          <Button className="btn-tertiary text-[#C33B53]" type="button" onClick={clear}>
            Clear
          </Button>
        )}
      </div>

    </form>
  );
};

UserImageForm.propTypes = {
  formClass: PropTypes.string.isRequired,
  headingClass: PropTypes.string.isRequired,
  buttonClass: PropTypes.string.isRequired,
};

export default UserImageForm;