import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as GenomeIcon } from "../images/genome.svg";
import { ReactComponent as UserIcon } from "../images/user.svg";
import { ReactComponent as GroupIcon } from "../images/group.svg";
import { ReactComponent as PipelineIcon } from "../images/pipeline.svg";
import { ReactComponent as ExecutionIcon } from "../images/execution.svg";
import { ReactComponent as DataIcon } from "../images/data.svg";
import { ReactComponent as SampleIcon } from "../images/sample.svg";
import { ReactComponent as ProjectIcon } from "../images/project.svg";
import { ReactComponent as EditIcon } from "../images/edit.svg";
import { ReactComponent as PrivateIcon } from "../images/private.svg";
import { ReactComponent as PublicIcon } from "../images/public.svg";
import OrganismIcon from "./OrganismIcon";
import { Link } from "react-router-dom";

const ObjectTitle2 = props => {

  const { type, title, editUrl, isPrivate, corner } = props;

  const Icon = {
    project: ProjectIcon,
    sample: SampleIcon,
    execution: ExecutionIcon,
    data: DataIcon,
    pipeline: PipelineIcon,
    UserType: UserIcon,
    GroupType: GroupIcon,
    OrganismType: OrganismIcon,
    GenomeType: GenomeIcon,
  }[type];

  const breakTitle = !title.props && !title.includes(" ");

  const VisibilityIcon = isPrivate ? PrivateIcon : PublicIcon;

  const longTitle = title.length > 50;

  const h1SizeClass = longTitle ? "text-lg sm:text-xl lg:text-2xl xl:text-3xl" : "text-xl sm:text-2xl lg:text-3xl";
  const iconClass = longTitle ? "bottom-0.5 w-4 sm:w-5 sm:bottom-1 lg:w-6 lg:bottom-1.5 xl:w-7" : "w-5 bottom-1 sm:w-6 sm:bottom-1.5 lg:w-7";

  return (
    <div className={`flex flex-row justify-between gap-x-4 gap-y-2 sm:gap-y-3 lg:gap-x-8 ${props.className || ""}`}>
      <div className="flex flex-wrap items-center gap-x-4 gap-y-1 sm:gap-y-2 md:gap-x-6">
        <div className="">
          <Icon className={`inline h-auto relative ${iconClass}`} />
          <h1 className={`m-0 text-[#595959] ml-1 inline sm:ml-1.5 lg:ml-2  ${h1SizeClass} ${!breakTitle || "break-all"}`}>{title}</h1>
        </div>
        {editUrl && (
          <Link to={editUrl} className="flex items-baseline gap-1 text-[#595959] hover:text-black font-medium text-xs whitespace-nowrap sm:inline-flex sm:text-sm">
            <EditIcon className="w-3 relative top-px h-auto sm:w-4" />Edit {type}
          </Link>
        )}
      </div>
      <div className="flex gap-2 h-5 items-center sm:h-7">
        {corner}
        {isPrivate !== undefined && <VisibilityIcon className="h-full w-auto" />}
      </div>
    </div>
  );
};

ObjectTitle2.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  editUrl: PropTypes.string,
  isPrivate: PropTypes.bool,
  corner: PropTypes.object,
};

export default ObjectTitle2;