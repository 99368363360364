import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const SampleOrganismChart = props => {

  const { sampleCounts } = props;

  const organisms = JSON.parse(sampleCounts || "{}").organisms || {};

  const organismsData = Object.entries(organisms);

  const data = organismsData.filter(o => o[1]).map(([name, count]) => ({
    name: name,
    y: count,
  }))

  const options = {
    chart: {
      type: "pie", backgroundColor: null,
      style: {fontFamily: "Basier Circle"},
    },
    title: null,
    legend: {enabled: false},
    credits: {enabled: false},
    plotOptions: {
      pie: {
          shadow: false,
          center: ["50%", "50%"],
      }
    },
    tooltip: {pointFormat: "<b>{point.y}</b> samples<br/>"},
    series: [{
      data: data,
      size: "50%",
      innerSize: "30%",
    }],
    colors: ["#6ADB90", "#DDBF56", "#3BB2C2"],
  }

  return (
    <div className="w-full">
      <div className="font-medium mx-auto w-fit">Public samples by organism</div>
      <div className="h-72">
        <HighchartsReact
          highcharts={Highcharts} options={options}
          containerProps={{style: {height: "100%"}}}
        />
      </div>
    </div>
  );
};

SampleOrganismChart.propTypes = {
  sampleCounts: PropTypes.string
};

export default SampleOrganismChart;