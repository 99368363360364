import React from "react";
import { useState, useRef } from "react";
import { useMutation } from "@apollo/client";
import { UPLOAD_ANNOTATION_DATA } from "../mutations";
import { Link } from "react-router-dom";
import Button from "./Button";
import FileInput from "./FileInput";
import ProgressBar from "./ProgressBar";
import { ReactComponent as UploadIcon } from "../images/upload.svg";
import { parseError } from "../errors";
import { base64Encode } from "../upload";
import AnnotationErrorModal from "./AnnotationErrorModal";
import AnnotationWarningModal from "./AnnotationWarningModal";

const AnnotationUploader = props => {

  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(null);
  const [finalDataId, setFinalDataId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [annotationErrors, setAnnotationErrors] = useState(null);
  const [annotationWarnings, setAnnotationWarnings] = useState(null);
  const canceled = useRef(false);

  const CHUNK_SIZE = 1 * 1000 * 1000;

  const [uploadData,] = useMutation(UPLOAD_ANNOTATION_DATA);

  const onSubmit = async (e, ignoreWarnings) => {
    e.preventDefault();
    canceled.current = false;
    const chunkCount = Math.ceil(file.size / CHUNK_SIZE);
    setProgress(0);
    let dataId = null;
    const chunkNums = [...Array(chunkCount).keys()];
    for (let c = 0; c < chunkNums.length; c++) {
      const chunkNum = chunkNums[c];
      const start = CHUNK_SIZE * chunkNum;
      const isLast = chunkNum === chunkCount - 1;
      const expectedFileSize = chunkNum * CHUNK_SIZE;
      const chunk = file.slice(start, start + CHUNK_SIZE);
      const isbase64 = ["true", "yes"].includes((process.env.REACT_APP_USE_BASE64 || "").toLowerCase());
      const blob = isbase64 ? await base64Encode(chunk) : chunk;


      let resp;
      const MAX_RETRIES = 5;
      for (let attempt = 0; attempt < MAX_RETRIES; attempt++) {
        try {
          resp = await uploadData({variables: {
            blob, isLast, expectedFileSize, data: dataId,
            filename: file.name,
            ignoreWarnings
          }})
          break;
        } catch (error) {
          const errorObject = parseError(error);
          if (errorObject.networkError) {
            if (attempt < MAX_RETRIES - 1) {
              await new Promise(resolve => setTimeout(resolve, 1000 * (attempt + 1)));
              continue;
            } else {
              setErrorMessage("There was a network error when uploading the file.");
              return;
            }
          } else {
            const validation = errorObject?.validation || {};
            if (errorObject.networkError) {
              setErrorMessage("There was a network error when uploading the file.");
            } else if ("annotation" in validation) {
              setAnnotationErrors(validation);
            } else if ("warnings" in validation) {
              setAnnotationWarnings(validation.warnings);
            } else {
              const message = errorObject.message || Object.values(validation)[0][0];
              setErrorMessage("There was a problem uploading the file: " + message);
            }
            return
          }
        }
      }
      if (canceled.current === true) {
        canceled.current = false;
        return
      }

      dataId = resp.data.uploadAnnotationData.dataId;
      setProgress((chunkNum + 1) / chunkCount);
      if (isLast) {
        setFinalDataId(dataId);
      }

    }
  }

  const cancel = () => {
    setProgress(null);
    setFinalDataId(null);
    setFile(null);
    setErrorMessage("");
    setAnnotationErrors(null);
    setAnnotationWarnings(null);
    canceled.current = true;
  }

  const reset = () => {
    setProgress(null);
    setFinalDataId(null);
    setFile(null);
    setErrorMessage("");
    setAnnotationErrors(null);
    setAnnotationWarnings(null);
  }

  const canUpload = Boolean(file);
  const linkClass = "text-[#3B59C3] w-fit";

  const url = process.env.REACT_APP_DATA.replace("/data", "");

  return (
    <form className={`w-full ${props.className || ""}`} onSubmit={onSubmit}>
      {annotationErrors && (
        <AnnotationErrorModal errors={annotationErrors} setShowModal={setAnnotationErrors} />
      )}
      {annotationWarnings && (
        <AnnotationWarningModal
          warnings={annotationWarnings} setShowModal={setAnnotationWarnings}
          upload={onSubmit}
        />
      )}

      <div className="mb-4">
        <div className="text-[#515255] text-xl font-medium mb-2">Upload Annotation Sheet</div>
        <div className="mb-2">Select annotation sheet</div>
        <FileInput
          file={file} setFile={setFile} errorMessage={errorMessage}
          accept=".csv,.xlsx" small={true}
        />
      </div>

      {progress === null && (
        <Button type="submit" className={`btn-primary w-full gap-1.5 ${canUpload || "opacity-30 pointer-events-none"}`} disabled={!file}>
          <UploadIcon className="relative bottom-px" /> Upload Annotation
        </Button>
      )}

      {progress !== null && (
        <ProgressBar progress={progress} onCancel={cancel} />
      )}

      {finalDataId && (
        <div className="flex justify-between mt-2 text-sm text-[#3B59C3]">
          <Link to={`/data/${finalDataId}/`}>View file</Link>
          <div className="link" onClick={reset}>Upload another</div>
        </div>
      )}

      <div className="mt-4">
        <div className="text-sm font-medium mb-1">Annotation sheet templates:</div>
        <div className="flex flex-col text-xs gap-0.5">
          <a href={`${url}/annotation/RNA-Seq`} className={linkClass}>
            RNA-Seq Annotation Sheet
          </a>
          <a href={`${url}/annotation/scRNA-Seq`} className={linkClass}>
            scRNA-Seq Annotation Sheet
          </a>
          <a href={`${url}/annotation/ChIP-Seq`} className={linkClass}>
            ChIP-Seq Annotation Sheet
          </a>
          <a href={`${url}/annotation/CLIP`} className={linkClass}>
            CLIP Annotation Sheet
          </a>
          <a href={`${url}/annotation/generic`} className={linkClass}>
            Generic Annotation Sheet
          </a>
        </div>
      </div>
    </form>
  );
};

AnnotationUploader.propTypes = {
  
};

export default AnnotationUploader;