import { useQuery } from "@apollo/client";
import moment from "moment/moment";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDocumentTitle } from "../hooks";
import { GENOME } from "../queries";
import { fileSize } from "../utils";
import ExecutionTable from "../components/ExecutionTable";
import Base from "./Base";
import ObjectTitle from "../components/ObjectTitle";
import GenomeFiles from "../components/GenomeFiles";
import Tabs from "../components/Tabs";

const GenomePage = () => {

  const { id } = useParams();

  const [tab, setTab] = useState("Preparations");

  const { loading, data } = useQuery(GENOME, {variables: {id}});

  useDocumentTitle(data?.genome ? `${data.genome.name} - Flow` : "Flow");

  if (loading) return <Base loading={true} />
  
  if (!data.genome) return <Base notFound={true} />

  const genome = data.genome;

  return (
    <Base>
      <ObjectTitle
        object={genome} canEdit={false} className="mb-6"
        title={`${genome.name} (${genome.organism.name})`}
        organism={genome.organism}
      />
      <GenomeFiles genome={genome} className="mb-8" />
      <Tabs labels={["Preparations", "Analysis"]} selected={tab} setSelected={setTab} />
      {tab === "Preparations" && (
        <ExecutionTable
          executions={genome.executions.filter(e => e.pipelineVersion.pipeline.preparesGenome)}
          pageLength={15} noMessage="No preparations yet."
        />
      )}
      {tab === "Analysis" && (
        <ExecutionTable
          executions={genome.executions.filter(e => !e.pipelineVersion.pipeline.preparesGenome)}
          pageLength={15} noMessage="No analysis yet."
        />
      )}
    </Base>
  );
};

GenomePage.propTypes = {
  
};

export default GenomePage;