import React, { useState } from "react";
import PropTypes from "prop-types";
import { useRef } from "react";
import { ReactComponent as UploadIcon } from "../images/upload.svg";
import Toggle from "./Toggle";
import FileInput from "./FileInput";
import Button from "./Button";
import ProgressBar from "./ProgressBar";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { UPLOAD_DEMULTIPLEXED_DATA } from "../mutations";
import NonStandardWarningModal from "./NonStandardWarningModal";
import SampleSelector from "./SampleSelector";
import { parseError } from "../errors";
import { base64Encode } from "../upload";
import SampleMetadataForm from "./SampleMetadataForm";

const DemultiplexUploader = props => {

  const { organisms, projects } = props;

  const [pairedEnd, setPairedEnd] = useState(false);
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(null);
  const [existingSample, setExistingSample] = useState(null);

  const [name, setName] = useState(null);
  const [category, setCategory] = useState("");
  const [organism, setOrganism] = useState("");
  const [project, setProject] = useState("");

  const [sourceId, setSourceId] = useState("");
  const [sourceName, setSourceName] = useState(null);
  const [sourceText, setSourceText] = useState("");
  const [purificationTargetId, setPurificationTargetId] = useState("");
  const [purificationTargetName, setPurificationTargetName] = useState(null);
  const [purificationTargetText, setPurificationTargetText] = useState("");

  const [scientist, setScientist] = useState("");
  const [pi, setPi] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [purificationAgent, setPurificationAgent] = useState("");
  const [experimentalMethod, setExperimentalMethod] = useState("");
  const [condition, setCondition] = useState("");
  const [sequencer, setSequencer] = useState("");
  const [comments, setComments] = useState("");

  const [fivePrimeBarcodeSequence, setFivePrimeBarcodeSequence] = useState("");
  const [threePrimeBarcodeSequence, setThreePrimeBarcodeSequence] = useState("");
  const [threePrimeAdapterName, setThreePrimeAdapterName] = useState("");
  const [threePrimeAdapterSequence, setThreePrimeAdapterSequence] = useState("");
  const [read1Primer, setread1Primer] = useState("");
  const [read2Primer, setRead2Primer] = useState("");
  const [rtPrimer, setRtPrimer] = useState("");

  const [umiBarcodeSequence, setUmiBarcodeSequence] = useState("");
  const [umiSeparator, setUmiSeparator] = useState("");
  const [strandedness, setStrandedness] = useState("");
  const [rnaSelectionMethod, setRnaSelectionMethod] = useState("");

  const [geo, setGeo] = useState("");
  const [ena, setEna] = useState("");
  const [pubmed, setPubmed] = useState("");

  const [progress, setProgress] = useState(null);
  const [finalSampleId, setFinalSampleId] = useState(null);
  const [nonStandard, setNonStandard] = useState(null);

  const canceled = useRef(false);

  const CHUNK_SIZE = 1 * 1000 * 1000;

  const [uploadData,] = useMutation(UPLOAD_DEMULTIPLEXED_DATA);

  const onSubmit = async e => {
    e.preventDefault();
    canceled.current = false;
    setErrorMessage("");
    const files = pairedEnd ? [file1, file2] : [file1];
    const chunkCount = files.reduce((p, c) => p + Math.ceil(c.size / CHUNK_SIZE), 0);
    setProgress(0);
    const previousData = [];
    let totalChunksDone = 0;
    for (let file of files) {
      let dataId = null;
      let sampleId = null;
      const fileChunkCount = Math.ceil(file.size / CHUNK_SIZE);
      const chunkNums = [...Array(fileChunkCount).keys()];
      for (let c = 0; c < chunkNums.length; c++) {
        const chunkNum = chunkNums[c];
        const start = CHUNK_SIZE * chunkNum;
        const isLastData = chunkNum === fileChunkCount - 1;
        const isLastSample = isLastData && file === files[files.length - 1];
        const expectedFileSize = chunkNum * CHUNK_SIZE;
        const chunk = file.slice(start, start + CHUNK_SIZE);
        const isbase64 = ["true", "yes"].includes((process.env.REACT_APP_USE_BASE64 || "").toLowerCase());
        const blob = isbase64 ? await base64Encode(chunk) : chunk;
        let resp;
        const MAX_RETRIES = 5;
        let variables = {
          blob, isLastData, isLastSample, expectedFileSize, data: dataId,
          previousData, filename: file.name
        }
        if (existingSample) {
          variables.sample = existingSample.id;
        } else {
          variables = {...variables, ...{
            sampleName, category,
            organism: organismToUse, source: sourceName,
            purificationTarget: purificationTargetName, project,
            scientist, pi, organisation, purificationAgent, experimentalMethod,
            condition, sequencer, comments, sourceText, purificationTargetText,
            fivePrimeBarcodeSequence, threePrimeBarcodeSequence, threePrimeAdapterName,
            threePrimeAdapterSequence, read1Primer, read2Primer, rtPrimer,
            umiBarcodeSequence, umiSeparator, strandedness, rnaSelectionMethod,
            geo, ena, pubmed
          }}
        }
        for (let attempt = 0; attempt < MAX_RETRIES; attempt++) {
          try {
            resp = await uploadData({variables})
            break;
          } catch (error) {
            const errorObject = parseError(error);
            if (errorObject.networkError) {
              if (attempt < MAX_RETRIES - 1) {
                await new Promise(resolve => setTimeout(resolve, 1000 * (attempt + 1)));
                continue;
              } else {
                setErrorMessage("There was a network error when uploading the file.");
              }
            } else {
              setError(errorObject);
              canceled.current = true;
              setProgress(null);
              setFinalSampleId(null);
              setErrorMessage("There was a problem uploading the file - check the metadata below.");
            }
          }
        }
        dataId = resp.data.uploadDemultiplexedData.dataId;
        sampleId = resp.data.uploadDemultiplexedData.sampleId;
        if (canceled.current === true) {
          canceled.current = false;
          return
        }
        setProgress((chunkNum + 1 + totalChunksDone) / chunkCount);
        if (isLastData) {
          previousData.push(dataId);
          totalChunksDone += chunkNum + 1;
        }
        if (isLastSample) setFinalSampleId(sampleId);
      }
    }
  }

  const getSampleName = filename => {
    let name = filename;
    if (name.slice(-2) === "gz") name = name.slice(0, -3);
    return name.split(".").slice(0, -1).join(".");
  }

  const cancel = () => {
    setProgress(null);
    setFinalSampleId(null);
    setFile1(null);
    setFile2(null);
    setErrorMessage("");
    setError(null);
    canceled.current = true;
  }

  const reset = () => {
    setProgress(null);
    setFinalSampleId(null);
    setFile1(null);
    setFile2(null);
    setErrorMessage("");
    setError(null);
  }
  
  const canAnnotate = Boolean(file1) && (!pairedEnd || Boolean(file2));
  const sampleName = name === null && file1 ? getSampleName(file1.name) : name;
  const organismToUse = organism || organisms[0].id;
  
  const headingClass = "mb-1 font-medium text-[#515151]";

  return (
    <form onSubmit={onSubmit}>

      <Toggle
        value={pairedEnd}
        onChange={setPairedEnd}
        labelClass="text-xs sm:text-sm"
        trueLabel="Paired-end"
        falseLabel="Single-end"
        className="mb-4"
      />

      <div className="w-full flex flex-wrap gap-6 mb-6">
        <div className="w-full max-w-sm">
          <div className={headingClass}>Demultiplexed FASTQ {pairedEnd && " 1"}</div>
          <FileInput
            small={true}
            file={file1}
            setFile={setFile1}
            accept=".fq,.fastq,.gz"
            errorMessage={errorMessage}
          />
        </div>

        {pairedEnd && (
          <div className="w-full max-w-sm">
            <div className={headingClass}>Demultiplexed FASTQ 2</div>
            <FileInput
              small={true}
              file={file2}
              setFile={setFile2}
              accept=".fq,.fastq,.gz"
              errorMessage={errorMessage}
            />
          </div>
        )}
      </div>

      <div className="mb-4">
        <label className="text-sm font-medium mb-1">(Optional) Select existing sample to update:</label>
        <SampleSelector
          inputClass="bg-[#F3F3F3] rounded mb-1.5 w-full max-w-sm h-9 text-[#3B59C3] font-medium px-3"
          value={existingSample}
          setValue={setExistingSample}
          setOrganism={() => {}}
          alwaysOpaque={true}
          forceIsOwned={true}
        />
      </div>

      <div className="w-full max-w-sm">
        {progress === null && (
          <Button type="submit" className={`btn-primary w-full gap-1.5 ${canAnnotate || "opacity-30 pointer-events-none"}`} disabled={!canAnnotate}>
            <UploadIcon className="relative bottom-px" /> Upload Sample
          </Button>
        )}

        {progress !== null && (
          <ProgressBar progress={progress} onCancel={cancel} />
        )}

        {finalSampleId && (
          <div className="flex justify-between mt-2 text-sm text-[#3B59C3]">
            <Link to={`/samples/${finalSampleId}/`}>View sample</Link>
            <div className="link" onClick={reset}>Upload another</div>
          </div>
        )}
      </div>

      {canAnnotate && !existingSample && (
        <div className="mt-12 border-l-4 pl-4 border-[#6A81D2]">
          <div className="font-medium text-xl mb-4">Sample Annotation</div>
          <SampleMetadataForm
            name={sampleName} setName={setName} error={error} setError={setError}
            organism={organismToUse} setOrganism={setOrganism} organisms={organisms}
            category={category} setCategory={setCategory}
            project={project} setProject={setProject} projects={projects}
            sourceId={sourceId} setSourceId={setSourceId}
            sourceName={sourceName} setSourceName={setSourceName}
            sourceText={sourceText} setSourceText={setSourceText}
            purificationTargetId={purificationTargetId} setPurificationTargetId={setPurificationTargetId}
            purificationTargetName={purificationTargetName} setPurificationTargetName={setPurificationTargetName}
            purificationTargetText={purificationTargetText} setPurificationTargetText={setPurificationTargetText}
            scientist={scientist} setScientist={setScientist}
            pi={pi} setPi={setPi} organisation={organisation} setOrganisation={setOrganisation}
            experimentalMethod={experimentalMethod} setExperimentalMethod={setExperimentalMethod}
            purificationAgent={purificationAgent} setPurificationAgent={setPurificationAgent}
            condition={condition} setCondition={setCondition}
            sequencer={sequencer} setSequencer={setSequencer}
            comments={comments} setComments={setComments}
            fivePrimeBarcodeSequence={fivePrimeBarcodeSequence} setFivePrimeBarcodeSequence={setFivePrimeBarcodeSequence}
            threePrimeBarcodeSequence={threePrimeBarcodeSequence} setThreePrimeBarcodeSequence={setThreePrimeBarcodeSequence}
            threePrimeAdapterName={threePrimeAdapterName} setThreePrimeAdapterName={setThreePrimeAdapterName}
            threePrimeAdapterSequence={threePrimeAdapterSequence} setThreePrimeAdapterSequence={setThreePrimeAdapterSequence}
            read1Primer={read1Primer} setread1Primer={setread1Primer}
            read2Primer={read2Primer} setRead2Primer={setRead2Primer}
            rtPrimer={rtPrimer} setRtPrimer={setRtPrimer}
            umiBarcodeSequence={umiBarcodeSequence} setUmiBarcodeSequence={setUmiBarcodeSequence}
            umiSeparator={umiSeparator} setUmiSeparator={setUmiSeparator}
            strandedness={strandedness} setStrandedness={setStrandedness}
            rnaSelectionMethod={rnaSelectionMethod} setRnaSelectionMethod={setRnaSelectionMethod}
            geo={geo} setGeo={setGeo} ena={ena} setEna={setEna} pubmed={pubmed} setPubmed={setPubmed}
          />
        </div>
      )}
      {nonStandard && (
        <NonStandardWarningModal
          setShowModal={() => setNonStandard(null)}
          attribute={nonStandard[0]}
          value={nonStandard[1]}
          setValue={nonStandard[2]}
        />
      )}
    </form>
  );
};

DemultiplexUploader.propTypes = {
  projects: PropTypes.array.isRequired,
  organisms: PropTypes.array.isRequired,
};

export default DemultiplexUploader;