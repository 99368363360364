import React from "react";
import PropTypes from "prop-types";

const SelectableGroup = props => {

  const { group, onClick } = props;

  return (
    <div
      onClick={() => onClick(group)}
      className="group flex items-center gap-2 py-0.5 px-2 cursor-pointer rounded-md hover:bg-[#f7f7f7]"
    >
      <div>
        <div className="text-base font-medium text-[#595959] group-hover:text-[#3B59C3]">{group.name}</div>
        <div className="text-sm">{group.groupname}</div>
      </div>
    </div>
  );
};

SelectableGroup.propTypes = {
  group: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SelectableGroup;