import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as GenomeIcon } from "../images/genome.svg";
import { ReactComponent as UserIcon } from "../images/user.svg";
import { ReactComponent as GroupIcon } from "../images/group.svg";
import { ReactComponent as PipelineIcon } from "../images/pipeline.svg";
import { ReactComponent as ExecutionIcon } from "../images/execution.svg";
import { ReactComponent as DataIcon } from "../images/data.svg";
import { ReactComponent as SampleIcon } from "../images/sample.svg";
import { ReactComponent as ProjectIcon } from "../images/project.svg";
import { ReactComponent as EditIcon } from "../images/edit.svg";
import { ReactComponent as PrivateIcon } from "../images/private.svg";
import { ReactComponent as PublicIcon } from "../images/public.svg";
import OrganismIcon from "./OrganismIcon";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import UserImage from "./UserImage";

const ObjectTitle = props => {

  const { object, title, corner, organism } = props;

  const forcedPublic = Boolean(
    object.forcedPublicProjectId || object.forcedPublicSampleId || object.forcedPublicExecutionId
  )

  const editUrl = `${useLocation().pathname}edit/`;

  const Icon = {
    ProjectType: ProjectIcon,
    SampleType: SampleIcon,
    ExecutionType: ExecutionIcon,
    DataType: DataIcon,
    PipelineType: PipelineIcon,
    UserType: UserIcon,
    GroupType: GroupIcon,
    OrganismType: OrganismIcon,
    GenomeType: GenomeIcon,
  }[object.__typename ];

  const VisibilityIcon = object.private && !forcedPublic ? PrivateIcon : PublicIcon;

  const h1 = title || object.name || object.filename || object.pipelineVersion?.pipeline?.name;
  const description = object.description || object.latinName || object.pipelineVersion?.description;
  const breakTitle = !h1.props && !h1.includes(" ");

  return (
    <div className={props.className || ""}>
      <div className="justify-between mb-2 gap-2 flex sm:mb-2 md:mb-4">
        <div className="leading-8">
          <Icon className={`inline w-6 h-auto sm:w-7 md:w-8 lg:w-9`} organism={object} />
          <h1 className={`m-0 text-[#595959] relative top-1 text-xl ml-2 mr-4 inline sm:text-2xl lg:text-3xl ${!breakTitle || "break-all"}`}>{h1}</h1>
          {(object.canEdit || object.isAdmin) && (
            <Link to={editUrl} className="flex items-baseline gap-1 font-medium text-xs whitespace-nowrap mt-1.5 sm:inline-flex sm:mt-0 md:text-sm">
              <EditIcon className="w-3 sm:w-4 md:w-5 relative top-px" />Edit {object.__typename.slice(0, -4)}
            </Link>
          )}
        </div>
        <div className="flex gap-2 h-7">
          {corner}
          {object.private !== undefined && <VisibilityIcon className="h-full" />}
        </div>
      </div>
      {description && (
        <div className={`mb-3 max-w-xl ${object.latinName ? "italic text-base" : "text-xs sm:text-sm"}`}>
          {description}
        </div>
      )}
      <div className="font-medium text-xs flex flex-col gap-2 mt-3 sm:items-center sm:gap-4 sm:mt-4 sm:flex-row sm:text-sm">
        {object.owner && (
          <Link className="flex items-center font-medium gap-1" to={`/users/${object.owner.username}/`}>
            <UserImage user={object.owner} className="w-6 h-6 sm:w-8 sm:h-8" />
            {object.owner.name}
          </Link>
        )}
        {object.created && (
          <div>
            <span className="text-[#777777]">Created </span>
            {moment(object.created * 1000).format("D MMM YYYY")}
          </div>
        )}
        {organism && (
          <Link className="flex items-center font-medium gap-1" to={`/organisms/${organism.id}/`}>
            {organism.latinName}
          </Link>
        )}
      </div>
    </div>
  );
};

ObjectTitle.propTypes = {
  object: PropTypes.object.isRequired,
  title: PropTypes.string,
  corner: PropTypes.object,
  organism: PropTypes.object,
};

export default ObjectTitle;