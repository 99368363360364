import { useState } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import Genome from "../components/Genome";
import { useDocumentTitle } from "../hooks";
import { ORGANISM, ORGANISM_SAMPLES } from "../queries";
import Base from "./Base";
import SampleTable from "../components/SampleTable";
import ObjectTitle from "../components/ObjectTitle";
import Tabs from "../components/Tabs";

const OrganismPage = () => {

  const { id } = useParams();

  const [samplesPage, setSamplesPage] = useState(1);
  const [samples, setSamples] = useState([]);
  const [samplesCount, setSamplesCount] = useState(null);
  const [tab, setTab] = useState("Samples");

  const samplesRowCount = 20;

  const { loading, data } = useQuery(ORGANISM, {variables: {id}});

  const { loading: samplesLoading } = useQuery(ORGANISM_SAMPLES, {
    variables: {id, first: samplesRowCount * samplesPage, last: samplesRowCount},
    onCompleted: data => {
      setSamplesCount(data.organism.samples.count);
      setSamples(data.organism.samples.edges.map(e => e.node));
    }
  });

  useDocumentTitle(data?.organism ? `${data.organism.name} - Flow` : "Flow");

  if (loading) return <Base loading={true} />
  
  if (!data.organism) return <Base notFound={true} />

  const organism = data.organism;

  const latest = organism.genomes[organism.genomes.length - 1];
  const previous = organism.genomes.slice(0, -1);

  return (
    <Base>
      <ObjectTitle object={organism} canEdit={false} className="mb-6" />

      {organism.genomes.length > 0 && (
        <div className="mb-8">
          <div className="font-medium text-lg mb-2 md:text-xl">Latest Genome</div>
          <Genome genome={latest} className="w-fit max-w-full" />
        </div>
      )}

      <Tabs labels={["Samples", "Genomes"]} selected={tab} setSelected={setTab} />

      {tab === "Samples" && (
        <SampleTable
          samples={samples}
          pageLength={samplesRowCount}
          noMessage="Organism has no viewable samples."
          loading={samplesLoading}
          currentPage={samplesPage}
          totalItems={samplesCount}
          pageChange={setSamplesPage}
        />
      )}
      {tab === "Genomes" && (
        <div>
          {previous.length > 0 && (
            <div className="flex flex-wrap gap-4">
              {previous.map(genome => <Genome genome={genome} key={genome.id} className="w-full max-w-md" />)}
            </div>
          )}
          {previous.length === 0 && <div className="info text-sm">No previous genomes.</div>}
        </div>
      )}
      
    </Base>
  );
};

OrganismPage.propTypes = {
  
};

export default OrganismPage;