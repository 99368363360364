import React from "react";
import PropTypes from "prop-types";
import { ClipLoader } from "react-spinners";
import SampleCategoryChart from "./SampleCategoryChart";
import SampleOrganismChart from "./SampleOrganismChart";

const FlowCounts = props => {

  const { headingClass, pipelineCount, userCount, sampleCount, executionCount, sampleCounts } = props;

  const boxClass = "flex items-center gap-x-3 bg-[#F5F5F5] rounded border border-[#DCDCDC] py-2";
  const numberClass = "border-r border-[#D2D2D2] text-2xl px-3";
  const loading = pipelineCount === null;

  const counts = [
    [pipelineCount, "text-[#3B59C3]", "pipelines"],
    [sampleCount, "text-[#D27620]", "samples"],
    [userCount, "text-[#6CB424]", "users"],
    [executionCount, "text-[#B425A5]", "executions"],
  ]

  return (
    <div className="text-[#505B60] pt-6">
      <div className={headingClass}>Flow in detail</div>

      <div className="mb-6 mt-10 grid grid-cols-1 gap-6 max-w-xl md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 lg:mt-0">
        <SampleCategoryChart sampleCounts={sampleCounts} />
        <SampleOrganismChart sampleCounts={sampleCounts} />
      </div>

      <div className="grid grid-cols-1 font-medium gap-5 max-w-xl md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
        {counts.map(([count, color, objects]) => (
          <div className={boxClass} key={color}>
            <div className={`${numberClass} ${color}`}>
              {loading && (
                <div className="relative top-1"><ClipLoader size={25} color={color} /></div>
              )}
              {count}
            </div>
            <div><span className={color}>{objects}</span> on Flow</div>
          </div>
        ))}
      </div>

    </div>
  );
};

FlowCounts.propTypes = {
  headingClass: PropTypes.string,
  pipelineCount: PropTypes.number,
  userCount: PropTypes.number,
  sampleCount: PropTypes.number,
  executionCount: PropTypes.number,
  sampleCounts: PropTypes.string
};

export default FlowCounts;