import React, { useContext, useEffect, useState, useRef } from "react";
import { UserContext } from "../contexts";
import Notification from "./Notification";
import { ReactComponent as NotificationsIcon } from "../images/notifications.svg";
import { READ_NOTIFICATIONS } from "../mutations";
import { useMutation, useLazyQuery } from "@apollo/client";
import { NOTIFICATIONS, USER } from "../queries";
import { BarLoader } from "react-spinners";

const Notifications = () => {

  const [showList, setShowList] = useState(false);
  const [extraNotifications, setExtraNotifications] = useState([]);
  const [cursor, setCursor] = useState(null);

  const ref = useRef();

  const [user, setUser] = useContext(UserContext);

  const [readNotifications,] = useMutation(READ_NOTIFICATIONS);

  const [fetchMore, { loading }] = useLazyQuery(NOTIFICATIONS);

  const [refreshUser,] = useLazyQuery(USER, {
    onCompleted: data => {
      setUser(data.me);
    }
  });

  const hasUnread = user.notifications.unreadCount > 0;
  
  const open = () => {
    setShowList(true);
    const unreadIds = allNotifications.filter(n => !n.hasRead).map(n => n.id);
    if (unreadIds.length > 0) {
      readNotifications({variables: {ids: unreadIds}})
    }
  }

  const close = () => {
    setShowList(false);
    const newUser = {...user};
    newUser.notifications.edges.forEach(n => n.node.hasRead = true);
    newUser.notifications.unreadCount -=  newUser.notifications.edges.length;
    setUser(newUser);
  }

  const getMoreNotifications = () => {
    fetchMore({
      variables: {after: cursor || user.notifications.pageInfo.endCursor},
      onCompleted: data => {
        setCursor(data.me.notifications.pageInfo.endCursor);
        setExtraNotifications([...extraNotifications, ...data.me.notifications.edges.map(n => n.node)]);
        setTimeout(() => open(), 0);
      }
    })
  }

  const markAllRead = () => {
    readNotifications({
      variables: {ids: null},
      onCompleted: data => {
        const newUser = {...user};
        setExtraNotifications(extraNotifications.map(n => ({...n, hasRead: true})));
        newUser.notifications.edges.forEach(n => n.node.hasRead = true);
        newUser.notifications.unreadCount = 0;
        setUser(newUser);
      }
    });
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (!showList) refreshUser();
    }, 1000 * 30)
    return () => clearInterval(interval);
  }, [showList, refreshUser])

  useEffect(() => {
    const onClick = e => {
      if (ref.current && ref.current.contains(e.target)) return;
      close();
    }
    window.addEventListener("click", onClick);
    return () => {
      window.removeEventListener("click", onClick);
    }
  }, [])

  const allNotifications = [...user.notifications.edges.map(n => n.node), ...extraNotifications];
    
  return (
    <div className="relative" ref={ref}>
      <div className="w-5 relative">
        <NotificationsIcon
          className={`w-full h-auto cursor-pointer ${(hasUnread || showList) ? "" : "opacity-70"}`}
          onClick={() => showList ? close() : open()}
        />
        {hasUnread && (
          <div className="absolute -top-1 -right-0 w-3 h-3 rounded-full bg-[#5636F8]" />
        )}
      </div>
      {showList && (
        <div className="border z-50 max-h-96 overflow-y-scroll right-2 left-2 top-14 shadow fixed bg-[#E9E9E9] flex flex-col gap-px rounded overflow-hidden sm:w-80 sm:absolute sm:top-8 sm:right-0 sm:left-auto">
          {user.notifications.count === 0 && (
            <div className="text-center text-[#697395] text-xs py-4 bg-[#F6F6F6]">
              No notifications yet
            </div>
          )}
          {user.notifications.unreadCount > 0 && (
            <div
              onClick={markAllRead}
              className="text-center text-[#697395] text-xs py-1.5 cursor-pointer bg-[#F6F6F6] hover:bg-[#E9E9E9]"
            >
              Mark all as read
            </div>
          )} 
          {allNotifications.map(notification => (
            <Notification key={notification.id} notification={notification} />
          ))}
          {allNotifications.length < user.notifications.count && (
            <div 
              onClick={getMoreNotifications}
              className="text-center text-[#697395] text-xs py-1.5 cursor-pointer bg-[#F6F6F6] hover:bg-[#E9E9E9]"
            >
              {loading ? <div className="w-full flex py-1.5 justify-center"><BarLoader /></div> : "More notifications"}
            </div> 
          )}
        </div>
      )}
    </div>
  );
};

Notifications.propTypes = {
  
};

export default Notifications;