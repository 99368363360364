import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import DataUploader from "../components/DataUploader";
import Tabs from "../components/Tabs";
import { useDocumentTitle } from "../hooks";
import { UPLOAD_INFO } from "../queries";
import Base from "./Base";
import DemultiplexUploader from "../components/DemultiplexUploader";
import AnnotationUploader from "../components/AnnotationUploader";
import MultiplexUploader from "../components/MultiplexUploader";

const UploadPage = () => {

  const [tab, setTab] = useState(null);
  
  const { data, loading } = useQuery(UPLOAD_INFO);

  useDocumentTitle("Upload Data - Flow");

  if (loading) return <Base loading={true} />

  const tabs = ["Multiplexed", "Other Data"];
  if (data.organisms.length > 0) tabs.unshift("Demultiplexed")
  const tabToUse = tab || tabs[0];

  return (
    <Base>
      <h1>Upload Data</h1>
      <Tabs labels={tabs} selected={tabToUse} setSelected={setTab} />
      {tabToUse === "Multiplexed" && (
        <div className="flex flex-wrap gap-x-36 gap-y-16">
          <AnnotationUploader className="max-w-sm" />
          <MultiplexUploader className="max-w-sm" />
        </div>
      )}
      {tabToUse === "Demultiplexed" && data.organisms.length > 0 && (
        <DemultiplexUploader
          projects={data.ownedProjects.edges.map(e => e.node)} organisms={data.organisms}

        />
      )}
      {tabToUse === "Other Data" && (
        <div>
          <DataUploader />
        </div>
      )}
    </Base>
  );
};

UploadPage.propTypes = {
  
};

export default UploadPage;