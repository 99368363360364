import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import { useMutation } from "@apollo/client";
import { UPDATE_PASSWORD } from "../mutations";
import { parseError } from "../errors";
import Input from "./Input";

const PasswordForm = props => {

  const { formClass, headingClass, rowClass, cellClass, labelClass, inputClass, buttonClass } = props;

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [errors, setErrors] = useState(null);

  const [updatePassword, updatePasswordMutation] = useMutation(UPDATE_PASSWORD, {
    onCompleted: () => {
      setCurrentPassword("");
      setNewPassword("");
    },
    onError: error => setErrors(parseError(error))
  });

  const onSubmit = e => {
    e.preventDefault();
    updatePassword({variables: {current: currentPassword, new: newPassword}});
  }

  const validation = errors?.validation || {};

  return (
    <form onSubmit={onSubmit} className={formClass}>
      <div className={headingClass}>Password</div>

      <div className={rowClass}>
        <div className={cellClass}>
          <label htmlFor="current" className={labelClass}>Current Password</label>
          <Input
            type="password"
            id="current"
            value={currentPassword}
            onChange={e => setCurrentPassword(e.target.value)}
            className={inputClass}
            autoComplete="current-password"
            required
            errors={validation.current}
          />
        </div>
        <div className={cellClass}>
          <label htmlFor="new" className={labelClass}>New password</label>
          <Input
            type="password"
            id="new"
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
            className={inputClass}
            autoComplete="new-password"
            required
            errors={validation.new}
          />
        </div>
      </div>

      <Button className={buttonClass} type="submit" loading={updatePasswordMutation.loading}>
        Save Password
      </Button>
    </form>
  )
};

PasswordForm.propTypes = {
  formClass: PropTypes.string.isRequired,
  headingClass: PropTypes.string.isRequired,
  rowClass: PropTypes.string.isRequired,
  cellClass: PropTypes.string.isRequired,
  labelClass: PropTypes.string.isRequired,
  inputClass: PropTypes.string.isRequired,
  buttonClass: PropTypes.string.isRequired,
};

export default PasswordForm;