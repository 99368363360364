import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import { useMutation } from "@apollo/client";
import { UPDATE_GROUP } from "../mutations";
import { UserContext } from "../contexts";
import { useNavigate } from "react-router-dom";
import Input from "./Input";
import { parseError } from "../errors";

const GroupForm = props => {

  const { group } = props;

  const [name, setName] = useState(null);
  const [slug, setSlug] = useState(null);
  const [description, setDescription] = useState(null);
  const [errors, setErrors] = useState(null);

  const [,setUser] = useContext(UserContext);
  const navigate = useNavigate();

  const displayName = name === null ? group.name : name;
  const displaySlug = slug === null ? group.slug : slug;
  const displayDescription = description === null ? group.description : description;

  const [updateGroup, updateGroupMutation] = useMutation(UPDATE_GROUP, {
    onCompleted: data => {
      setUser(data.updateGroup.user);
      navigate(`/groups/@${data.updateGroup.group.slug}/`);
    },
    onError: error => setErrors(parseError(error))
  });

  const onSubmit = e => {
    e.preventDefault();
    updateGroup({variables: {
      id: group.id, name: displayName, slug: displaySlug,
      description: displayDescription
    }})
  }

  const inputClass = "bg-[#EDEDED] rounded block";
  const validation = errors?.validation || {};

  return (
    <form onSubmit={onSubmit}>
      <div className="flex flex-col items-start gap-2 font-medium w-full max-w-xl mb-2 md:flex-row">
        <Input
          value={displayName}
          onChange={e => setName(e.target.value)}
          className={`${inputClass} w-full`}
          containerClassName="w-full md:flex-grow"
          required
          errors={validation.name}
        />
        <div className="relative w-full flex-shrink-0 md:w-1/3">
          <Input
            value={displaySlug}
            onChange={e => setSlug(e.target.value)}
            className={`${inputClass} text-[#3B59C3] pl-6 w-full`}
            required
            errors={validation.slug}
          />
          <div className="absolute bottom-1 -mb-px -ml-px left-2.5 text-[#3B59C3] font-medium">@</div>
        </div>
      </div>
      <Input
        value={displayDescription}
        onChange={e => setDescription(e.target.value)}
        className={`${inputClass} block w-full max-w-xl h-32 text-sm resize-none mb-4`}
        required
        textarea={true}
        errors={validation.description}
      />
      <Button type="submit" className="btn-primary" loading={updateGroupMutation.loading}>
        Save Changes
      </Button>
    </form>
  );
};

GroupForm.propTypes = {
  group: PropTypes.object.isRequired
};

export default GroupForm;