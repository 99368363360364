import { useQuery } from "@apollo/client";
import React from "react";
import { useState } from "react";
import ProjectGrid from "../components/ProjectGrid";
import { useDocumentTitle } from "../hooks";
import { PUBLIC_PROJECTS } from "../queries";
import Base from "./Base";

const PublicProjectsPage = () => {

  const [page, setPage] = useState(1);
  const [projects, setProjects] = useState([]);
  const [count, setCount] = useState(1);

  useDocumentTitle("Public Projects - Flow")

  const rowCount = 12;

  const { loading } = useQuery(PUBLIC_PROJECTS, {
    variables: { first: page * rowCount, last: rowCount},
    notifyOnNetworkStatusChange: false,
    onCompleted: data => {
      setProjects(data.publicProjects.edges.map(e => e.node));
      setCount(data.publicProjects.count);
    }
  })

  return (
    <Base>
      <h1>Public Projects</h1>
      <div className="mb-8">
        These are the Flow Projects which have been set to be publicly
        available by their owners.
      </div>
      <ProjectGrid
        projects={projects} pageLength={rowCount}
        noMessage="There are currently no public projects."
        loading={loading} currentPage={page}
        totalItems={count} pageChange={setPage}
      />
    </Base>
  );
};

PublicProjectsPage.propTypes = {
  
};

export default PublicProjectsPage;