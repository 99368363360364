import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useDocumentTitle } from "../hooks";
import { PROJECT } from "../queries";
import FinishedEditingLink from "../components/FinishedEditingLink";
import PrivacyToggle from "../components/PrivacyToggle";
import Base from "./Base";
import Permissions from "../components/Permissions";
import ProjectDetailsForm from "../components/ProjectDetailsForm";
import DeleteProject from "../components/DeleteProject";
import { UserContext } from "../contexts";

const EditProjectPage = () => {

  const { id } = useParams();

  const { loading, data } = useQuery(PROJECT, {variables: {id}});

  const [user,] = useContext(UserContext);

  useDocumentTitle(data?.project ? `${data.project.name} - Flow` : "Flow");

  if (loading) return <Base loading={true} />

  if (!data.project) return <Base notFound={true} />

  const project = data.project;

  return (
    <Base>
      <div className="flex items-start break-all justify-between gap-4 mb-2 md:mb-4">
        <h1>{project.name}</h1>
        <FinishedEditingLink />
      </div>

      <PrivacyToggle object={project} />

      <ProjectDetailsForm project={project} />

      {project.canShare && (
        <Permissions object={project} className="mt-12 pt-10 border-t border-[#e8e8e8]" />
      )}

      {project.owner.id === user.id && <DeleteProject project={project} />}
    </Base>
  );
};

EditProjectPage.propTypes = {
  
};

export default EditProjectPage;