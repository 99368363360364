import React, { useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../contexts";
import moment from "moment";

const AccountInfo = props => {

  const { formClass, headingClass } = props;

  const [user,] = useContext(UserContext);

  return (
    <div className={formClass}>
      <div className={headingClass}>Account Information</div>
      <div className="text-[#37474F] text-sm sm:text-base">
        Your account was created on <time className="text-[#3B59C3]">{moment(user.created * 1000).format("Do MMMM YYYY")}</time>
      </div>
    </div>
  );
};

AccountInfo.propTypes = {
  formClass: PropTypes.string.isRequired,
  headingClass: PropTypes.string.isRequired,
};

export default AccountInfo;