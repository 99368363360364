import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { MULTIPLEXED_DATA } from "../mutations";
import { useMutation } from "@apollo/client";
import FileInput from "./FileInput";
import ProgressBar from "./ProgressBar";
import Button from "./Button";
import { ReactComponent as UploadIcon } from "../images/upload.svg";
import { parseError } from "../errors";
import { base64Encode } from "../upload";
import DataInput from "./DataInput";

const MultiplexUploader = props => {

  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(null);
  const [finalDataId, setFinalDataId] = useState(null);
  const [reads1Id, setReads1Id] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const canceled = useRef(false);

  const CHUNK_SIZE = 1 * 1000 * 1000;

  const [uploadData,] = useMutation(MULTIPLEXED_DATA);

  const onSubmit = async e => {
    e.preventDefault();
    canceled.current = false;
    const chunkCount = Math.ceil(file.size / CHUNK_SIZE);
    setProgress(0);
    let dataId = null;
    const chunkNums = [...Array(chunkCount).keys()];
    for (let c = 0; c < chunkNums.length; c++) {
      const chunkNum = chunkNums[c];
      const start = CHUNK_SIZE * chunkNum;
      const isLast = chunkNum === chunkCount - 1;
      const expectedFileSize = chunkNum * CHUNK_SIZE;
      const chunk = file.slice(start, start + CHUNK_SIZE);
      const isbase64 = ["true", "yes"].includes((process.env.REACT_APP_USE_BASE64 || "").toLowerCase());
      const blob = isbase64 ? await base64Encode(chunk) : chunk;

      let resp;
      const MAX_RETRIES = 5;
      for (let attempt = 0; attempt < MAX_RETRIES; attempt++) {
        try {
          resp = await uploadData({variables: {
            blob, isLast, expectedFileSize, data: dataId,
            filename: file.name, reads1: reads1Id
          }})
          break;
        } catch (error) {
          const errorObject = parseError(error);
          if (errorObject.networkError) {
            if (attempt < MAX_RETRIES - 1) {
              await new Promise(resolve => setTimeout(resolve, 1000 * (attempt + 1)));
              continue;
            } else {
              setErrorMessage("There was a network error when uploading the file.");
              return;
            }
          } else {
            const message = errorObject.message || Object.values(errorObject.validation)[0][0];
            setErrorMessage("There was a problem uploading the file: " + message);
            return;
          }
        }
      }
      if (canceled.current === true) {
        canceled.current = false;
        return
      }

      dataId = resp.data.uploadMultiplexedData.dataId;
      setProgress((chunkNum + 1) / chunkCount);
      if (isLast) {
        setFinalDataId(dataId);
      }

    }
  }

  const cancel = () => {
    setProgress(null);
    setFinalDataId(null);
    setFile(null);
    setErrorMessage("");
    setReads1Id(null);
    canceled.current = true;
  }

  const reset = () => {
    setProgress(null);
    setFinalDataId(null);
    setFile(null);
    setReads1Id(null);
    setErrorMessage("");
  }

  const canUpload = Boolean(file);

  return (
    <form onSubmit={onSubmit} className={`w-full ${props.className || ""}`}>

      <div className="mb-4">
        <div className="text-[#515255] text-xl font-medium mb-2">Upload Multiplexed Reads</div>
        <div className="mb-2">Select multiplexed reads</div>
        <FileInput
          file={file} setFile={setFile} errorMessage={errorMessage}
          accept=".fq,.fastq,.gz" small={true}
        />
      </div>

      <div className="mb-4">
        <label className="text-sm font-medium mb-1">(Optional) Select file to pair with:</label>
        <DataInput
          inputClass="bg-[#F3F3F3] rounded mb-1.5 w-full h-9 text-[#3B59C3] font-medium px-3"
          value={reads1Id}
          setValue={setReads1Id}
          filetype="fq|fastq|fq.gz|fastq.gz"
          category={3}
          isSingle={true}
          forceIsOwned={true}
        />
      </div>

      {progress === null && (
        <Button type="submit" className={`btn-primary w-full gap-1.5 ${canUpload || "opacity-30 pointer-events-none"}`} disabled={!file}>
          <UploadIcon className="relative bottom-px" /> Upload FASTQ
        </Button>
      )}

      

      {progress !== null && (
        <ProgressBar progress={progress} onCancel={cancel} />
      )}

      {finalDataId && (
        <div className="flex justify-between mt-2 text-sm text-[#3B59C3]">
          <Link to={`/data/${finalDataId}/`}>View file</Link>
          <div className="link" onClick={reset}>Upload another</div>
        </div>
      )}
    </form>
  );
};

MultiplexUploader.propTypes = {
  
};

export default MultiplexUploader;