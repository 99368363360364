import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const SelectableSample = props => {

  const { sample, onClick } = props;

  const ellipsise = (string, max) => {
    if (string.length < max) return string;
    const slices = parseInt((max - 5) / 2);
    return string.slice(0, slices) + "..." + string.slice(-slices);
  }
  
  const name = ellipsise(sample.name, 75);

  return (
    <div
      onClick={() => onClick(sample)}
      className="group py-0.5 px-2 cursor-pointer rounded-md hover:bg-[#f7f7f7]"
    >
      <div className="text-base font-medium text-[#595959] group-hover:text-[#3B59C3]">{name}</div>
      <div className="text-xs flex gap-1 leading-4 text-[#595F73] whitespace-nowrap">
        <span>{moment(sample.created * 1000).format("D MMM YYYY")}</span>
        <span className="text-2xs">|</span>
        <span>{sample.owner.name}</span>
        <span className="text-2xs">|</span>
        <span>{sample.category || "Generic"}</span>
      </div>
    </div>
  );
};

SelectableSample.propTypes = {
  sample: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SelectableSample;