import React, { useContext } from "react";
import Base from "./Base";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useDocumentTitle } from "../hooks";
import { EXECUTION } from "../queries";
import PrivacyToggle from "../components/PrivacyToggle";
import FinishedEditingLink from "../components/FinishedEditingLink";
import Permissions from "../components/Permissions";
import DependencyToggle from "../components/DependencyToggle";
import DeleteExecution from "../components/DeleteExecution";
import { UserContext } from "../contexts";

const EditExecutionPage = () => {

  const { id } = useParams();

  const { loading, data } = useQuery(EXECUTION, {variables: {id}});

  const [user,] = useContext(UserContext);

  useDocumentTitle(data?.execution ? `${data.execution.pipelineVersion.pipeline.name} Execution - Flow` : "Flow");

  if (loading) return <Base loading={true} />

  if (!data.execution) return <Base notFound={true} />

  if (!data.execution.canEdit) return <Base notFound={true} />

  const execution = data.execution;

  return (
    <Base>
      <div className="flex items-start break-all justify-between gap-4 mb-2 md:mb-4">
        <h1>{execution.pipelineVersion.pipeline.name}</h1>
        <FinishedEditingLink />
      </div>
      <PrivacyToggle object={execution} />
      <DependencyToggle execution={execution} className="mt-12 pt-10 border-t border-[#e8e8e8]"/>
      {execution.canShare && (
        <Permissions object={execution} className="mt-12 pt-10 border-t border-[#e8e8e8]" />
      )}
      {execution.owner.id === user.id && <DeleteExecution execution={execution} />}
    </Base>
  );
};

EditExecutionPage.propTypes = {
  
};

export default EditExecutionPage;