import { useQuery } from "@apollo/client";
import React from "react";
import { Link, useParams } from "react-router-dom";
import GroupForm from "../components/GroupForm";
import { useDocumentTitle } from "../hooks";
import { GROUP } from "../queries";
import Base from "./Base";

const EditGroupPage = () => {

  const { slug } = useParams();

  const { loading, data } = useQuery(GROUP, {variables: {slug}});

  useDocumentTitle(data ? `${data.group.name} - Flow` : "Flow");

  if (loading) return <Base loading={true} />

  const group = data.group;

  return (
    <Base>
      <div className="flex flex-col justify-between gap-y-3 items-center border-b border-[#F2F2F2] pb-5 mb-8 sm:flex-row sm:pb-4">
        <h1 className="mb-2 text-center border-b w-full border-[#F2F2F2] pb-3 sm:mb-0 sm:text-left sm:border-0 sm:w-auto sm:pb-0">Edit {group.name}</h1>
        <Link to={`/groups/@${group.slug}/`} className="btn-secondary py-1 px-4 text-sm">Finished Editing</Link>
      </div>
      <GroupForm group={group} />
    </Base>
  );
};

EditGroupPage.propTypes = {
  
};

export default EditGroupPage;