import React from "react";
import Base from "./Base";
import { Link, useParams } from "react-router-dom";
import { GROUP } from "../queries";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { useDocumentTitle } from "../hooks";
import UserGrid from "../components/UserGrid";
import ObjectTitle from "../components/ObjectTitle";

const GroupPage = () => {

  const { slug } = useParams();

  const { loading, data } = useQuery(GROUP, {variables: {slug}});

  useDocumentTitle(data?.group ? `${data.group.name} - Flow` : "Flow");

  if (loading) return <Base loading={true} />
  
  if (!data.group) return <Base notFound={true} />

  const group = data.group;
  const members = group.members.filter(u => !group.admins.map(a => a.id).includes(u.id));

  return (
    <Base>
      <ObjectTitle
        object={group} canEdit={group.isAdmin} className="mb-8 sm:mb-10"
        title={<span>{group.name} <span className="text-[#3B59C3] text-2xl">@{group.slug}</span></span>}
      />
      
      <UserGrid users={group.admins} label="Group Admins" />
      {members.length > 0 && <UserGrid users={members} label="Current Members" />}
    </Base>
  );
};

GroupPage.propTypes = {
  
};

export default GroupPage;