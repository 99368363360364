import { useState, useRef } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import { useLazyFetch } from "../hooks";
import { ClipLoader } from "react-spinners";
import moment from "moment";
import Checkbox from "./Checkbox";

const ExecutionSelector2 = props => {

  const { execution, setExecution, pipelineVersions, organismId, returnData, returnGenome, inputClass, placeholder, placeholderClass } = props;

  const [showModal, setShowModal] = useState(false);
  const [query, setQuery] = useState("");
  const [ownedOnly, setOwnedOnly] = useState(true);
  const [possibleExecutions, setPossibleExecutions] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const timeout = useRef();
  const listRef = useRef();
  const pageCount = 20;

  const [{ loading }, searchExecutions ] = useLazyFetch("/executions/search", {
    onCompleted: data => {
      if (data.page > 1 && possibleExecutions) {
        setPossibleExecutions([...possibleExecutions, ...data.executions]);
      } else {
        setPossibleExecutions(data.executions);
      }
      setTotalCount(data.count);
    }
  });

  const search = params => {
    return searchExecutions({params: {
      name: query,
      organism: organismId,
      owned: ownedOnly,
      count: pageCount,
      return_data: returnData,
      return_genome: returnGenome,
      pipeline_versions: pipelineVersions?.join(","),
      ...params
    }})
  }

  const textEntered = e => {
    setQuery(e.target.value);
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      search({name: e.target.value});
    }, 500)
  }

  const openModal = () => {
    setShowModal(true);
    setQuery("");
    search();
  }

  const executionSelected = e => {
    if (execution && execution.id === e.id) {
      setExecution(null);
    } else {
      setExecution(e);
    }
    setShowModal(false);
  }

  const toggleIsOwned = isOwned => {
    setOwnedOnly(isOwned);
    search({owned: isOwned})
  }

  const clear = () => {
    setExecution(null);
    setShowModal(false);
  }

  const loadMore = async () => {
    const nextPage = Math.ceil(possibleExecutions.length / pageCount) + 1;
    setLoadingMore(true);
    await search({page: nextPage});
    setLoadingMore(false);
    if (listRef.current) {
      setTimeout(() => listRef.current.scrollBy({
        top: 100, left: 0, behavior: "smooth"
      }), 100);
    }
  }

  const hasMore = possibleExecutions && totalCount && possibleExecutions.length < totalCount;

  const executionToName = e => {
    let name = `${e.pipeline_name} ${e.pipeline_version}`
    if (e.genome_name) {
      name = `${e.organism_name} ${e.genome_name} - ${name}`;
    }
    return name;
  }

  return (
    <div>
      <div className={`${inputClass} cursor-pointer flex justify-center items-start flex-col gap-1`} onClick={openModal}>
        {execution ? executionToName(execution) : ""}
        {!execution && <span className={placeholderClass}>{placeholder}</span>}
      </div>
      {showModal && (
        <Modal setShowModal={setShowModal} className="w-full h-full flex flex-col px-4 py-2 max-w-6xl border border-[#E8E8E8] bg-[#FEFEFE]">
          <div className="flex flex-wrap items-center pt-1 pb-3 border-[#D3D3D3] justify-center gap-x-10 gap-y-2 text-sm text-[#595959] font-medium">
            <div
              onClick={clear}
              className={`${execution ? "cursor-pointer opacity-60 hover:opacity-100" : "opacity-30 pointer-events-none"}  `}
            >
              Clear
            </div>
            <Checkbox checked={ownedOnly} onChange={toggleIsOwned}>
              Limit to executions you own
            </Checkbox>
          </div>
          <input
            value={query} 
            className="bg-[#F3F3F3] flex-shrink-0 rounded mb-1.5 w-full h-9 text-[#3B59C3] font-medium px-3"
            onChange={textEntered}
            autoFocus
          />
          <div className="flex-grow overflow-auto" ref={listRef}>
            {possibleExecutions && possibleExecutions.length === 0 && !loading && (
              <div className="text-[#B5B5B5] text-lg font-light font-inter text-center py-3">
                No Matching Executions Found
              </div>
            )}
            {possibleExecutions && (!loading || loadingMore) && (
              <div className="">
                {possibleExecutions.map(e => {
                  const isCurrent = execution?.id === e.id;
                  return (
                    <div
                      key={e.id}
                      onClick={() => executionSelected(e)}
                      className={`group py-0.5 px-2 cursor-pointer rounded-md hover:bg-[#f7f7f7] ${isCurrent && "bg-[#f7f7f7]"}`}
                    >
                      <div className={`text-sm font-medium group-hover:text-[#3B59C3] sm:text-base ${isCurrent ? "text-[#3B59C3]" : "text-[#595959]"}`}>
                        {executionToName(e)}
                      </div>
                      <div className="text-2xs flex gap-5 font-normal leading-4 text-[#595F73] whitespace-nowrap sm:text-xs">
                        <span>{moment(e.created * 1000).format("D MMM YYYY")}</span>
                        <span>{e.owner_name}</span>
                        <span>{e.identifier}</span>
                      </div>
                    </div> 
                  )
                })}
                <div
                  className={`px-1 py-2 flex cursor-pointer justify-center text-primary-500 font-medium text-xs hover:bg-gray-100 rounded-md ${hasMore && !loading ? "" : "hidden"}`}
                  onClick={loadMore}
                >
                  Load More
                </div>
              </div>
            )}
            {loading && (
              <div className={`flex justify-center items-center ${loadingMore ? "h-10" : "h-20"}`}>
                <ClipLoader size={loadingMore ? 20 : 50} speedMultiplier={1.5} />
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

ExecutionSelector2.propTypes = {
  execution: PropTypes.object,
  setExecution: PropTypes.func.isRequired,
  pipelineVersions: PropTypes.array,
  organismId: PropTypes.number,
  returnData: PropTypes.bool,
  returnGenome: PropTypes.bool,
  inputClass: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderClass: PropTypes.string,

};

export default ExecutionSelector2;