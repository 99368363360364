import { useApolloClient } from "@apollo/client";
import { useEffect, useState } from "react";
import { TOKEN } from "./queries";
import { makeParamString } from "./utils";

export const useDocumentTitle = title => {
  useEffect(() => {
    document.title = title;
  }, [title]);
}

export const useFetch = (path, options = {}) => {
  const [state, setState] = useState({loading: true, error: null, data: null, status: null});
  const token = useApolloClient().readQuery({ query: TOKEN })?.accessToken;
  const method = options.method || "GET";
  const fullPath = method === "GET" ? makeParamString(path, options) : path;
  useEffect(() => {
    if (!options.skip) {
      setState({...state, loading: true});
      fetch(`${process.env.REACT_APP_BACKEND}${fullPath}`, {
        method: method, headers: token ? {Authorization: token} : undefined,
        body: method === "POST" ? JSON.stringify(options.params || {}) : undefined
      }).then(resp => {
        if (resp.status === 200) {
          resp.json().then(json => {
            if (options.onCompleted) options.onCompleted(json);
            setState({loading: false, error: null, data: json, status: resp.status});
          })
        } else {
          resp.json().then(json => {
            if (options.onError) options.onError(json);
            setState({loading: false, error: json, data: null, status: resp.status});
          })
        }
      })
    } else {
      setState({loading: false, error: null, data: null, status: null});
    }
  }, [fullPath, options.skip]);
  return state;
}

export const useLazyFetch = (path, options = {}) => {
  const [state, setState] = useState({loading: false, error: null, data: null, status: null});
  const token = useApolloClient().readQuery({ query: TOKEN })?.accessToken;
  const func = async (funcOptions = {}) => {
    const combinedOptions = {...options, ...funcOptions};
    const method = combinedOptions.method || "GET";
    const fullPath = method === "GET" ? makeParamString(path, combinedOptions) : path;
    setState({...state, loading: true});
    const resp = await fetch(`${process.env.REACT_APP_BACKEND}${fullPath}`, {
      method: method, headers: token ? {Authorization: token} : undefined,
      body: method === "POST" ? JSON.stringify(combinedOptions.params || {}) : undefined
    })
    const json = await resp.json();
    if (resp.status === 200) {
      if (combinedOptions.onCompleted) combinedOptions.onCompleted(json);
      setState({loading: false, error: null, data: json, status: resp.status});
      return { loading: false, error: null, data: json, status: resp.status };
    } else {
      if (combinedOptions.onError) combinedOptions.onError(json);
      setState({loading: false, error: json, data: null, status: resp.status});
      return { loading: false, error: json, data: null, status: resp.status };
    }
  }
  return [state, func];
}