import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Button from "./Button";
import UserImage from "./UserImage";
import LeaveGroupModal from "./LeaveGroupModal";

const GroupSummaries = props => {

  const { groups, title, formClass, headingClass } = props;

  const [groupWithModal, setGroupWithModal] = useState(null);

  const buttonClass = "w-full text-sm py-1 sm:w-32 sm:py-1.5";
  
  return (
    <div className={formClass}>
      <div className={headingClass}>{title}</div>
      <div className="flex flex-wrap mt-6 gap-x-20 gap-y-12 sm:gap-y-16">
        {groups.map((group, index) => {
          const allMembers = group.members.filter(u => !group.admins.map(m => m.id).includes(u.id));
          allMembers.sort((a, b) => Boolean(b.image) - Boolean(a.image));
          
          const members = allMembers.slice(0, 7);
          let extraText = ""
          if (allMembers.length >= 5) {
            members.splice(5);
            extraText = `...and ${allMembers.length - members.length} more`
          }
          return (
            <div className="max-w-xl w-full" key={group.id}>
              <Link className="flex font-medium gap-2 hover:no-underline" to={`/groups/@${group.slug}/`}>
                <div>{group.name}</div>
                <div className="text-[#3B59C3]">@{group.slug}</div>
              </Link>
              <div className="text-sm mb-2">{group.description}</div>
              <div className="font-medium">Current Admins</div>
              <div className="flex gap-2 mb-4">
                {group.admins.map(user => (
                  <UserImage user={user} className="w-10 h-10 text-xs" useInitials={true} isLink={true} key={user.id} />
                ))}
              </div>
              {members.length > 0 && (
                <>
                  <div className="font-medium">Other Members</div>
                  <div className="flex gap-1.5 mb-4 items-center sm:gap-2">
                    {members.map(user => (
                      <UserImage user={user} className="w-8 h-8 text-xs sm:w-10 sm:h-10" useInitials={true} isLink={true} key={user.id} />
                    ))}
                    <div className="font-medium text-sm sm:text-base">{extraText}</div>
                  </div>
                </>
              )}
              <div className="flex flex-col w-96 max-w-full gap-3 mt-6 sm:flex-row sm:w-fit sm:gap-4">
                {group.isAdmin && (
                  <Link to={`/groups/@${group.slug}/edit/`} className={`btn-primary ${buttonClass}`}>
                    Edit Group
                  </Link>
                )}
                <Button className={`btn-secondary ${buttonClass}`} onClick={() => setGroupWithModal(index)}>
                  Leave Group
                </Button>
                {groupWithModal === index && <LeaveGroupModal group={group} setShowModal={setGroupWithModal} />}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
};

GroupSummaries.propTypes = {
  groups: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  formClass: PropTypes.string.isRequired,
  headingClass: PropTypes.string.isRequired,
};

export default GroupSummaries;