import React from "react";
import PropTypes from "prop-types";
import Toggle from "./Toggle";
import { ClipLoader } from "react-spinners";
import { UPDATE_EXECUTION } from "../mutations";
import { useMutation } from "@apollo/client";

const DependencyToggle = props => {

  const { execution } = props;

  const [updateExecution, updateExecutionMutation] = useMutation(UPDATE_EXECUTION, {

  });

  const toggleClicked = value => {
    updateExecution({variables: {id: execution.id, dependent: value}})
  }

  return (
    <div className="edit-section">
      <div className="edit-heading">Independence</div>

      <div className="info mb-2 text-sm max-w-lg md:text-base md:mb-4">
        Executions can be part of samples or projects. By default, permissions on
        that sample/project don't apply to the execution. If you want to inherit
        permissions, you can do so here.
      </div>

      <div className="flex items-center gap-2">
        <Toggle
          value={execution.dependent} onChange={toggleClicked}
          trueLabel="Inherit permissions" falseLabel="Don't inherit permissions"
          className={!updateExecutionMutation.loading|| "opacity-60 pointer-events-none"}
        />
        {updateExecutionMutation.loading && <ClipLoader size={20} />}
      </div>
    </div>
  );
};

DependencyToggle.propTypes = {
  
};

export default DependencyToggle;