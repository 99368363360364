import { useState } from "react";
import Base from "./Base";
import Tabs from "../components/Tabs";
import { useFetch, useDocumentTitle, useLazyFetch } from "../hooks";
import SamplesTable2 from "../components/SamplesTable2";
import { ClipLoader } from "react-spinners";

const YourSamplePage = () => {

  const [tab, setTab] = useState("Owned");
  const [ownedSamplesPage, setOwnedSamplesPage] = useState(1);
  const [ownedSamplesCount, setOwnedSamplesCount] = useState(null);
  const [ownedSamples, setOwnedSamples] = useState(null);
  const [ownedSamplesFilter, setOwnedSamplesFilter] = useState(null);
  const [hasOwnedSamples, setHasOwnedSamples] = useState(null);
  const [sharedSamplesPage, setSharedSamplesPage] = useState(1);
  const [sharedSamplesCount, setSharedSamplesCount] = useState(null);
  const [sharedSamples, setSharedSamples] = useState(null);
  const [sharedSamplesFilter, setSharedSamplesFilter] = useState(null);
  const [hasSharedSamples, setHasSharedSamples] = useState(null);
  const pageSize = 20;

  useDocumentTitle("Your Samples - Flow");

  const { loading: ownedSamplesLoading } = useFetch("/samples/owned", {
    params: {page: ownedSamplesPage, count: pageSize, filter: ownedSamplesFilter},
    onCompleted: data => {
      setOwnedSamplesCount(data.count);
      setOwnedSamples(data.samples);
      if (hasOwnedSamples === null) setHasOwnedSamples(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / pageSize);
      if (ownedSamplesPage > maxPossiblePage) setOwnedSamplesPage(maxPossiblePage || 1);
    }
  })

  const { loading: sharedSamplesLoading } = useFetch("/samples/shared", {
    params: {page: sharedSamplesPage, count: pageSize, filter: sharedSamplesFilter},
    onCompleted: data => {
      setSharedSamplesCount(data.count);
      setSharedSamples(data.samples);
      if (hasSharedSamples === null) setHasSharedSamples(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / pageSize);
      if (sharedSamplesPage > maxPossiblePage) setSharedSamplesPage(maxPossiblePage || 1);
    }
  })

  const [,postBulkDelete] = useLazyFetch("/samples/owned", {
    params: {page: ownedSamplesPage, count: pageSize, filter: ownedSamplesFilter},
    onCompleted: data => {
      setOwnedSamplesCount(data.count);
      setOwnedSamples(data.samples);
    }
  })

  const textClass = "text-sm mb-6 info sm:mb-8";

  const ownedLabel = (hasOwnedSamples === false) ? "You do not own any samples yet." : "Samples you own";
  const sharedLabel = (hasSharedSamples === false) ? "You do not have any samples shared with you yet." : "Samples shared with you";
  const showOwnedTable = (hasOwnedSamples === true);
  const showSharedTable = (hasSharedSamples === true);

  return (
    <Base>
      <h1>Your Samples</h1>
      <Tabs labels={["Owned", "Shared"]} selected={tab} setSelected={setTab} />
      {tab === "Owned" && (
        <div>
          <div className={textClass}>{ownedLabel}</div>
          {ownedSamplesLoading && !showOwnedTable && (
            <div className="w-full h-48 flex justify-center items-center">
              <ClipLoader size={100} />
            </div>
          )}
          {showOwnedTable && (
            <SamplesTable2
              samples={ownedSamples}
              page={ownedSamplesPage}
              pageSize={pageSize}
              setPage={setOwnedSamplesPage}
              totalCount={ownedSamplesCount}
              loading={ownedSamplesLoading}
              onFilter={s => setOwnedSamplesFilter(s || null)}
              onBulkDelete={postBulkDelete}
              noMessage="No owned samples matches that term." 
            />
          )}
        </div>
      )}
      {tab === "Shared" && (
        <div>
          <div className={textClass}>{sharedLabel}</div>
          {sharedSamplesLoading && !showSharedTable && (
            <div className="w-full h-48 flex justify-center items-center">
              <ClipLoader size={100} />
            </div>
          )}
          {showSharedTable && (
            <SamplesTable2
              samples={sharedSamples}
              page={sharedSamplesPage}
              pageSize={pageSize}
              setPage={setSharedSamplesPage}
              totalCount={sharedSamplesCount}
              loading={sharedSamplesLoading}
              onFilter={s => setSharedSamplesFilter(s || null)}
              noMessage="No shared samples matches that term."
            />
          )}
        </div>
      )}
    </Base>
  );

}

YourSamplePage.propTypes = {
  
};

export default YourSamplePage;