import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import Button from "./Button";
import { useMutation } from "@apollo/client";
import { LEAVE_GROUP } from "../mutations";
import { UserContext } from "../contexts";
import { parseError } from "../errors";

const LeaveGroupModal = props => {

  const { group, setShowModal } = props;

  const [errors, setErrors] = useState(null);
  const [,setUser] = useContext(UserContext);

  const [leaveGroup, leaveGroupMutation] = useMutation(LEAVE_GROUP, {
    onCompleted: data => {
      setUser(data.leaveGroup.user);
      setShowModal(false);
    },
    onError: error => setErrors(parseError(error))
  });

  const validation = errors?.validation || {};

  return (
    <Modal
      setShowModal={setShowModal}
      title={<div>Are you sure you want to leave <span className="text-[#3B59C3]">{group.name}</span>?</div>}
      text="If you want to rejoin in future you will need to request access."
      className="w-96 max-w-full"
    >
      {validation && (
        <div className="text-red-700 text-sm mx-auto w-fit">{Object.values(validation)[0]}</div>
      )}
      <div className="flex gap-3 flex-col w-full mx-auto mt-4 sm:w-64 sm:flex-row">
        <Button
          className="btn-primary py-1 w-full sm:w-1/2 sm:py-1.2"
          onClick={() => leaveGroup({variables: {id: group.id}})}
          loading={leaveGroupMutation.loading}
        >
          Yes, leave
        </Button>
        <button className="btn-secondary py-1 w-full sm:w-1/2 sm:py-1.5" onClick={() => setShowModal(false)}>Go Back</button>
      </div>
    </Modal>
  );
};

LeaveGroupModal.propTypes = {
  group: PropTypes.object.isRequired,
  setShowModal: PropTypes.func.isRequired
};

export default LeaveGroupModal;