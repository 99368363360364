import React, { useState } from "react";
import PropTypes from "prop-types";
import Input from "../components/Input";
import Button from "./Button";
import { UPDATE_PROJECT } from "../mutations";
import { useMutation } from "@apollo/client";
import { parseError } from "../errors";

const ProjectDetailsForm = props => {

  const { project } = props;

  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [errors, setErrors] = useState(null);

  const nameText = name === null ? project.name : name;
  const descriptionText = description === null ? project.description : description;

  const [updateProject, updateProjectMutation] = useMutation(UPDATE_PROJECT, {
    onError: error => setErrors(parseError(error))
  });

  const save = () => {
    setErrors(null);
    updateProject({
      variables: {
        id: project.id,
        name: nameText,
        description: descriptionText
      }
    })
  }

  const validation = errors?.validation || {};
  const labelClass = "text-[#37474F] font-medium text-sm block mb-1.5";
  const inputClass = "block bg-[#F3F3F3] text-[#3B59C3] rounded text-base px-3 py-1.5 w-full max-w-md sm:text-lg";

  return (
    <div className="edit-section">

      <div className="edit-heading">Details</div>

      <div className="mb-4">
        <label htmlFor="name" className={labelClass}>Project Name</label>
        <Input
          id="name"
          value={nameText}
          className={`${inputClass} text-base font-medium sm:text-lg`}
          onChange={e => setName(e.target.value)}
          required
          errors={validation.name}
        />
      </div>

      <div>
        <label htmlFor="description" className={labelClass}>Description</label>
        <Input
          id="description"
          value={descriptionText}
          className={`${inputClass} resize-none h-36 text-sm sm:text-base`}
          onChange={e => setDescription(e.target.value)}
          required
          errors={validation.description}
          textarea={true}
        />
      </div>

      <Button className="btn-primary mt-4" loading={updateProjectMutation.loading} onClick={save}>
        Save Details
      </Button>

    </div>
  );
};

ProjectDetailsForm.propTypes = {
  project: PropTypes.object.isRequired
};

export default ProjectDetailsForm;