import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useLazyQuery, useQuery } from "@apollo/client";
import { POSSIBLE_DATA } from "../queries";
import searchIcon from "../images/search.svg";
import { ClipLoader } from "react-spinners";
import Checkbox from "./Checkbox";
import SelectableData from "./SelectableData";

const DataInput = props => {

  const { placeholder, filetype, setValue, inputClass, forcedText, category, isSingle, forceIsOwned, showPair } = props;

  const [show, setShow] = useState(false);
  const [location, setLocation] = useState([]);
  const [typedText, setTypedText] = useState("");
  const [isUploaded, setIsUploaded] = useState(false);
  const [isOwned, setIsOwned] = useState(forceIsOwned || false);
  const [selectedDataName, setSelectedDataName] = useState("");
  const [possibleData, setPossibleData] = useState(null);
  const [count, setCount] = useState(null);
  const [page, setPage] = useState(1);
  const boxRef = useRef();
  const nameRef = useRef();
  const selectorRef = useRef();
  const timeout = useRef();
  const PER_PAGE = 10;

  const calculateLocation = () => {
    const main = document.querySelector("main");
    const rect = nameRef.current.getBoundingClientRect();
    const minimumTop = main.offsetTop;
    const maximumTop = window.innerHeight - (selectorRef.current?.offsetHeight || 0);
    const actualTop = Math.min(Math.max(minimumTop, rect.top), maximumTop);
    setLocation([rect.left, actualTop])
  }

  const clickOutside = e => {
    if (boxRef.current && !boxRef.current.contains(e.target)) {
      setShow(false);
      setPossibleData(null);
      setPage(1);
    }
  }

  useEffect(() => {
    window.addEventListener("click", clickOutside);
    window.addEventListener("resize", calculateLocation);
    document.querySelector("main").addEventListener("scroll", calculateLocation);
    return () => {
      window.removeEventListener("click", clickOutside);
      window.removeEventListener("resize", calculateLocation);
      document.querySelector("main")?.removeEventListener("scroll", calculateLocation);
    }
  }, [])

  useEffect(calculateLocation, [show]);

  const { loading: initialLoading } = useQuery(POSSIBLE_DATA, {
    variables: {
      filename: typedText, filetype, category, isSingle, isOwned,
      first: PER_PAGE * page, last: PER_PAGE
    },
    skip: !show || typedText.length || possibleData,
    onCompleted: data => {
      setPossibleData(data.searchData.edges.map(edge => edge.node));
      setCount(data.searchData.count);
    }
  })

  const [searchData, { loading: lazyLoading }] = useLazyQuery(POSSIBLE_DATA, {
    onCompleted: data => {
      const newData = data.searchData.edges.map(edge => edge.node);
      setPossibleData(newData);
      setCount(data.searchData.count);
    }
  });

  const [searchExtraData, { loading: extraLoading }] = useLazyQuery(POSSIBLE_DATA, {
    onCompleted: data => {
      const currentIds = possibleData.map(d => d.id);
      const newData = data.searchData.edges.map(edge => edge.node).filter(
        d => !currentIds.includes(d.id)
      );
      setPossibleData([...possibleData, ...newData]);
      setCount(data.searchData.count);
    }
  });

  const selectorClicked = () => {
    if (forcedText) return;
    calculateLocation();
    setShow(true);
    setSelectedDataName("");
    if (selectedDataName) setValue(null);
  }

  const dataClicked = async data => {
    setShow(false);
    if (showPair) {
      setSelectedDataName(`${data.filename} / ${data.pair.filename}`);
    } else {
      setSelectedDataName(data.filename);
    }
    setValue(data.id);
    setPossibleData(null);
  }

  const uploadedCheckboxClicked = () => {
    setIsUploaded(!isUploaded);
    searchData({variables: {
      filename: typedText, filetype, category, isSingle,
      isUploaded: !isUploaded, isOwned, first: PER_PAGE, last: PER_PAGE
    }})
  }

  const ownedCheckboxClicked = e => {
    setIsOwned(!isOwned);
    searchData({variables: {
      filename: typedText, filetype, category, isSingle,
      isUploaded, isOwned: !isOwned, first: PER_PAGE, last: PER_PAGE
    }})
  }
  
  const loadMoreClicked = () => {
    searchExtraData({variables: {
      filename: typedText, filetype, category, isSingle,
      isUploaded, isOwned, first: PER_PAGE * (page + 1), last: PER_PAGE
    }})
    setPage(page + 1);
  }

  const textEntered = e => {
    setTypedText(e.target.value);
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      searchData({variables: {
        filename: e.target.value, filetype, category, isSingle,
        isUploaded, isOwned: e.target.checked, first: PER_PAGE, last: PER_PAGE
      }})
    }, 500)
  }

  const isLoading = initialLoading || lazyLoading || extraLoading;
  const isMoreData = count !== null && possibleData && count > possibleData.length;

  return (
    <div ref={boxRef}>
      <div
        className={`${inputClass} flex items-center cursor-pointer overflow-x-auto no-scroll ${!forcedText || "italic pointer-events-none text-opacity-50"} ${!placeholder || "text-sm italic"}`}
        onClick={selectorClicked}
        ref={nameRef}
      >
        {placeholder}
        {!placeholder && typeof forcedText === "string" && forcedText}
        {!placeholder && typeof forcedText !== "string" && selectedDataName}
      </div>
      {show && (
        <div
          className="border border-[#E8E8E8] fixed bg-[#FEFEFE] rounded pt-3 max-w-xl left-0 top-0 right-6 z-40 shadow"
          style={{left: location[0], top: location[1]}}
          ref={selectorRef}
        >
          <div className="relative px-3 mb-2">
            <input
              value={typedText}
              onChange={textEntered}
              placeholder="Search"
              className="bg-[#F3F3F3] font-medium py-2 pr-2 pl-8 w-full text-sm"
              autoFocus
            />
            <img src={searchIcon} className="w-3.5 top-3 -mt-px left-6 absolute z-40 " alt="" />
          </div>

          <div className="h-60 overflow-y-auto px-3 mb-1">
            {possibleData && (!isLoading || extraLoading) && possibleData.map(d => (
              <SelectableData key={d.id} data={d} onClick={dataClicked} />
            ))}
            {possibleData && possibleData.length === 0 && !isLoading && (
              <div className="text-[#B5B5B5] text-lg font-light font-inter text-center py-3 mt-20">
                No Matching Files Found
              </div>
            )}
            {isLoading && (
              <div className={`mx-auto w-fit ${extraLoading ? "py-1" : "py-3"}`}>
                <ClipLoader color="#3C59C3" size={extraLoading ? 20 : 30} />
              </div>
            )}
            <div
              className={`px-1 py-2 flex cursor-pointer justify-center text-primary-500 font-medium text-xs hover:bg-gray-100 rounded-md ${isMoreData && !isLoading ? "" : "hidden"}`}
              onClick={loadMoreClicked}
            >Load More</div>
          </div>

          <div className="flex flex-wrap border-[#D3D3D3] justify-center gap-x-10 px-6 gap-y-2 py-2 mx-3 text-sm text-[#595959] font-medium border-t">
            <Checkbox checked={isUploaded} onChange={uploadedCheckboxClicked}>
              Limit to uploads
            </Checkbox>
            {!forceIsOwned && (
              <Checkbox checked={isOwned} onChange={ownedCheckboxClicked}>
                Limit to data you own
              </Checkbox>
            )}
          </div>
        </div>
      )}
    </div>
  )
};

DataInput.propTypes = {
  filetype: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  inputClass: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  forcedText: PropTypes.string,
  category: PropTypes.string,
  isSingle: PropTypes.bool,
  forceIsOwned: PropTypes.bool,
  showPair: PropTypes.bool,
};

export default DataInput;