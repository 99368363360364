import { useMutation } from "@apollo/client";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Input from "../components/Input";
import { UserContext } from "../contexts";
import { CREATE_GROUP } from "../mutations";
import Base from "./Base";
import { parseError } from "../errors";
import { useDocumentTitle } from "../hooks";

const NewGroupPage = () => {

  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState(null);
  const [,setUser] = useContext(UserContext);
  const navigate = useNavigate();

  useDocumentTitle("New Group - Flow");

  const cellClass = "flex-grow relative";
  const labelClass = "text-[#37474F] font-medium text-sm block mb-1.5";
  const inputClass = "block bg-[#F3F3F3] text-[#3B59C3] rounded text-base px-3 py-1.5 w-full sm:text-lg";

  const [createGroup, createGroupMutation] = useMutation(CREATE_GROUP, {
    onCompleted: data => {
      setUser(data.createGroup.user);
      navigate(`/groups/@${data.createGroup.group.slug}/`);
    },
    onError: error => setErrors(parseError(error))
  });

  const onSubmit = e => {
    e.preventDefault();
    createGroup({variables: {name, slug, description}});
  }

  const validation = errors?.validation || {};

  return (
    <Base>
      <h1>New Group</h1>
      <div className="text-base text-[#37474F] max-w-2xl mb-10">
        Groups are how you organise the members of your lab or organisation.
        You can make data available to just members of your group if you like,
        as well as make it your group's public profile for showing your previous research.
      </div>

      <form onSubmit={onSubmit}>
        <div className="flex gap-x-10 flex-wrap items-start gap-y-5 mb-5 max-w-2xl sm:gap-y-6 sm:mb-6">
          <div className={cellClass}>
            <label htmlFor="name" className={labelClass}>Group Name</label>
            <Input
              id="name"
              value={name}
              className={`${inputClass} text-base font-medium sm:text-lg`}
              onChange={e => setName(e.target.value)}
              required
              errors={validation.name}
            />
          </div>
          <div className={cellClass}>
            <label htmlFor="slug" className={labelClass}>Group URL</label>
            <Input
              id="slug"
              value={slug}
              className={`${inputClass} pl-6 text-base font-medium sm:text-lg`}
              onChange={e => setSlug(e.target.value)}
              required
              autoCapitalize="false"
              errors={validation.slug}
            />
            <div className="absolute bottom-2 left-2 -mb-px text-[#3B59C3] font-medium">@</div>
          </div>
        </div>
        <div className="w-full max-w-2xl">
          <label htmlFor="description" className={labelClass}>Description</label>
          <Input
            id="description"
            value={description}
            className={`${inputClass} resize-none h-36 text-sm sm:text-base`}
            onChange={e => setDescription(e.target.value)}
            required
            errors={validation.description}
            textarea={true}
          />
        </div>
        <Button className="btn-primary mt-8" type="submit" loading={createGroupMutation.loading}>
          Create Group
        </Button>
      </form>
    </Base>
  );
};

NewGroupPage.propTypes = {
  
};

export default NewGroupPage;