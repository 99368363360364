import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { UserContext } from "../contexts";
import Button from "./Button";
import { UPDATE_USER } from "../mutations";
import { parseError } from "../errors";
import Input from "./Input";

const UserDetailsForm = props => {

  const { formClass, headingClass, rowClass, cellClass, labelClass, inputClass, buttonClass } = props;

  const [user, setUser] = useContext(UserContext);

  const [username, setUsername] = useState(null);
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [errors, setErrors] = useState(null);

  const displayUsername = username === null ? user.username : username;
  const displayEmail = email === null ? user.email : email;
  const displayName = name === null ? user.name : name;

  const [updateUser, updateUserMutation] = useMutation(UPDATE_USER, {
    onCompleted: data => {
      setUser(data.updateUser.user);
    },
    onError: error => setErrors(parseError(error))
  });

  const onSubmit = e => {
    e.preventDefault();
    setErrors(null);
    updateUser({variables: {
      username: displayUsername, email: displayEmail, name: displayName
    }});
  }

  const validation = errors?.validation || {};

  return (
    <form onSubmit={onSubmit} className={formClass}>
      <div className={headingClass}>Details</div>

      <div className={rowClass}>
        <div className={cellClass}>
          <label htmlFor="username" className={labelClass}>Username</label>
          <Input
            type="text"
            id="username"
            value={displayUsername}
            onChange={e => setUsername(e.target.value)}
            className={inputClass}
            required
            errors={validation.username}
          />
        </div>
        <div className={cellClass}>
          <label htmlFor="email" className={labelClass}>Email</label>
          <Input
            type="email"
            id="email"
            value={displayEmail}
            onChange={e => setEmail(e.target.value)}
            className={inputClass}
            required
            errors={validation.email}
          />
        </div>
        <div className={cellClass}>
          <label htmlFor="name" className={labelClass}>Name</label>
          <Input
            type="text"
            id="name"
            value={displayName}
            onChange={e => setName(e.target.value)}
            className={inputClass}
            required
            errors={validation.name}
          />
        </div>
      </div>

      <Button className={buttonClass} type="submit" loading={updateUserMutation.loading}>
        Save Details
      </Button>
    </form>
  );
};

UserDetailsForm.propTypes = {
  formClass: PropTypes.string.isRequired,
  headingClass: PropTypes.string.isRequired,
  rowClass: PropTypes.string.isRequired,
  cellClass: PropTypes.string.isRequired,
  labelClass: PropTypes.string.isRequired,
  inputClass: PropTypes.string.isRequired,
  buttonClass: PropTypes.string.isRequired,
};

export default UserDetailsForm;