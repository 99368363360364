import { useState } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import Button from "./Button";
import { DELETE_PROJECT } from "../mutations";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

const DeleteProject = props => {

  const { project } = props;

  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [deleteProject, deleteProjectMutation] = useMutation(DELETE_PROJECT, {
    variables: {id: project.id},
    onCompleted: () => {
      setShowModal(false);
      navigate("/projects/");
    }
  });

  const text = `This will permanently delete the project and its samples (only those which you own), but not any associated analysis or data.`

  const primaryClass = "btn-primary bg-red-800 border-red-800 hover:bg-red-900 hover:border-red-900";
  const secondaryClass = "btn-secondary text-red-900 border-red-800 cursor-pointer hover:bg-red-800 hover:border-red-800";

  return (
    <div className="edit-section">
      <div className="edit-heading text-red-900">Deletion</div>
      <div className="text-sm mb-6 text-red-900">Permanently remove this project and its samples.</div>
      <button
        onClick={() => setShowModal(true)}
        className={`w-fit ${secondaryClass}`}
      >
        Remove Project
      </button>
      {showModal && (
        <Modal setShowModal={setShowModal} title={"Remove Project?"} text={text} className="max-w-lg">
          <div className="flex gap-3 mx-auto w-fit">
            <Button className={primaryClass} loading={deleteProjectMutation.loading} onClick={deleteProject}>Delete</Button>
            <button className={secondaryClass} onClick={() => setShowModal(false)}>Go Back</button>
          </div>
        </Modal>
      )}
    </div>
  );
};

DeleteProject.propTypes = {
  project: PropTypes.object.isRequired,
};

export default DeleteProject;