import { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { POSSIBLE_GROUPS } from "../queries";
import { ClipLoader } from "react-spinners";
import { useLazyQuery, useQuery } from "@apollo/client";
import searchIcon from "../images/search.svg";
import SelectableGroup from "./SelectableGroup";

const GroupSelector = props => {

  const { group, setGroup, inputClass } = props;

  const [show, setShow] = useState(false);
  const [location, setLocation] = useState([]);
  const [typedText, setTypedText] = useState("");
  const [possibleGroups, setPossibleGroups] = useState(null);
  const [count, setCount] = useState(null);
  const [page, setPage] = useState(1);
  const boxRef = useRef();
  const nameRef = useRef();
  const selectorRef = useRef();
  const timeout = useRef();
  const PER_PAGE = 10;

  const calculateLocation = () => {
    const main = document.querySelector("main");
    const rect = nameRef.current.getBoundingClientRect();
    const minimumTop = main.offsetTop;
    const maximumTop = window.innerHeight - (selectorRef.current?.offsetHeight || 0);
    const actualTop = Math.min(Math.max(minimumTop, rect.top), maximumTop);
    setLocation([rect.left, actualTop])
  }

  const clickOutside = e => {
    if (boxRef.current && !boxRef.current.contains(e.target)) setShow(false);
  }

  useEffect(() => {
    window.addEventListener("click", clickOutside);
    window.addEventListener("resize", calculateLocation);
    document.querySelector("main").addEventListener("scroll", calculateLocation);
    return () => {
      window.removeEventListener("click", clickOutside);
      window.removeEventListener("resize", calculateLocation);
      document.querySelector("main").removeEventListener("scroll", calculateLocation);
    }
  }, [])

  useEffect(calculateLocation, [show]);

  const { loading: initialLoading } = useQuery(POSSIBLE_GROUPS, {
    variables: {
      text: typedText, first: PER_PAGE * page, last: PER_PAGE
    },
    skip: !show || typedText.length || possibleGroups,
    onCompleted: data => {
      setPossibleGroups(data.searchGroups.edges.map(edge => edge.node));
      setCount(data.searchGroups.count);
    }
  })

  const [searchGroup, { loading: lazyLoading }] = useLazyQuery(POSSIBLE_GROUPS, {
    onCompleted: data => {
      const newGroup = data.searchGroups.edges.map(edge => edge.node);
      setPossibleGroups(newGroup);
      setCount(data.searchGroups.count);
    }
  });

  const [searchExtraGroup, { loading: extraLoading }] = useLazyQuery(POSSIBLE_GROUPS, {
    onCompleted: data => {
      const currentIds = possibleGroups.map(d => d.id);
      const newGroup = data.searchGroups.edges.map(edge => edge.node).filter(
        d => !currentIds.includes(d.id)
      );
      setPossibleGroups([...possibleGroups, ...newGroup]);
      setCount(data.searchGroups.count);
    }
  });

  const selectorClicked = e => {
    e.stopPropagation();
    e.preventDefault();
    calculateLocation();
    setShow(true);
  }

  const groupClicked = async group => {
    setShow(false);
    setGroup(group);
  }
  
  const loadMoreClicked = () => {
    searchExtraGroup({variables: {
      text: typedText, first: PER_PAGE * (page + 1), last: PER_PAGE
    }})
    setPage(page + 1);
  }

  const textEntered = e => {
    setTypedText(e.target.value);
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      searchGroup({variables: {
        text: e.target.value, first: PER_PAGE, last: PER_PAGE
      }})
    }, 500)
  }

  const isLoading = initialLoading || lazyLoading || extraLoading;
  const isMoreGroups = count !== null && possibleGroups && count > possibleGroups.length;

  return (
    <div ref={boxRef}>
      <input
        className={`${inputClass} font-medium text-[#3B59C3] placeholder:font-normal flex items-center cursor-pointer overflow-x-auto no-scroll`}
        onClick={selectorClicked}
        ref={nameRef}
        onMouseDown={e => e.preventDefault()}
        defaultValue={group ? group.name : ""}
        placeholder="Select group..."
      />
      {show && (
        <div
          className="border border-[#E8E8E8] fixed bg-[#FEFEFE] rounded pt-3 max-w-xl left-0 top-0 right-6 z-40 shadow"
          style={{left: location[0], top: location[1]}}
          ref={selectorRef}
        >
          <div className="relative px-3 mb-2">
            <input
              value={typedText}
              onChange={textEntered}
              placeholder="Search"
              className="bg-[#F3F3F3] font-medium py-2 pr-2 pl-8 w-full text-sm"
              autoFocus
            />
            <img src={searchIcon} className="w-3.5 top-3 -mt-px left-6 absolute z-40 " alt="" />
          </div>

          <div className="h-60 overflow-y-auto px-3 mb-1">
            {possibleGroups && (!isLoading || extraLoading) && possibleGroups.map(l => (
              <SelectableGroup key={l.id} group={l} onClick={groupClicked} />
            ))}
            {possibleGroups && possibleGroups.length === 0 && !isLoading && (
              <div className="text-[#B5B5B5] text-lg font-light font-inter text-center py-3 mt-20">
                No Matching Files Found
              </div>
            )}
            {isLoading && (
              <div className={`mx-auto w-fit ${extraLoading ? "py-1" : "py-3"}`}>
                <ClipLoader color="#3C59C3" size={extraLoading ? 20 : 30} />
              </div>
            )}
            <div
              className={`px-1 py-2 flex cursor-pointer justify-center text-primary-500 font-medium text-xs hover:bg-gray-100 rounded-md ${isMoreGroups && !isLoading ? "" : "hidden"}`}
              onClick={loadMoreClicked}
            >Load More</div>
          </div>
        </div>
      )}
    </div>
  )
};

GroupSelector.propTypes = {
  setGroup: PropTypes.func.isRequired,
  inputClass: PropTypes.string.isRequired,
  getFiles: PropTypes.bool,
};

export default GroupSelector;