import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { fileSize } from "../utils";
import { ReactComponent as RightIcon } from "../images/right.svg";
import moment from "moment/moment";
import OrganismIcon from "./OrganismIcon";

const Genome = props => {

  const { genome, organism } = props;

  const files = [
    ...(genome.fasta ? [genome.fasta] : []),
    ...(genome.gtf ? [genome.gtf] : []),
    ...genome.data
  ]

  return (
    <div className={`rounded pt-3 px-4 pb-2 ${organism ? "border border-[#DBDBDB]" : "bg-[#F9F9F9] rounded"} ${props.className || ""}`}>
      <div className="flex gap-6 items-start">
        {organism && (
          <OrganismIcon
            organism={organism}
            className="hidden w-14 h-auto mt-1.5 flex-shrink-0 md:block"
            fill="#37474F"
          />
        )}
        <div className="flex-grow">
          <div className="flex justify-between">
            {organism && (
              <div>
                <div className="text-xl font-medium md:text-2xl">{organism.name}</div>
                <div className="text-xs italic mb-2.5 md:text-sm">{organism.latinName}</div>
              </div>
            )}
            <Link className={`text-sm link font-medium md:text-base ${organism ? "" : "mb-1"}`} to={`/genomes/${genome.id}/`}>{genome.name}</Link>
          </div>
          <div className={`flex flex-col break-all ${organism ? "text-xs gap-0.5" : "gap-1 text-xs font-medium md:text-sm"}`}>
            {files.map(file => (
              <div key={file.id}>
                <Link to={`/data/${file.id}/`} className="text-[#3B59C3] link">{file.filename}</Link>{" "}
                <span className={organism ? "text-2xs" : "text-xs"}>{fileSize(file.size)}</span>
              </div>
            ))}
          </div>
        </div>
      </div>  
      <div className="border-t border-[#DBDBDB] mt-4 pt-1.5 flex justify-between">
        <div className={`whitespace-nowrap font-medium ${organism ? "text-sm md:text-base" : "text-xs md:text-sm"}`}>
          {moment(genome.created * 1000).format("D MMM YYYY")}
        </div>
        {organism && (
          <Link to={`/organisms/${organism.id}/`} className="text-[#3B59C3] link text-sm font-medium whitespace-nowrap">
            <span className="hidden md:inline">See more genomes</span>
            <RightIcon fill="#3B59C3" className="inline w-3 py-px h-auto md:ml-1.5 md:w-2.5" />
          </Link>
        )}
      </div>
    </div>
  );
};

Genome.propTypes = {
  genome: PropTypes.object.isRequired,
  organism: PropTypes.object,
};

export default Genome;