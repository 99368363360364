import { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { CREATE_GENOME, UPDATE_GENOME } from "../mutations";
import { parseError } from "../errors";
import { GENOMES_ADMIN } from "../queries";
import Button from "./Button";
import moment from "moment";

const GenomeForm = props => {

  const { genome, organismId, setShow } = props;

  const [name, setName] = useState(null);
  const [url, setUrl] = useState(null);
  const [created, setCreated] = useState(null);
  const [fasta, setFasta] = useState(null);
  const [gtf, setGtf] = useState(null);
  const [data, setData] = useState(null);
  const [errors, setErrors] = useState(null);

  const nameText = name === null ? genome ? genome.name : "" : (name || "");
  const urlText = url === null ? genome ? genome.url : "" : (url || "");
  const createdText = created === null ? genome ? moment(genome.created * 1000).format("YYYY-MM-DD") : "" : (created || "");
  const fastaText = fasta === null ? genome ? genome.fasta.id : "" : (fasta || "");
  const gtfText = gtf === null ? genome ? genome.gtf.id : "" : (gtf || "");
  const dataText = data === null ? genome ? genome.data.map(d => d.id).join(",") : "" : (data || "");

  const [createGenome, createGenomeMutation] = useMutation(CREATE_GENOME, {
    variables: {
      name: nameText, url: urlText, created: createdText, fasta: fastaText,
      gtf: gtfText, data: dataText?.split(","), organism: organismId
    },
    refetchQueries: [{query: GENOMES_ADMIN}],
    awaitRefetchQueries: true,
    onCompleted: () => setShow(false),
    onError: error => setErrors(parseError(error))
  });

  const [updateGenome, updateGenomeMutation] = useMutation(UPDATE_GENOME, {
    variables: {
      id: genome?.id, name: nameText, url: urlText, created: createdText, fasta: fastaText,
      gtf: gtfText, data: dataText?.split(","), organism: organismId
    },
    refetchQueries: [{query: GENOMES_ADMIN}],
    awaitRefetchQueries: true,
    onCompleted: () => setShow(false),
    onError: error => setErrors(parseError(error))
  });

  const onSubmit = e => {
    e.preventDefault();
    if (!genome) createGenome();
    if (genome) updateGenome();
  };

  const loading = createGenomeMutation.loading || updateGenomeMutation.loading;

  const blockClass = "w-full max-w-xs mb-2";
  const labelClass = "text-[#37474F] font-medium text-xs block cursor-pointer";
  const inputClass = "w-full border border-gray-300 rounded-sm px-2 py-1 text-xs";

  return (
    <form onSubmit={onSubmit} className="ml-6 w-48 mt-4">
      <div className={blockClass}>
        <label className={labelClass} htmlFor="name">Name</label>
        {errors && errors.validation?.name && (
          <div className="text-red-500 text-xs mb-2">{errors.validation.name[0]}</div>
        )}
        <input
          id="name"
          type="text"
          value={nameText}
          onChange={e => setName(e.target.value)}
          className={inputClass}
          autoComplete="off"
          required
        />
      </div>
      <div className={blockClass}>
        <label className={labelClass} htmlFor="url">URL</label>
        {errors && errors.validation?.url && (
          <div className="text-red-500 text-xs mb-2">{errors.validation.url[0]}</div>
        )}
        <input
          id="url"
          type="text"
          value={urlText}
          onChange={e => setUrl(e.target.value)}
          className={inputClass}
          autoComplete="off"
          required
        />
      </div>
      <div className={blockClass}>
        <label className={labelClass} htmlFor="created">Created</label>
        {errors && errors.validation?.created && (
          <div className="text-red-500 text-xs mb-2">{errors.validation.created[0]}</div>
        )}
        <input
          id="created"
          type="date"
          value={createdText}
          onChange={e => setCreated(e.target.value)}
          className={inputClass}
          autoComplete="off"
          required
        />
      </div>
      <div className={blockClass}>
        <label className={labelClass} htmlFor="fasta">Fasta ID</label>
        {errors && errors.validation?.fasta && (
          <div className="text-red-500 text-xs mb-2">{errors.validation.fasta[0]}</div>
        )}
        <input
          id="fasta"
          type="number"
          value={fastaText}
          onChange={e => setFasta(e.target.value)}
          className={inputClass}
          autoComplete="off"
          required
        />
      </div>
      <div className={blockClass}>
        <label className={labelClass} htmlFor="gtf">GTF ID</label>
        {errors && errors.validation?.gtf && (
          <div className="text-red-500 text-xs mb-2">{errors.validation.gtf[0]}</div>
        )}
        <input
          id="gtf"
          type="number"
          value={gtfText}
          onChange={e => setGtf(e.target.value)}
          className={inputClass}
          autoComplete="off"
          required
        />
      </div>
      <div className={blockClass}>
        <label className={labelClass} htmlFor="gtf">Data IDs</label>
        {errors && errors.validation?.data && (
          <div className="text-red-500 text-xs mb-2">{errors.validation.data[0]}</div>
        )}
        <input
          id="data"
          type="text"
          value={dataText}
          onChange={e => setData(e.target.value)}
          className={inputClass}
          autoComplete="off"
        />
      </div>
      <div className="flex gap-2 text-xs mt-1">
        <Button type="submit" className="btn-primary text-white py-1 px-2" loading={loading}>Save</Button>
        <Button type="button" className="btn-secondary text-[#54618D] py-1 px-2" onClick={() => setShow(false)}>Cancel</Button>
      </div>
    </form>
  );
};

GenomeForm.propTypes = {
  genome: PropTypes.object,
  organismId: PropTypes.string.isRequired,
  setShow: PropTypes.func.isRequired
};

export default GenomeForm;