import { useState } from "react";
import Base from "./Base";
import Tabs from "../components/Tabs";
import { useFetch, useDocumentTitle, useLazyFetch } from "../hooks";
import DataTable2 from "../components/DataTable2";
import { ClipLoader } from "react-spinners";

const YourDataPage = () => {

  const [tab, setTab] = useState("Owned");
  const [ownedDataPage, setOwnedDataPage] = useState(1);
  const [ownedDataCount, setOwnedDataCount] = useState(null);
  const [ownedData, setOwnedData] = useState(null);
  const [ownedDataFilter, setOwnedDataFilter] = useState(null);
  const [hasOwnedData, setHasOwnedData] = useState(null);
  const [sharedDataPage, setSharedDataPage] = useState(1);
  const [sharedDataCount, setSharedDataCount] = useState(null);
  const [sharedData, setSharedData] = useState(null);
  const [sharedDataFilter, setSharedDataFilter] = useState(null);
  const [hasSharedData, setHasSharedData] = useState(null);
  const pageSize = 50;

  useDocumentTitle("Your Data - Flow");

  const { loading: ownedDataLoading } = useFetch("/data/owned", {
    params: {page: ownedDataPage, count: pageSize, filter: ownedDataFilter},
    onCompleted: data => {
      setOwnedDataCount(data.count);
      setOwnedData(data.data);
      if (hasOwnedData === null) setHasOwnedData(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / pageSize);
      if (ownedDataPage > maxPossiblePage) setOwnedDataPage(maxPossiblePage || 1);
    }
  })

  const { loading: sharedDataLoading } = useFetch("/data/shared", {
    params: {page: sharedDataPage, count: pageSize, filter: sharedDataFilter},
    onCompleted: data => {
      setSharedDataCount(data.count);
      setSharedData(data.data);
      if (hasSharedData === null) setHasSharedData(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / pageSize);
      if (sharedDataPage > maxPossiblePage) setSharedDataPage(maxPossiblePage || 1);
    }
  })

  const [,postBulkDelete] = useLazyFetch("/data/owned", {
    params: {page: ownedDataPage, count: pageSize, filter: ownedDataFilter},
    onCompleted: data => {
      setOwnedDataCount(data.count);
      setOwnedData(data.data);
    }
  })

  const textClass = "text-sm mb-6 info sm:mb-8";

  const ownedLabel = (hasOwnedData === false) ? "You do not own any data yet." : "Data you own";
  const sharedLabel = (hasSharedData === false) ? "You do not have any data shared with you yet." : "Data shared with you";
  const showOwnedTable = (hasOwnedData === true);
  const showSharedTable = (hasSharedData === true);

  return (
    <Base>
      <h1>Your Data</h1>
      <Tabs labels={["Owned", "Shared"]} selected={tab} setSelected={setTab} />
      {tab === "Owned" && (
        <div>
          <div className={textClass}>{ownedLabel}</div>
          {ownedDataLoading && !showOwnedTable && (
            <div className="w-full h-48 flex justify-center items-center">
              <ClipLoader size={100} />
            </div>
          )}
          {showOwnedTable && (
            <DataTable2
              data={ownedData}
              page={ownedDataPage}
              pageSize={pageSize}
              setPage={setOwnedDataPage}
              totalCount={ownedDataCount}
              loading={ownedDataLoading}
              onFilter={s => setOwnedDataFilter(s || null)}
              onBulkDelete={postBulkDelete}
              noMessage="No owned data matches that term." 
            />
          )}
        </div>
      )}
      {tab === "Shared" && (
        <div>
          <div className={textClass}>{sharedLabel}</div>
          {sharedDataLoading && !showSharedTable && (
            <div className="w-full h-48 flex justify-center items-center">
              <ClipLoader size={100} />
            </div>
          )}
          {showSharedTable && (
            <DataTable2
              data={sharedData}
              page={sharedDataPage}
              pageSize={pageSize}
              setPage={setSharedDataPage}
              totalCount={sharedDataCount}
              loading={sharedDataLoading}
              onFilter={s => setSharedDataFilter(s || null)}
              noMessage="No shared data matches that term."
            />
          )}
        </div>
      )}
    </Base>
  );

}

YourDataPage.propTypes = {
  
};

export default YourDataPage;