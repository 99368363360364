import { useRef } from "react";
import PropTypes from "prop-types";
import { roundTo } from "round-to";

const ProgressBar = props => {

  const { progress, onCancel } = props;

  const timestamps = useRef([]);
  const remainingTime = useRef(null);
  timestamps.current.push([progress, new Date().getTime()]);

  const timeLeft = seconds => {
    const nearest5 = roundTo(seconds / 5, 0) * 5;
    if (nearest5 > 7200) {
      return "More than 2 hours"
    } else if (nearest5 > 3600 * 1.5) {
      return "2 hours"
    } else if (nearest5 > 3600) {
      return "1 hour"
    } else {
      const minutes = Math.floor(nearest5 / 60);
      if (minutes >= 1) {
        return `${minutes} min`
      } else {
        return "A few seconds";
      }
    }
  }

  const percent = n => `${roundTo(n * 100, 1)}%`;

  const SAMPLE_SIZE = 20;

  if (timestamps.current.length > SAMPLE_SIZE && timestamps.current.length % (SAMPLE_SIZE * 2) === 0) {
    const last10 = timestamps.current.slice(-SAMPLE_SIZE);
    const last10Duration = (last10[SAMPLE_SIZE - 1][1] - last10[0][1]) / 1000;
    const last10Progress = last10[SAMPLE_SIZE - 1][0] - last10[0][0];
    const remaining = 1 - progress;
    remainingTime.current = roundTo((remaining / last10Progress) * last10Duration, 0)
  }

  return (
    <div className="w-full relative">
      <div className="w-full h-10 bg-[#F3F3F3] rounded overflow-hidden flex items-center text-sm">
        <div
          className="h-full bg-[#3B59C3] text-right flex items-center justify-end transition-all duration-200"
          style={{
            width: `${progress * 100}%`,
            animation: progress === 1 ? undefined : "progress-bar-stripes 0.8s linear infinite",
            backgroundImage: progress === 1 ? undefined : "linear-gradient(45deg, hsla(0,0%,100%,.03) 25%, transparent 0, transparent 50%, hsla(0,0%,100%,.03) 0, hsla(0,0%,100%,.03) 75%, transparent 0,transparent)",
            backgroundSize: "1rem 1rem"
          }}
        >
          {progress > 0.4 && (
            <div className="text-white pr-2">{percent(progress)}</div>
          )}
        </div>
        {progress <= 0.4 && (
          <div className="text-[#3B59C3] pl-2">{percent(progress)}</div>
        )}
      </div>
      {progress !== 1 && (
        <div className="w-full text-xs flex justify-between absolute -bottom-5">
          {remainingTime.current === null && <div />}
          {remainingTime.current !== null && <div>{timeLeft(remainingTime.current)} remaining</div>} 
          <div className="link" onClick={onCancel}>Cancel</div>
        </div>
      )}
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ProgressBar;