import React, { useState, useEffect } from "react";
import { ApolloProvider, useQuery } from "@apollo/client";
import Plausible from "plausible-tracker";
import Div100vh from "react-div-100vh";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { PropagateLoader } from "react-spinners";
import { client } from "../api";
import { UserContext } from "../contexts";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/LoginPage";
import PasswordResetPage from "../pages/PasswordResetPage";
import PrivacyPage from "../pages/PrivacyPage";
import SignupPage from "../pages/SignupPage";
import TermsPage from "../pages/TermsPage";
import { TOKEN, USER } from "../queries";
import SettingsPage from "../pages/SettingsPage";
import NewGroupPage from "../pages/NewGroupPage";
import GroupPage from "../pages/GroupPage";
import EditGroupPage from "../pages/EditGroupPage";
import UploadPage from "../pages/UploadPage";
import NewProjectPage from "../pages/NewProjectPage";
import PipelinesPage from "../pages/PipelinesPage";
import UserPage from "../pages/UserPage";
import DataPage from "../pages/DataPage";
import PipelinePage from "../pages/PipelinePage";
import ExecutionPage from "../pages/ExecutionPage";
import SamplePage from "../pages/SamplePage";
import YourExecutionsPage from "../pages/YourExecutionsPage";
import YourDataPage from "../pages/YourDataPage";
import YourSamplesPage from "../pages/YourSamplesPage";
import YourProjectsPage from "../pages/YourProjectsPage";
import PublicProjectsPage from "../pages/PublicProjectsPage";
import PageNotFound from "../pages/PageNotFound";
import ProjectPage from "../pages/ProjectPage";
import SearchPage from "../pages/SearchPage";
import GenomesPage from "../pages/GenomesPage";
import OrganismPage from "../pages/OrganismPage";
import GenomePage from "../pages/GenomePage";
import EditProjectPage from "../pages/EditProjectPage";
import EditSamplePage from "../pages/EditSamplePage";
import EditDataPage from "../pages/EditDataPage";
import EditExecutionPage from "../pages/EditExecutionPage";
import PekaPage from "../pages/PekaPage";
import AdminPage from "../pages/AdminPage";

const App = () => {

  // Keep track of whether there is a logged in user
  const [user, setUser] = useState(null);

  // Get the current access token in the cache 
  const accessToken = client.cache.readQuery({query: TOKEN})?.accessToken;

  // Keep track of whether the current token is expired
  const [isExpired, setIsExpired] = useState(true);

  // Send request for access token if it is expired
  const tokenQuery = useQuery(TOKEN, {
    client,
    fetchPolicy: "network-only",
    skip: !isExpired,
    onCompleted: () => setIsExpired(false)
  });

  // If the token query has fired at least once, and log-in status is unknown,
  // try to get user
  useQuery(USER, {
    client,
    skip: tokenQuery.loading || user !== null,
    onCompleted: data => {
      setUser(data.me || false);
    }
  })

  useEffect(() => {
    // Whenever the access token changes, setup a new recurring function which
    // will check to see if it has expired or is getting old.

    const repeatingFunction = () => {
      if (accessToken && !isExpired) {
        const payload = jwt_decode(accessToken);
        const currentTimestamp = new Date().getTime() / 1000;
        const hasExpired = payload.expires < currentTimestamp;
        const validButOld = payload.iat + (60 * 3) < currentTimestamp;
        if (hasExpired) console.log("Token has expired, get a new one");
        if (validButOld) console.log("Token is getting old - refresh it");
        if (hasExpired || validButOld) setIsExpired(true);
      }
    }
    const intervalId = setInterval(repeatingFunction, 50);
    return () => clearInterval(intervalId);
  }, [accessToken, isExpired])

  useEffect(() => {
    if (window.location.hostname === "app.flow.bio") {
      const { enableAutoPageviews } = Plausible({domain: "app.flow.bio"});
      enableAutoPageviews();
    }
  }, [])

  // While log-in status is unknown, show loading page
  if (user === null) {
    return (
      <Div100vh className="flex items-center justify-center">
        <PropagateLoader color={"#4163DB"} />
      </Div100vh>
    )
  }

  return (
    <ApolloProvider client={client}>
      <UserContext.Provider value={[user, setUser]}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/privacy/" element={<PrivacyPage />} />
            <Route path="/terms/" element={<TermsPage />} />
            <Route path="/login/" element={<LoginPage />} />
            <Route path="/signup/" element={<SignupPage />} />
            <Route path="/password-reset/" element={<PasswordResetPage />} />

            {user && <Route path="/settings/" element={<SettingsPage />} />}
            {user && <Route path="/groups/new/" element={<NewGroupPage />} />}
            <Route path="/groups/@:slug/" element={<GroupPage />} />
            {user && <Route path="/groups/@:slug/edit/" element={<EditGroupPage />} />}
            <Route path="/users/:username/" element={<UserPage />} />

            {user && <Route path="/upload/" element={<UploadPage />} />}
            {user && <Route path="/projects/new/" element={<NewProjectPage />} />}
            <Route path="/pipelines/" element={<PipelinesPage />} />

            <Route path="/pipelines/:id/" element={<PipelinePage />} />
            <Route path="/projects/:id/" element={<ProjectPage />} />
            <Route path="/samples/:id/" element={<SamplePage />} />
            <Route path="/data/:id/" element={<DataPage />} />
            <Route path="/executions/:id/" element={<ExecutionPage />} />
            <Route path="/organisms/:id/" element={<OrganismPage />} />
            <Route path="/genomes/:id/" element={<GenomePage />} />
            
            {user && <Route path="/projects/:id/edit/" element={<EditProjectPage />} />}
            {user && <Route path="/samples/:id/edit/" element={<EditSamplePage />} />}
            {user && <Route path="/data/:id/edit/" element={<EditDataPage />} />}
            {user && <Route path="/executions/:id/edit/" element={<EditExecutionPage />} />}

            {user && <Route path="/projects/" element={<YourProjectsPage />} />}
            {user && <Route path="/samples/" element={<YourSamplesPage />} />}
            {user && <Route path="/executions/" element={<YourExecutionsPage />} />}
            {user && <Route path="/data/" element={<YourDataPage />} />}

            <Route path="/projects/public/" element={<PublicProjectsPage />} />
            <Route path="/genomes/" element={<GenomesPage />} />
            <Route path="/search/" element={<SearchPage />} />
            <Route path="/search/:type" element={<SearchPage />} />

            <Route path="/peka/" element={<PekaPage />} />

            {user && user.isAdmin && <Route path="/admin/" element={<AdminPage />} />}

            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </UserContext.Provider>
    </ApolloProvider>
  );
};

App.propTypes = {
  
};

export default App;