const blobToDataURL = blob => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => resolve(event.target.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(blob);
  });
}

export const base64Encode = async blob => {
  const dataUrl = await blobToDataURL(blob);
  const base64String = dataUrl.split(',')[1];
  const newBlob = new Blob([base64String])
  if (blob.name) return new File([newBlob], blob.name);
  return newBlob;
}