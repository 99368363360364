import React from "react";
import PropTypes from "prop-types";
import Toggle from "../components/Toggle";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { UPDATE_PROJECT } from "../mutations";
import { UPDATE_SAMPLE } from "../mutations";
import { UPDATE_EXECUTION } from "../mutations";
import { UPDATE_DATA } from "../mutations";
import { ClipLoader } from "react-spinners";

const PrivacyToggle = props => {

  const { object } = props;

  const objectType = object.__typename.replace("Type", "").toLowerCase();

  const MUTATION = {
    project: UPDATE_PROJECT,
    sample: UPDATE_SAMPLE,
    execution: UPDATE_EXECUTION,
    data: UPDATE_DATA,
  }[objectType];

  const [updateObject, updateObjectMutation] = useMutation(MUTATION, {

  });

  const forcedPublic = Boolean(
    object.forcedPublicProjectId || object.forcedPublicSampleId || object.forcedPublicExecutionId
  )

  const forcedPrivate = object.publicIssues?.length > 0 && object.private && !forcedPublic;

  const forcingObject = object.forcedPublicProjectId ? "project" :
  object.forcedPublicSampleId ? "sample" : object.forcedPublicExecutionId ?
  "execution" : "";

  const linkClass = "text-[#3B59C3] hover:underline";

  const toggleClicked = value => {
    updateObject({variables: {id: object.id, private: value}})
  }

  const infoClass = "info mb-2 pl-2 text-sm max-w-md md:text-base md:mb-4";

  const fieldMap = {
    scientist: "A scientist.",
    sequencer: "A sequencer.",
    source: "A validated cell/tissue type.",
    purification_target: "A validated protein purification target (category dependent)."
  }

  const missingFields = forcedPrivate ? object.publicIssues.map(issue => fieldMap[issue]).filter(Boolean) : [];

  return (
    <div className="edit-section border-t-0 pt-0 mt-0">
      <div className="edit-heading">Privacy</div>

      {forcedPublic && (
        <div className={infoClass}>
          This {objectType} must be <span className="font-semibold">public</span> because
          {object.forcedPublicProjectId ? (
            <span> its <Link to={`/projects/${object.forcedPublicProjectId}/`} className={linkClass}>project</Link> is public. </span>
          ) : object.forcedPublicSampleId ? (
            <span> its <Link to={`/samples/${object.forcedPublicSampleId}/`} className={linkClass}>sample</Link> is public. </span>
          ) : object.forcedPublicExecutionId && (
            <span> its <Link to={`/executions/${object.forcedPublicExecutionId}/`} className={linkClass}>execution</Link> is public. </span>
          )}
          If that {forcingObject} is made private, this {objectType} {object.private ? "will be private" : "can be made public too"}.
        </div>
      )}

      {forcedPrivate && (
        <div className={infoClass}>
          This {objectType} is <span className="font-semibold">private</span> and can't be made public because
          {objectType === "project" ? " one or more of its samples lack " : " it lacks "}
          certain metadata.
          {missingFields.length > 0 ? " For the sample to be public it must have:" : "  Problematic samples are:"}
          <ul className="list-disc list-inside text-sm mt-1">
            {object.publicIssues.map(issue => (
              <li key={issue}>{fieldMap[issue] || issue}</li>
            ))}
          </ul>
        </div> 
      )}

      {!forcedPublic && !forcedPrivate && (
        <div className="flex items-center gap-2">
          <Toggle
            value={object.private} onChange={toggleClicked}
            trueLabel="Private" falseLabel="Public"
            className={!updateObjectMutation.loading || "opacity-60 pointer-events-none"}
          />
          {updateObjectMutation.loading && <ClipLoader size={20} />}
      </div>
      )}
    </div>
  );
};

PrivacyToggle.propTypes = {
  object: PropTypes.object.isRequired
};

export default PrivacyToggle;