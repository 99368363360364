import React from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";

const AnnotationErrorModal = props => {

  const { errors, setShowModal } = props;

  return (
    <Modal setShowModal={setShowModal} className="max-w-xl bg-red-50 text-red-600" closable={true}>
      <div className="text-red-600 font-medium text-base mb-4 text-center">
        There were problems validating the annotation sheet contents:
      </div>
      {typeof errors.annotation === "string" && <div className="text-center text-xs">{errors.annotation}</div>}
      {typeof errors.annotation !== "string" && (
        <div className="flex flex-col gap-6">
          {errors.annotation.map((error, i) => {
            const hasError = Object.keys(error).length > 0;
            if (!hasError) return null;
            return (
              <div key={i} className="border border-red-600 text-red-700 rounded py-1 px-2">
                <div className="font-medium text-sm mb-1 ">Sample {i + 1}</div>
                <ul className="text-sm">
                  {Object.keys(error).map(key => (
                    <li key={key} className="list-disc ml-4">
                      <span className="font-medium ">{key}</span> <span className="text-xs text-red-600">{error[key]}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )
          })}
        </div>
      )}
    </Modal>
  );
};

AnnotationErrorModal.propTypes = {
  errors: PropTypes.object.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default AnnotationErrorModal;