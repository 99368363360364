import { useRef, useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { ReactComponent as SearchIcon } from "../images/search.svg";
import { QUICK_SEARCH } from "../queries";
import { BarLoader } from "react-spinners";
import QuickSearchResult from "./QuickSearchResult";
import { Link } from "react-router-dom";

const QuickSearcher = props => {

  const [text, setText] = useState("");
  const [results, setResults] = useState(null);
  const timeout = useRef();

  const [search, { data, loading }] = useLazyQuery(QUICK_SEARCH, {
    onCompleted: data => {
      setResults([...Object.values(data)].reduce(
        (p, c) => [...c.edges.map(e => e.node), ...p], []
      ))
    }
  });

  useEffect(() => {
    const dismiss = () => {
      setText("");
      setResults(null);
    }
    if (results) window.addEventListener("click", dismiss);
    return () => window.removeEventListener("click", dismiss);
  }, [results])
  
  const showResults = text.length >= 2;

  const keyUp = e => {
    const query = e.target.value;
    if (query.length >= 2) {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
      timeout.current = setTimeout(() => {
        search({variables: {text: query}});
      }, 750)
    } else {
      setResults(null);
    }
  }

  return (
    <div className={`flex flex-col px-8 items-end gap-2 w-full sm:flex-row sm:items-center sm:max-w-lg sm:px-0 ${props.className || ""}`}>
      <div className="w-full relative h-9 sm:max-w-md">
        {text.length > 0 && <div className="w-screen h-screen fixed bg-opacity-40 bg-gray-700 left-0 top-0 z-20" />}
        <SearchIcon fill="#B5B5B5" className="absolute w-4 h-full left-3 z-40" />
        <input
          className={`bg-[#F3F3F3] w-full pl-10 h-full text-[#727272] font-medium text-sm z-30 relative placeholder-[#B5B5B5] ${showResults ? "rounded-none rounded-t" : "rounded"}`}
          value={text}
          onKeyUp={keyUp}
          placeholder="Search"
          onChange={e => setText(e.target.value)}
        />
        {showResults && (
          <div className="bg-white max-h-96 border-b border-x overflow-y-auto border-[#F3F3F3] fixed top-30 left-2 right-2 z-50 rounded sm:w-full sm:absolute sm:rounded-t-none sm:left-0 sm:right-0 sm:top-9">
            {results === null && (
              <div className="h-10 flex items-center justify-center">
                <BarLoader color="#3B59C3" speedMultiplier={1.5} />
              </div>
            )}
            {results !== null && results.map((result, index) => (
              <QuickSearchResult object={result} query={text} key={index} />
            ))}
            {results !== null && results.length === 0 && (
              <div className="flex justify-center items-center h-10 text-sm text-[#8B8B8B]">No results</div>
            )}
          </div>
        )}
      </div>
      <Link className="link text-white text-xs whitespace-pre font-medium sm:text-[#54618D]" to="/search/">
        Advanced Search
      </Link>
    </div>
  );
};

QuickSearcher.propTypes = {
  
};

export default QuickSearcher;