import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";

const Notification = props => {

  const { notification } = props;

  const minorRowClass = "text-xs text-[#697395]";
  const mainRowClass = "block font-medium";


  const now = moment();
  const created = moment(notification.created * 1000);
  let time = "";
  if (now.diff(created, "days") >= 1) {
    time = created.format("MMM D");
  } else {
    time = created.format("h:mm A");
  }

  return (
    <div className={` h-fit py-1.5 px-1.5 bg-[#F6F6F6]`}>
      <div className={`flex items-stretch gap-2 px-1.5 py-1.5 rounded ${notification.hasRead ? "" : "bg-[#EEEEF0]"}`}>
        {!notification.hasRead && <div className="w-1 bg-[#3B59C3]" />}
        <div className="flex-grow">
          {notification.category === 1 && (
            <>
              <div className={minorRowClass}>
                <Link to={`/users/${notification.user.username}/`}>{notification.user.name}</Link> has invited you to join
              </div>
              <Link className={mainRowClass} to={`/groups/@${notification.group.slug}/`}>{notification.group.name}</Link>
            </>
          )}
          {notification.category === 2 && (
            <>
              <Link className={mainRowClass} to={`/users/${notification.user.username}/`}>{notification.user.name}</Link>
              <div className={minorRowClass}>
                accepted your invitation to join <Link to={`/groups/@${notification.group.slug}/`}>{notification.group.name}</Link> 
              </div>
            </>
          )}     
        </div>
        <time className="text-gray-400 text-2xs">{time}</time>
      </div>
    </div>
  );
};

Notification.propTypes = {
  notification: PropTypes.object.isRequired
};

export default Notification;