import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ReactComponent as PipelineIcon } from "../images/pipeline.svg";
import { ReactComponent as PipelineCategoryIcon } from "../images/pipeline-category.svg";
import PipelineTags from "./PipelineTags";

const PipelinesGrid = props => {

  const { name, description, pipelines } = props;

  

  return (
    <div>
      <div className="font-medium text-2xl mb-1.5 flex items-center gap-1.5">
        <PipelineCategoryIcon className="inline w-7 h-auto" />{name}
      </div>
      <div className="text-[#565656] mb-6 max-w-lg">{description}</div>

      <div className="flex flex-wrap gap-6">
        {pipelines.map(pipeline => (
          <Link
            to={`/pipelines/${pipeline.id}/`}
            key={pipeline.id}
            className="flex flex-col relative bg-[#FCFCFC] justify-between border border-[#E8E8E8] rounded py-3.5 px-3 w-full shadow-sm hover:shadow sm:w-96"
          >
            <PipelineTags pipeline={pipeline} small={true} className="absolute -top-2.5 right-1.5" />
  
            <div className="mb-3">
              <div className="font-medium text-lg mb-2 pb-1 border-b border-[#E8E8E8] ">
                <PipelineIcon className="inline w-8 h-auto mr-1" />
                {pipeline.name}
              </div>
              <div className="text-[#5C5C5C] text-sm">{pipeline.version.description}</div>
            </div>
            {pipeline.executionCount > 0 && (
              <div className="text-sm font-medium text-[#5C5C5C]">
                Run <span className="text-[#3B59C3]">{pipeline.executionCount} </span>
                time{pipeline.executionCount === 1 ? "" : "s"}
              </div>
            )}
          </Link>
        ))}
      </div>

    </div>
  );
};

PipelinesGrid.propTypes = {
  description: PropTypes.string.isRequired,
  pipelines: PropTypes.array.isRequired,
};

export default PipelinesGrid;