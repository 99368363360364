import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useDocumentTitle } from "../hooks";
import { DATA } from "../queries";
import Base from "./Base";
import PrivacyToggle from "../components/PrivacyToggle";
import FinishedEditingLink from "../components/FinishedEditingLink";
import Permissions from "../components/Permissions";
import { UserContext } from "../contexts";
import DeleteData from "../components/DeleteData";

const EditDataPage = () => {

  const { id } = useParams();

  const { loading, data } = useQuery(DATA, {variables: {id}});

  const [user,] = useContext(UserContext);

  useDocumentTitle(data?.data ? `${data.data.filename} - Flow` : "Flow");

  if (loading) return <Base loading={true} />

  if (!data.data) return <Base notFound={true} />

  if (!data.data.canEdit) return <Base notFound={true} />

  const dataFile = data.data;

  return (
    <Base>
      <div className="flex items-start break-all justify-between gap-4 mb-2 md:mb-4">
        <h1>{dataFile.filename}</h1>
        <FinishedEditingLink />
      </div>
      <PrivacyToggle object={dataFile} />

      {dataFile.canShare && (
        <Permissions object={dataFile} className="mt-12 pt-10 border-t border-[#e8e8e8]" />
      )}

      {dataFile.owner.id === user.id && <DeleteData data={dataFile} />}
    </Base>
  );
};

EditDataPage.propTypes = {
  
};

export default EditDataPage;