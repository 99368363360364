import PropTypes from "prop-types";
import PermissionEditor from "./PermissionEditor";

const Permissions = props => {

  const { object } = props;

  const borderClass = "2xl:mr-8 2xl:pr-8 2xl:border-r 2xl:border-[#e8e8e8]";

  return (
    <div className="edit-section">
      <div className="edit-heading">Permissions</div>
      <div className="flex flex-col gap-y-6 md:gap-y-12 2xl:flex-row">
        <PermissionEditor object={object} permission={1} className={borderClass} />
        <PermissionEditor object={object} permission={2} className={borderClass} />
        <PermissionEditor object={object} permission={3} className="" />
      </div>
    </div>
  );
};

Permissions.propTypes = {
  object: PropTypes.object.isRequired
};

export default Permissions;