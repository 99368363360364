import { useQuery } from "@apollo/client";
import React from "react";
import FlowCounts from "../components/FlowCounts";
import LatestNews from "../components/LatestNews";
import PublicPapers from "../components/PublicPapers";
import Quickstart from "../components/Quickstart";
import Welcome from "../components/Welcome";
import { useDocumentTitle } from "../hooks";
import { HOME } from "../queries";
import Base from "./Base";

const HomePage = () => {

  useDocumentTitle("Flow");

  const headingClass = "text-[#3B59C3] font-medium text-xl mb-3 tracking-normal xl:mb-4";

  const { loading, data } = useQuery(HOME);

  const papers = loading ? null : data.publicPapers.edges.map(e => e.node);
  const pipelineCount = loading ? null : data.pipelineCount;
  const userCount = loading ? null : data.userCount;
  const sampleCount = loading ? null : data.sampleCount;
  const executionCount = loading ? null : data.executionCount;
  const sampleCounts = loading ? null : data.sampleCounts;
  const news = loading ? null : data.news.edges.map(e => e.node);
  
  return (
    <Base>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full max-w-xl lg:border-r lg:border-[#E0E0E0] lg:pr-5 xl:pr-10">
          <Welcome headingClass={headingClass} />
          <Quickstart headingClass={headingClass} />
          <PublicPapers headingClass={headingClass} papers={papers} />
        </div>
        <div className="w-full lg:max-w-3xl lg:pl-5 xl:pl-10">
          <LatestNews headingClass={headingClass} news={news} />
          <FlowCounts
            headingClass={headingClass} sampleCounts={sampleCounts}
            pipelineCount={pipelineCount} userCount={userCount}
            sampleCount={sampleCount} executionCount={executionCount}
          />
        </div>
      </div>
    </Base>
  );
};

HomePage.propTypes = {
  
};

export default HomePage;