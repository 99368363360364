import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ReactComponent as SampleIcon } from "../images/sample.svg";

const ExecutionInputs = props => {

  const { execution } = props;

  const dataById = execution.upstream_data.reduce((p, c) => ({...p, [c.id]: c}), {});
  const samplesById = execution.upstream_samples.reduce((p, c) => ({...p, [c.id]: c}), {});

  const propertyClass = "flex flex-col items-baseline sm:table-row";
  const nameClass = "font-medium pr-2 table-cell py-0.5 text-right whitespace-nowrap";
  const valueClass = "text-sm table-cell sm:text-base";

  return (
    <div className="flex flex-col gap-1 sm:table">
      {[...Object.entries(execution.sample_params)].map(([name, values]) => {
        const samples = Array.isArray(values) ? values : Object.keys(values);
        return (
          <div key={name} className={propertyClass}>
            <div className={nameClass}>--{name}</div>
            <div className="flex whitespace-nowrap">
              {samples.map((sample, index) => {
                return (
                  <React.Fragment key={sample.id}>
                    {index !== 0 && ", "}
                    <Link className={`${valueClass} text-[#3B59C3] ${index === 0 || "ml-2"}`} to={`/samples/${sample.id}/`}>
                      <SampleIcon className="w-4 -mr-0.5 h-auto inline relative z-10 bottom-0.5" alt="" fill="#3B59C3" /> {sample.name}
                    </Link>
                  </React.Fragment>
                )
              })}
            </div>
          </div>
        )
      })}
      {[...Object.entries(execution.data_params)].map(([name, value]) => (
        <div key={name} className={propertyClass}>
          <div className={nameClass}>--{name}</div>
          {Array.isArray(value) ? (
            <div className="flex whitespace-nowrap">
              {value.map((d, index) => (
                <React.Fragment key={d.id}>
                  {index !== 0 && ", "}
                  <Link className={`${valueClass} text-[#3B59C3] ${index === 0 || "ml-2"}`} to={`/data/${d.id}/`}>
                    {d.filename}
                  </Link>
                </React.Fragment>
              ))}
            </div>
          ) : (
            <Link className={`${valueClass} text-[#3B59C3]`} to={`/data/${value.id}/`}>
              {value.filename}
            </Link>
          )}
        </div>
      ))}
      {[...Object.entries(execution.params)].map(([name, value]) => (
        <div key={name} className={propertyClass}>
          <div className={nameClass}>--{name}</div>
          <div className="font-mono text-sm">{value}</div>
        </div>
      ))}
    </div>
  );
};

ExecutionInputs.propTypes = {
  execution: PropTypes.object.isRequired
};

export default ExecutionInputs;