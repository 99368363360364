import { useState } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import Button from "./Button";
import { DELETE_SAMPLE } from "../mutations";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

const DeleteSample = props => {

  const { sample } = props;

  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [deleteSample, deleteSampleMutation] = useMutation(DELETE_SAMPLE, {
    variables: {id: sample.id},
    onCompleted: () => {
      setShowModal(false);
      navigate("/samples/");
    }
  });

  const text = `This will permanently delete the sample and its metadata, but not any associated analysis or data.`

  const primaryClass = "btn-primary bg-red-800 border-red-800 hover:bg-red-900 hover:border-red-900";
  const secondaryClass = "btn-secondary text-red-900 border-red-800 cursor-pointer hover:bg-red-800 hover:border-red-800";

  return (
    <div className="edit-section">
      <div className="edit-heading text-red-900">Deletion</div>
      <div className="text-sm mb-6 text-red-900">Permanently remove this sample and its metadata.</div>
      <button
        onClick={() => setShowModal(true)}
        className={`w-fit ${secondaryClass}`}
      >
        Remove Sample
      </button>
      {showModal && (
        <Modal setShowModal={setShowModal} title={"Remove Sample?"} text={text} className="max-w-lg">
          <div className="flex gap-3 mx-auto w-fit">
            <Button className={primaryClass} loading={deleteSampleMutation.loading} onClick={deleteSample}>Delete</Button>
            <button className={secondaryClass} onClick={() => setShowModal(false)}>Go Back</button>
          </div>
        </Modal>
      )}
    </div>
  );
};

DeleteSample.propTypes = {
  sample: PropTypes.object.isRequired,
};

export default DeleteSample;