import React, { useEffect , useRef, useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as BoxIcon } from "../images/upload-box.svg";
import { fileSize } from "../utils";

const FileInput = props => {

  const { file, setFile, errorMessage, accept, small } = props;

  const [hovering, setHovering] = useState(false);
  const ref = useRef();
  const labelRef = useRef();

  useEffect(() => {
    if (!file) ref.current.value = "";
  }, [file])

  const noSpaces = s => s.replace(/ /g, "_");

  const dragEnter = e => {
    e.preventDefault();
    setHovering(true);
  }

  const dragLeave = e => {
    e.preventDefault();
    setHovering(false);
  }

  const dragOver = e => {
    e.preventDefault();
  }

  const drop = e => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (accept) {
      const acceptable = accept.split(",");
      if (!acceptable.some(ext => file.name.slice(-ext.length) === ext)) {
        return
      }
    }
    ref.current.files = e.dataTransfer.files;
    setFile(e.dataTransfer.files[0]);
  }

  useEffect(() => {
    if (labelRef.current) {
      labelRef.current.addEventListener("dragleave", dragLeave, false);
      labelRef.current.addEventListener("dragenter", dragEnter, false);
      labelRef.current.addEventListener("dragover", dragOver, false);
      labelRef.current.addEventListener("drop", drop, false);
    }
  })

  const background = errorMessage ? "bg-[#F78080]" : file ? "bg-[#3B59C3] opacity-95" : "bg-[#F8F8F8] opacity-80";
  const border = (errorMessage || file) ? "border-0" : "border-3 border-dashed border-[#3B59C3]";
  const fileId = (Math.random() + 1).toString(36).substring(2);

  return (
    <label
      className={`cursor-pointer text-white px-3 flex w-full rounded justify-center items-center hover:opacity-100 ${hovering ? "opacity-100" : ""} ${background} ${border} ${small ? "h-24" : "h-48"} ${props.className || ""}`}
      htmlFor={fileId}
      ref={labelRef}
    >
      <input
        id={fileId}
        ref={ref}
        type="file"
        className="hidden"
        accept={accept}
        onChange={e => setFile(e.target.files[0])}
      />
      {!file && !errorMessage && <BoxIcon fill="#3B59C3" className={`${small ? "w-10" : "w-20"} h-auto`} />}
      {errorMessage && <div className="text-center">{errorMessage}</div>}
      {file && !errorMessage && (
        <div className={`break-all text-center ${file.name.length > 50 ? "text-base" : "text-lg"}`}>
            {noSpaces(file.name)} <span className="text-2xs break-normal">{fileSize(file.size)}</span>
        </div>
      )}
    </label>
  );
};

FileInput.propTypes = {
  file: PropTypes.object,
  setFile: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  accept: PropTypes.string,
  small: PropTypes.bool
};

export default FileInput;