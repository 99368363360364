import React, { useState } from "react";
import PropTypes from "prop-types";
import { POSSIBLE_SAMPLE_SOURCES, POSSIBLE_SAMPLE_PURIFICATION_TARGETS } from "../queries";
import Selector from "./Selector";
import Toggle from "./Toggle";
import NonStandardWarningModal from "./NonStandardWarningModal";
import Modal from "./Modal";

const SampleMetadataForm = props => {

  const {
    name, setName, category, setCategory, organism, setOrganism, organisms,
    project, setProject, projects, sourceId, setSourceId, sourceName, setSourceName,
    purificationTargetId, setPurificationTargetId, purificationTargetName, setPurificationTargetName,
    sourceText, setSourceText, purificationTargetText, setPurificationTargetText,
    scientist, setScientist, pi, setPi, organisation, setOrganisation,
    purificationAgent, setPurificationAgent, experimentalMethod, setExperimentalMethod,
    condition, setCondition, sequencer, setSequencer, comments, setComments,
    fivePrimeBarcodeSequence, setFivePrimeBarcodeSequence, threePrimeBarcodeSequence, setThreePrimeBarcodeSequence,
    threePrimeAdapterName, setThreePrimeAdapterName, threePrimeAdapterSequence, setThreePrimeAdapterSequence,
    read1Primer, setread1Primer, read2Primer, setRead2Primer,
    rtPrimer, setRtPrimer, umiBarcodeSequence, setUmiBarcodeSequence, umiSeparator, setUmiSeparator,
    strandedness, setStrandedness, rnaSelectionMethod, setRnaSelectionMethod,
    geo, setGeo, ena, setEna, pubmed, setPubmed,
    error, setError
  } = props;

  const sampleCategories = ["RNA-Seq", "scRNA-Seq", "ChIP-Seq", "CLIP"];

  const [nonStandard, setNonStandard] = useState(null);
  const [help, setHelp] = useState(null);

  const sectionClass = "flex flex-wrap gap-x-8 gap-y-6";
  const labelClass = "font-medium text-sm block mb-1"
  const inputClass = "bg-[#F3F3F3] block rounded outline-none text-sm px-2 py-1.5 w-full font-medium";
  const errorInputClass = `${inputClass} border border-red-500 bg-red-100 text-red-600`;
  const selectorClass = "bg-[#F3F3F3] block rounded outline-none text-sm w-full font-medium";
  const blockClass = "w-72";
  const optionsClass = "bg-[#F3F3F3] rounded-b-md";
  const optionClass = "py-0.5 px-2 cursor-pointer";

  const onSourceChange = ([sourceId, sourceName]) => {
    if (sourceId === null && sourceName) {
      setNonStandard(["cell/tissue type", sourceName, setSourceName]);
      setSourceId(null);
    } else {
      setSourceId(sourceId);
      setSourceName(sourceName);
    }
  }

  const onPurificationTargetChange = ([purificationTargetId, purificationTargetName]) => {
    if (purificationTargetId === null && purificationTargetName) {
      setNonStandard(["purification target", purificationTargetName, setPurificationTargetName]);
      setPurificationTargetId(null);
    } else {
      setPurificationTargetId(purificationTargetId);
      setPurificationTargetName(purificationTargetName);
    }
  }

  const organismToUse = organism || organisms[0]?.id;

  const fieldBlocks = [
    [
      {
        label: "Name",
        errorField: "name",
        helpText: "The name of the sample.",
        value: name,
        setValue: setName,
        isBoolean: false,
        isRequired: true,
        sampleTypes: [],
        options: null,
        defaultOptionText: null,
      },
      {
        label: "Category",
        errorField: "category",
        helpText: "The sample type. Some fields here are only relevant for certain sample types.",
        value: category,
        setValue: setCategory,
        isBoolean: false,
        isRequired: true,
        sampleTypes: [],
        options: sampleCategories,
        defaultOptionText: "Generic",
      },
      {
        label: "Organism",
        errorField: "organism",
        helpText: "The organism the sample contents are from.",
        value: organismToUse,
        setValue: setOrganism,
        isBoolean: false,
        isRequired: true,
        sampleTypes: [],
        options: organisms,
        defaultOptionText: null,
      },
      {
        label: "Project",
        errorField: "project",
        helpText: "A project of yours the sample should belong to. Useful for organisation and data sharing.",
        value: project,
        setValue: setProject,
        isBoolean: false,
        isRequired: true,
        sampleTypes: [],
        options: projects,
        defaultOptionText: "",
      }
    ],
    [
      {
        label: "Cell/Tissue",
        errorField: "source",
        helpText: "The cell or tissue type the sample contents are from. Flow has a list of approved terms, or you can enter your own.",
        value: sourceId,
        setValue: onSourceChange,
        isBoolean: false,
        isRequired: true,
        sampleTypes: [],
        options: {
          query: POSSIBLE_SAMPLE_SOURCES,
          text: sourceId ? null : sourceName,
          textParam: "name",
          displayProp: "name",
        },
        defaultOptionText: null,
      },
      {
        label: "Cell/Tissue Annotation",
        errorField: "source_text",
        helpText: "Any additional information about the cell or tissue type - this will be displayed after the cell/tissue with a colon.",
        value: sourceText,
        setValue: setSourceText,
        isBoolean: false,
        isRequired: false,
        sampleTypes: [],
        options: null,
        defaultOptionText: null,
      },
      {
        label: "Purification Target",
        errorField: "purification_target",
        helpText: "The target of the purification process. Flow has a list of approved terms, or you can enter your own.",
        value: purificationTargetId,
        setValue: onPurificationTargetChange,
        isBoolean: false,
        isRequired: false,
        sampleTypes: ["CLIP", "ChIP-Seq"],
        options: {
          query: POSSIBLE_SAMPLE_PURIFICATION_TARGETS,
          text: purificationTargetId ? null : purificationTargetName,
          textParam: "name",
          displayProp: "name",
        },
        defaultOptionText: null,
      },
      {
        label: "Purification Target Annotation",
        errorField: "purification_target_text",
        helpText: "Any additional information about the cell or tissue type - this will be displayed after the protein name with a colon.",
        value: purificationTargetText,
        setValue: setPurificationTargetText,
        isBoolean: false,
        isRequired: false,
        sampleTypes: ["CLIP", "ChIP-Seq"],
        options: null,
        defaultOptionText: null,
      }
    ],
    [
      {
        label: "Scientist",
        errorField: "scientist",
        helpText: "The name of the scientist who performed the experiment.",
        value: scientist,
        setValue: setScientist,
        isBoolean: false,
        isRequired: false,
        sampleTypes: [],
        options: null,
        defaultOptionText: null,
      },
      {
        label: "PI",
        errorField: "pi",
        helpText: "The name of the principal investigator who oversaw the experiment.",
        value: pi,
        setValue: setPi,
        isBoolean: false,
        isRequired: false,
        sampleTypes: [],
        options: null,
        defaultOptionText: null,
      },
      {
        label: "Organisation",
        errorField: "organisation",
        helpText: "The organisation the experiment was performed at.",
        value: organisation,
        setValue: setOrganisation,
        isBoolean: false,
        isRequired: false,
        sampleTypes: [],
        options: null,
        defaultOptionText: null,
      },
      {
        label: "Purification Method",
        errorField: "purification_agent",
        helpText: "The purification antibody used.",
        value: purificationAgent,
        setValue: setPurificationAgent,
        isBoolean: false,
        isRequired: false,
        sampleTypes: ["CLIP", "ChIP-Seq"],
        options: null,
        defaultOptionText: null,
      },
      {
        label: "Experimental Method",
        errorField: "experimental_method",
        helpText: "The experimental method used - each sample type typically has a number of specific variants.",
        value: experimentalMethod,
        setValue: setExperimentalMethod,
        isBoolean: false,
        isRequired: false,
        sampleTypes: [],
        options: null,
        defaultOptionText: null,
      },
      {
        label: "Condition",
        errorField: "condition",
        helpText: "The experimental condition.",
        value: condition,
        setValue: setCondition,
        isBoolean: false,
        isRequired: false,
        sampleTypes: [],
        options: null,
        defaultOptionText: null,
      },
      {
        label: "Sequencer",
        errorField: "sequencer",
        helpText: "The sequencer used to generate the data.",
        value: sequencer,
        setValue: setSequencer,
        isBoolean: false,
        isRequired: false,
        sampleTypes: [],
        options: null,
        defaultOptionText: null,
      },
      {
        label: "Comments",
        errorField: "comments",
        helpText: "Any additional comments about the sample.",
        value: comments,
        setValue: setComments,
        isBoolean: false,
        isRequired: false,
        sampleTypes: [],
        options: null,
        defaultOptionText: null,
      }
    ],
    [
      {
        label: "5' Barcode Sequence",
        errorField: "five_prime_barcode_sequence",
        helpText: "The 5' barcode sequence.",
        value: fivePrimeBarcodeSequence,
        setValue: setFivePrimeBarcodeSequence,
        isBoolean: false,
        isRequired: ["CLIP"],
        sampleTypes: [],
        options: null,
        defaultOptionText: null,
      },
      {
        label: "3' Barcode Sequence",
        errorField: "three_prime_barcode_sequence",
        helpText: "The 3' barcode sequence.",
        value: threePrimeBarcodeSequence,
        setValue: setThreePrimeBarcodeSequence,
        isBoolean: false,
        isRequired: false,
        sampleTypes: [],
        options: null,
        defaultOptionText: null,
      },
      {
        label: "3' Adapter",
        errorField: "three_prime_adapter_name",
        helpText: "The 3' adapter name.",
        value: threePrimeAdapterName,
        setValue: setThreePrimeAdapterName,
        isBoolean: false,
        isRequired: false,
        sampleTypes: [],
        options: null,
        defaultOptionText: null,
      },
      {
        label: "3' Adapter Sequence",
        errorField: "three_prime_adapter_sequence",
        helpText: "The 3' adapter sequence.",
        value: threePrimeAdapterSequence,
        setValue: setThreePrimeAdapterSequence,
        isBoolean: false,
        isRequired: false,
        sampleTypes: [],
        options: null,
        defaultOptionText: null,
      },
      {
        label: "Read 1 Primer",
        errorField: "read1_primer",
        helpText: "The Read 1 Primer.",
        value: read1Primer,
        setValue: setread1Primer,
        isBoolean: false,
        isRequired: false,
        sampleTypes: [],
        options: null,
        defaultOptionText: null,
      },
      {
        label: "Read 2 Primer",
        errorField: "read2_primer",
        helpText: "The Read 2 Primer.",
        value: read2Primer,
        setValue: setRead2Primer,
        isBoolean: false,
        isRequired: false,
        sampleTypes: [],
        options: null,
        defaultOptionText: null,
      },
      {
        label: "RT Primer",
        errorField: "rt_primer",
        helpText: "The reverse transcriptase primer.",
        value: rtPrimer,
        setValue: setRtPrimer,
        isBoolean: false,
        isRequired: false,
        sampleTypes: [],
        options: null,
        defaultOptionText: null,
      }
    ],
    [
      {
        label: "UMI Sequence",
        errorField: "umi_barcode_sequence",
        helpText: "The UMI barcode sequence.",
        value: umiBarcodeSequence,
        setValue: setUmiBarcodeSequence,
        isBoolean: false,
        isRequired: false,
        sampleTypes: ["RNA-Seq", "ChIP-Seq"],
        options: null,
        defaultOptionText: null,
      },
      {
        label: "UMI Separator",
        errorField: "umi_separator",
        helpText: "The UMI separator used in the barcode.",
        value: umiSeparator,
        setValue: setUmiSeparator,
        isBoolean: false,
        isRequired: false,
        sampleTypes: [],
        options: null,
        defaultOptionText: null,
      },
      {
        label: "Strandedness",
        errorField: "strandedness",
        helpText: "The strandedness of the sample.",
        value: strandedness,
        setValue: setStrandedness,
        isBoolean: false,
        isRequired: true,
        sampleTypes: ["RNA-Seq"],
        options: ["unstranded", "forward", "reverse", "auto"],
        defaultOptionText: null,
      },
      {
        label: "RNA Selection Method",
        errorField: "rna_selection_method",
        helpText: "The RNA selection method used.",
        value: rnaSelectionMethod,
        setValue: setRnaSelectionMethod,
        isBoolean: false,
        isRequired: false,
        sampleTypes: ["RNA-Seq"],
        options: ["polyA", "ribo-minus", "targeted"],
        defaultOptionText: null,
      }
    ],
    [
      {
        label: "GEO ID",
        errorField: "geo",
        helpText: "The accession of this sample on the Gene Expression Omnibus if present there.",
        value: geo,
        setValue: setGeo,
        isBoolean: false,
        isRequired: false,
        sampleTypes: [],
        options: null,
        defaultOptionText: null,
      },
      {
        label: "ENA ID",
        errorField: "ena",
        helpText: "The accession of this sample on the European Nucleotide Archive if present there.",
        value: ena,
        setValue: setEna,
        isBoolean: false,
        isRequired: false,
        sampleTypes: [],
        options: null,
        defaultOptionText: null,
      },
      {
        label: "PubMed ID",
        errorField: "pubmed",
        helpText: "The PMID for the paper associated with this sample.",
        value: pubmed,
        setValue: setPubmed,
        isBoolean: false,
        isRequired: false,
        sampleTypes: [],
        options: null,
        defaultOptionText: null,
      }
    ]
  ]

  const changeAndRemoveErrors = (onChange, value) => {
    setError(null);
    onChange(value);
  }

  const validation = error?.validation || {};

  return (
    <div className="flex flex-col gap-14">
      {fieldBlocks.map((fields, index) => (
        <div className={sectionClass} key={index}>
          {fields.filter(f => f.sampleTypes.length === 0 || f.sampleTypes.includes(category)).map(field => {
            const errorMessages = validation[field.errorField] || [];
            const hasError = errorMessages.length > 0;
            const isRequired = Array.isArray(field.isRequired) ? field.isRequired.includes(category) : field.isRequired;

            return (
              <div key={field.label} className={blockClass}>
                <label className={labelClass}>
                  {field.label}{!isRequired && " (Optional)"}
                  <span className="ml-1.5 w-4 h-4 text-2xs cursor-pointer rounded-full inline-flex bg-[#37474F] text-white justify-center items-center" onClick={() => setHelp(field)}>?</span>
                </label>
                <div>
                  {errorMessages.map((message, index) => (
                    <div className="text-red-500 text-xs mb-1" key={index}>{message}</div>
                  ))}
                </div>
                {field.options && Array.isArray(field.options) && (
                  <select
                    className={hasError ? errorInputClass : inputClass}
                    value={field.value}
                    onChange={e => changeAndRemoveErrors(field.setValue, e.target.value)}
                  >
                    {field.defaultText !== null && <option value="">{field.defaultText || ""}</option>}
                    {field.options.map(option => (
                      <option value={option?.id || option} key={option?.id || option}>
                        {option?.name || option}
                      </option>
                    ))}
                  </select>
                )}
                {field.options && !Array.isArray(field.options) && (
                  <Selector
                    value={field.value}
                    onChange={value => changeAndRemoveErrors(field.setValue, value)}
                    text={field.options.text}
                    returnText={true}
                    className={selectorClass}
                    inputClassName={hasError ? errorInputClass : inputClass}
                    optionsClassName={optionsClass}
                    optionClassName={optionClass}
                    query={field.options.query}
                    textParam={field.options.textParam}
                    pageLength={5}
                    displayProp={field.options.displayProp}
                  /> 
                )}
                {!field.isBoolean && !field.options && (
                  <input
                    value={field.value}
                    onChange={e => changeAndRemoveErrors(field.setValue, e.target.value)}
                    className={hasError ? errorInputClass : inputClass}
                  />
                )}
                {field.isBoolean && <Toggle value={field.value} onChange={field.setValue} trueLabel="Yes" falseLabel="No" />}
              </div>
            )
          })}
        </div>
      ))}
      {help && (
        <Modal setShowModal={setHelp} title={help.label} className="max-w-sm text-center" closable={true}>
          <div>{help.helpText}</div>
        </Modal>
      )}
      {nonStandard && (
        <NonStandardWarningModal
          setShowModal={() => setNonStandard(null)}
          attribute={nonStandard[0]}
          value={nonStandard[1]}
          setValue={nonStandard[2]}
        />
      )}
    </div>
  )
};

SampleMetadataForm.propTypes = {
  name: PropTypes.string,
  setName: PropTypes.func,
  category: PropTypes.string,
  setCategory: PropTypes.func,
  organism: PropTypes.string,
  setOrganism: PropTypes.func,
  organisms: PropTypes.array,
  project: PropTypes.string,
  setProject: PropTypes.func,
  projects: PropTypes.array,
  sourceId: PropTypes.string,
  setSourceId: PropTypes.func,
  sourceName: PropTypes.string,
  setSourceName: PropTypes.func,
  purificationTargetId: PropTypes.string,
  setPurificationTargetId: PropTypes.func,
  purificationTargetName: PropTypes.string,
  setPurificationTargetName: PropTypes.func,
  scientist: PropTypes.string,
  setScientist: PropTypes.func,
  pi: PropTypes.string,
  setPi: PropTypes.func,
  organisation: PropTypes.string,
  setOrganisation: PropTypes.func,
  purificationAgent: PropTypes.string,
  setPurificationAgent: PropTypes.func,
  experimentalMethod: PropTypes.string,
  setExperimentalMethod: PropTypes.func,
  condition: PropTypes.string,
  setCondition: PropTypes.func,
  sequencer: PropTypes.string,
  setSequencer: PropTypes.func,
  comments: PropTypes.string,
  setComments: PropTypes.func,
  fivePrimeBarcodeSequence: PropTypes.string,
  setFivePrimeBarcodeSequence: PropTypes.func,
  threePrimeBarcodeSequence: PropTypes.string,
  setThreePrimeBarcodeSequence: PropTypes.func,
  threePrimeAdapterName: PropTypes.string,
  setThreePrimeAdapterName: PropTypes.func,
  threePrimeAdapterSequence: PropTypes.string,
  setThreePrimeAdapterSequence: PropTypes.func,
  read1Primer: PropTypes.string,
  setread1Primer: PropTypes.func,
  read2Primer: PropTypes.string,
  setRead2Primer: PropTypes.func,
  rtPrimer: PropTypes.string,
  setRtPrimer: PropTypes.func,
  umiBarcodeSequence: PropTypes.string,
  setUmiBarcodeSequence: PropTypes.func,
  umiSeparator: PropTypes.string,
  setUmiSeparator: PropTypes.func,
  strandedness: PropTypes.string,
  setStrandedness: PropTypes.func,
  rnaSelectionMethod: PropTypes.string,
  setRnaSelectionMethod: PropTypes.func,
};

export default SampleMetadataForm;