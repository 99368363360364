import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { useDocumentTitle } from "../hooks";
import { GENOMES } from "../queries";
import Base from "./Base";
import Genome from "../components/Genome";

const GenomesPage = () => {

  useDocumentTitle("Genomes - Flow");

  const { data, loading } = useQuery(GENOMES);

  if (loading) return <Base loading={true} />

  const organisms = data.organisms;

  return (
    <Base>
      <h1>Genomes</h1>
      <div className="gap-4 flex flex-wrap md:gap-8">
        {organisms.filter(o => o.latestGenome).map(organism => (
          <Genome
            genome={organism.latestGenome} organism={organism} key={organism.id}
            className="w-full max-w-lg"
          />
        ))}
      </div>
    </Base>
  );
};

GenomesPage.propTypes = {
  
};

export default GenomesPage;