import { useContext, useState } from "react";
import Base from "./Base";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useDocumentTitle } from "../hooks";
import { SAMPLE, USER_OWNED_PROJECTS } from "../queries";
import PrivacyToggle from "../components/PrivacyToggle";
import FinishedEditingLink from "../components/FinishedEditingLink";
import Permissions from "../components/Permissions";
import SampleMetadataForm from "../components/SampleMetadataForm";
import Button from "../components/Button";
import { UPDATE_SAMPLE } from "../mutations";
import DeleteSample from "../components/DeleteSample";
import { UserContext } from "../contexts";
import { parseError } from "../errors";

const EditSamplePage = () => {

  const { id } = useParams();

  const { loading, data } = useQuery(SAMPLE, {variables: {id}});
  const { loading: projectsLoading, data: projectsData } = useQuery(USER_OWNED_PROJECTS);

  const [user,] = useContext(UserContext);
  const [error, setError] = useState(null);

  useDocumentTitle(data?.sample ? `${data.sample.name} - Flow` : "Flow");

  const [name, setName] = useState(null);
  const [category, setCategory] = useState(null);
  const [organism, setOrganism] = useState(null);
  const [project, setProject] = useState(null);

  const [sourceId, setSourceId] = useState(null);
  const [sourceName, setSourceName] = useState(null);
  const [sourceText, setSourceText] = useState(null);
  const [purificationTargetId, setPurificationTargetId] = useState(null);
  const [purificationTargetName, setPurificationTargetName] = useState(null);
  const [purificationTargetText, setPurificationTargetText] = useState(null);
  
  const [scientist, setScientist] = useState(null);
  const [pi, setPi] = useState(null);
  const [organisation, setOrganisation] = useState(null);
  const [purificationAgent, setPurificationAgent] = useState(null);
  const [experimentalMethod, setExperimentalMethod] = useState(null);
  const [condition, setCondition] = useState(null);
  const [sequencer, setSequencer] = useState(null);
  const [comments, setComments] = useState(null);

  const [fivePrimeBarcodeSequence, setFivePrimeBarcodeSequence] = useState(null);
  const [threePrimeBarcodeSequence, setThreePrimeBarcodeSequence] = useState(null);
  const [threePrimeAdapterName, setThreePrimeAdapterName] = useState(null);
  const [threePrimeAdapterSequence, setThreePrimeAdapterSequence] = useState(null);
  const [read1Primer, setread1Primer] = useState(null);
  const [read2Primer, setRead2Primer] = useState(null);
  const [rtPrimer, setRtPrimer] = useState(null);

  const [umiBarcodeSequence, setUmiBarcodeSequence] = useState(null);
  const [umiSeparator, setUmiSeparator] = useState(null);
  const [strandedness, setStrandedness] = useState(null);
  const [rnaSelectionMethod, setRnaSelectionMethod] = useState(null);

  const [geo, setGeo] = useState(null);
  const [ena, setEna] = useState(null);
  const [pubmed, setPubmed] = useState(null);

  const [updateSample, updateSampleMutation] = useMutation(UPDATE_SAMPLE, {
    refetchQueries: [{query: SAMPLE, variables: {id}}],
    onCompleted: () => setError(null),
    onError: error => {
      const errorObject = parseError(error);
      setError(errorObject);
    }
  });

  if (loading || projectsLoading) return <Base loading={true} />

  if (!data.sample) return <Base notFound={true} />

  if (!data.sample.canEdit) return <Base notFound={true} />

  const sample = data.sample;

  const save = () => {
    const variables = {
      id: sample.id, name: name, category: category, organism: organism, project,
      source: sourceName, purificationTarget: purificationTargetName,
      scientist: scientist, pi: pi, organisation: organisation,
      purificationAgent: purificationAgent, experimentalMethod: experimentalMethod,
      condition: condition, sequencer: sequencer, comments: comments,
      fivePrimeBarcodeSequence: fivePrimeBarcodeSequence,
      threePrimeBarcodeSequence: threePrimeBarcodeSequence,
      threePrimeAdapterName: threePrimeAdapterName,
      threePrimeAdapterSequence: threePrimeAdapterSequence,
      read1Primer: read1Primer,
      read2Primer: read2Primer,
      rtPrimer: rtPrimer, umiBarcodeSequence: umiBarcodeSequence,
      umiSeparator: umiSeparator, strandedness: strandedness,
      geo: geo, ena: ena, pubmed: pubmed,
      rnaSelectionMethod: rnaSelectionMethod, sourceText: sourceText,
      purificationTargetText: purificationTargetText
    }
    for (let key in variables) {
      if (![""].includes(key) && variables[key] === null) {
        delete variables[key];
      }
    }
    updateSample({variables})
  }

  return (
    <Base>
      <div className="flex items-start break-all justify-between gap-4 mb-2 md:mb-4">
        <h1>{sample.name}</h1>
        <FinishedEditingLink />
      </div>
      <PrivacyToggle object={sample} />

      <div className="edit-section">
        <div className="edit-heading">Metadata</div>
        {error?.message && <div className="text-red-500 font-medium mb-6">{error.message}</div>}
        <SampleMetadataForm
          organisms={data.organisms} projects={projectsData.ownedProjects.edges.map(e => e.node)}
          name={name === null ? sample.name : name} setName={setName}
          category={category === null ? sample.category : category} setCategory={setCategory}
          organism={organism === null ? sample.organism?.id || null : organism} setOrganism={setOrganism}
          project={project === null ? sample.project?.id : project} setProject={setProject}
          sourceId={sourceId === null && sourceName === null ? null : sourceId} setSourceId={setSourceId}
          sourceName={sourceName === null ? sample.source?.name : sourceName} setSourceName={setSourceName}
          sourceText={sourceText === null ? sample.sourceText : sourceText} setSourceText={setSourceText}
          purificationTargetId={purificationTargetId === null ? null : purificationTargetId} setPurificationTargetId={setPurificationTargetId}
          purificationTargetName={purificationTargetId === null ? sample.purificationTarget?.name : purificationTargetName} setPurificationTargetName={setPurificationTargetName}
          purificationTargetText={purificationTargetText === null ? sample.purificationTargetText : purificationTargetText} setPurificationTargetText={setPurificationTargetText}
          scientist={scientist === null ? sample.scientist : scientist} setScientist={setScientist}
          pi={pi === null ? sample.pi : pi} setPi={setPi}
          organisation={organisation === null ? sample.organisation : organisation} setOrganisation={setOrganisation}
          purificationAgent={purificationAgent === null ? sample.purificationAgent : purificationAgent} setPurificationAgent={setPurificationAgent}
          experimentalMethod={experimentalMethod === null ? sample.experimentalMethod : experimentalMethod} setExperimentalMethod={setExperimentalMethod}
          condition={condition === null ? sample.condition : condition} setCondition={setCondition}
          sequencer={sequencer === null ? sample.sequencer : sequencer} setSequencer={setSequencer}
          comments={comments === null ? sample.comments : comments} setComments={setComments}
          fivePrimeBarcodeSequence={fivePrimeBarcodeSequence === null ? sample.fivePrimeBarcodeSequence : fivePrimeBarcodeSequence} setFivePrimeBarcodeSequence={setFivePrimeBarcodeSequence}
          threePrimeBarcodeSequence={threePrimeBarcodeSequence === null ? sample.threePrimeBarcodeSequence : threePrimeBarcodeSequence} setThreePrimeBarcodeSequence={setThreePrimeBarcodeSequence}
          threePrimeAdapterName={threePrimeAdapterName === null ? sample.threePrimeAdapterName : threePrimeAdapterName} setThreePrimeAdapterName={setThreePrimeAdapterName}
          threePrimeAdapterSequence={threePrimeAdapterSequence === null ? sample.threePrimeAdapterSequence : threePrimeAdapterSequence} setThreePrimeAdapterSequence={setThreePrimeAdapterSequence}
          read1Primer={read1Primer === null ? sample.read1Primer : read1Primer} setread1Primer={setread1Primer}
          read2Primer={read2Primer === null ? sample.read2Primer : read2Primer} setRead2Primer={setRead2Primer}
          rtPrimer={rtPrimer === null ? sample.rtPrimer : rtPrimer} setRtPrimer={setRtPrimer}
          umiBarcodeSequence={umiBarcodeSequence === null ? sample.umiBarcodeSequence : umiBarcodeSequence} setUmiBarcodeSequence={setUmiBarcodeSequence}
          umiSeparator={umiSeparator === null ? sample.umiSeparator : umiSeparator} setUmiSeparator={setUmiSeparator}
          strandedness={strandedness === null ? sample.strandedness : strandedness} setStrandedness={setStrandedness}
          rnaSelectionMethod={rnaSelectionMethod === null ? sample.rnaSelectionMethod : rnaSelectionMethod} setRnaSelectionMethod={setRnaSelectionMethod}
          geo={geo === null ? sample.geo : geo} setGeo={setGeo}
          ena={ena === null ? sample.ena : ena} setEna={setEna}
          pubmed={pubmed === null ? sample.pubmed : pubmed} setPubmed={setPubmed}
          error={error} setError={setError}
        />
        <Button className="btn-primary mt-10" onClick={save} loading={updateSampleMutation.loading}>
          Save Metadata
        </Button>
      </div>

      {sample.canShare && (
        <Permissions object={sample} className="mt-12 pt-10 border-t border-[#e8e8e8]" />
      )}

      {sample.owner.id === user.id && <DeleteSample sample={sample} />}
    </Base>
  );
};

EditSamplePage.propTypes = {
  
};

export default EditSamplePage;