import { useState, useRef } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import { useLazyFetch } from "../hooks";
import { ClipLoader } from "react-spinners";
import moment from "moment";
import { ReactComponent as CloseIcon } from "../images/plus.svg";
import { fileSize } from "../utils";
import Checkbox from "./Checkbox";

const DataSelector2 = props => {

  const { data, setData, pattern, category, forceIsOwned, multiple, paired, locked, inputClass, placeholder, placeholderClass } = props;

  const [showModal, setShowModal] = useState(false);
  const [query, setQuery] = useState("");
  const [uploadsOnly, setUploadsOnly] = useState(false);
  const [ownedOnly, setOwnedOnly] = useState(true);
  const [possibleData, setPossibleData] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const timeout = useRef();
  const listRef = useRef();
  const pageCount = 20;

  const [{ loading }, searchData ] = useLazyFetch("/data/search", {
    onCompleted: data => {
      if (data.page > 1 && possibleData) {
        setPossibleData([...possibleData, ...data.data]);
      } else {
        setPossibleData(data.data);
      }
      setTotalCount(data.count);
    }
  });

  const search = params => {
    return searchData({params: {
      filename: query,
      owned: ownedOnly,
      uploaded: uploadsOnly,
      count: pageCount,
      category: category,
      paired: paired === undefined ? undefined : paired.toString(),
      pattern: pattern ? encodeURIComponent(pattern) : undefined,
      ...params
    }})
  }

  const textEntered = e => {
    setQuery(e.target.value);
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      search({filename: e.target.value});
    }, 500)
  }

  const openModal = () => {
    setShowModal(true);
    setQuery("");
    search();
  }

  const dataSelected = d => {
    if (multiple) {
      if (data && data.map(d => d.id).includes(d.id)) {
        setData(data.filter(d2 => d2.id !== d.id));
      } else {
        setData(data ? [...data, d] : [d]);
      }
    } else {
      if (data && data.id === d.id) {
        setData(null);
      } else {
        setData(d);
      }
      setShowModal(false);
    }
  }

  const toggleIsOwned = isOwned => {
    setOwnedOnly(isOwned);
    search({owned: isOwned})
  }

  const toggleIsUploaded = isUploaded => {
    setUploadsOnly(isUploaded);
    search({uploaded: isUploaded})
  }

  const clear = () => {
    setData(null);
    setShowModal(false);
  }

  const loadMore = async () => {
    const nextPage = Math.ceil(possibleData.length / pageCount) + 1;
    setLoadingMore(true);
    await search({page: nextPage});
    setLoadingMore(false);
    if (listRef.current) {
      setTimeout(() => listRef.current.scrollBy({
        top: 100, left: 0, behavior: "smooth"
      }), 100);
    }
  }

  const hasMore = possibleData && totalCount && possibleData.length < totalCount;

  return (
    <div>
      <div className={`${inputClass} cursor-pointer flex justify-center items-start flex-col gap-1 ${locked ? "pointer-events-none italic opacity-60" : ""}`} onClick={openModal}>
        {(!multiple && data) ? data.filename : ""}
        {multiple && data && data.length > 0 && data.map(d => (
          <div key={d.id}>{d.filename}{d.paired_filename && ` / ${d.paired_filename}`}</div>
        ))}
        {(multiple ? (!data || data.length === 0) : !data) && <span className={placeholderClass}>{placeholder}</span>}
      </div>
      {showModal && (
        <Modal setShowModal={setShowModal} className="w-full h-full flex flex-col px-4 py-2 max-w-6xl border border-[#E8E8E8] bg-[#FEFEFE]">
          <div className="flex flex-wrap items-center pt-1 pb-3 border-[#D3D3D3] justify-center gap-x-10 gap-y-2 text-sm text-[#595959] font-medium">
            {multiple && (
              <div
                onClick={() => setShowModal(false)}
                className="cursor-pointer opacity-60 hover:opacity-100"
              >
                Done
              </div>
            )}
            <div
              onClick={clear}
              className={`${(multiple ? data?.length : data) ? "cursor-pointer opacity-60 hover:opacity-100" : "opacity-30 pointer-events-none"}  `}
            >
              Clear
            </div>
            <Checkbox checked={uploadsOnly} onChange={toggleIsUploaded}>
              Limit to uploads
            </Checkbox>
            {!forceIsOwned && (
              <Checkbox checked={ownedOnly} onChange={toggleIsOwned}>
                Limit to data you own
              </Checkbox>
            )}
          </div>
          {multiple && data && (
            <div className="flex flex-wrap gap-1 mb-1.5">
              {data.map(d => (
                <div key={d.id} className="text-xs bg-blue-100 py-1 px-2 rounded">
                  <span>{d.filename}{d.paired_filename && ` / ${d.paired_filename}`}</span>
                  <CloseIcon
                    className="inline w-2.5 ml-1 h-auto rotate-45 fill-gray-500 cursor-pointer"
                    onClick={() => dataSelected(d)}
                  />
                </div>
              ))}
            </div>
          )}
          <input
            value={query} 
            className="bg-[#F3F3F3] flex-shrink-0 rounded mb-1.5 w-full h-9 text-[#3B59C3] font-medium px-3"
            onChange={textEntered}
            autoFocus
          />
          <div className="flex-grow overflow-auto" ref={listRef}>
            {possibleData && possibleData.length === 0 && !loading && (
              <div className="text-[#B5B5B5] text-lg font-light font-inter text-center py-3">
                No Matching Files Found
              </div>
            )}
            {possibleData && (!loading || loadingMore) && (
              <div className="">
                {possibleData.map(d => {
                  const isCurrent = multiple ? (data && data.map(d => d.id).includes(d.id)) : data?.id === d.id;
                  const processName = d.process_execution_name ? (
                    d.process_execution_name.split(":")[d.process_execution_name.split(":").length - 1]
                  ) : "";
                  return (
                    <div
                      key={d.id}
                      onClick={() => dataSelected(d)}
                      className={`group py-0.5 px-2 cursor-pointer rounded-md hover:bg-[#f7f7f7] ${isCurrent && "bg-[#f7f7f7]"}`}
                    >
                      <div className={`text-sm font-medium group-hover:text-[#3B59C3] sm:text-base ${isCurrent ? "text-[#3B59C3]" : "text-[#595959]"}`}>
                        {d.filename}
                        {d.paired_filename && ` / ${d.paired_filename}`}
                      </div>
                      <div className="text-2xs flex gap-5 leading-4 text-[#595F73] whitespace-nowrap sm:text-xs">
                        {d.sample_name && <span>{d.sample_name}</span>}
                        <span>{moment(d.created * 1000).format("D MMM YYYY")}</span>
                        {processName && <span>{processName}</span>}
                        <span>{d.owner_name}</span>
                        <span>{fileSize(d.size)}</span>
                      </div>
                    </div> 
                  )
                })}
                <div
                  className={`px-1 py-2 flex cursor-pointer justify-center text-primary-500 font-medium text-xs hover:bg-gray-100 rounded-md ${hasMore && !loading ? "" : "hidden"}`}
                  onClick={loadMore}
                >
                  Load More
                </div>
              </div>
            )}
            {loading && (
              <div className={`flex justify-center items-center ${loadingMore ? "h-10" : "h-20"}`}>
                <ClipLoader size={loadingMore ? 20 : 50} speedMultiplier={1.5} />
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

DataSelector2.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  setData: PropTypes.func.isRequired,
  pattern: PropTypes.string,
  category: PropTypes.number,
  forceIsOwned: PropTypes.bool,
  multiple: PropTypes.bool,
  paired: PropTypes.bool,
  locked: PropTypes.bool,
  inputClass: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderClass: PropTypes.string,
};

export default DataSelector2;