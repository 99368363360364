import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { UPLOAD_DATA } from "../mutations";
import { parseError } from "../errors";
import { Link, useNavigate } from "react-router-dom";
import Button from "./Button";
import { ReactComponent as UploadIcon } from "../images/upload.svg";
import ProgressBar from "./ProgressBar";
import FileInput from "./FileInput";
import { base64Encode } from "../upload";
import { useLazyFetch } from "../hooks";

const DataUploader = props => {

  const { redirect } = props;

  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(null);
  const [finalDataId, setFinalDataId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const canceled = useRef(false);
  const navigate = useNavigate();
 
  const CHUNK_SIZE = 1 * 1000 * 1000;

  const [uploadData2,] = useMutation(UPLOAD_DATA);

  const [,uploadData] = useLazyFetch("/upload", {
    method: "POST",
  })


  const noSpaces = s => s.replace(/ /g, "_")

  const onSubmit = async e => {
    e.preventDefault();
    canceled.current = false;
    const chunkCount = Math.ceil(file.size / CHUNK_SIZE);
    setProgress(0);
    let dataId = null;
    const chunkNums = [...Array(chunkCount).keys()];
    for (let c = 0; c < chunkNums.length; c++) {
      const chunkNum = chunkNums[c];
      const start = CHUNK_SIZE * chunkNum;
      const isLast = chunkNum === chunkCount - 1;
      const expectedFileSize = chunkNum * CHUNK_SIZE;


      const chunk = file.slice(start, start + CHUNK_SIZE);
      const isbase64 = ["true", "yes"].includes((process.env.REACT_APP_USE_BASE64 || "").toLowerCase());
      const blob = isbase64 ? await base64Encode(chunk) : chunk;

      let resp;
      const MAX_RETRIES = 5;
      for (let attempt = 0; attempt < MAX_RETRIES; attempt++) {
        try {
          resp = await uploadData({
            params: {
              blob, isLast, expectedFileSize, data: dataId,
              filename: noSpaces(file.name)
            },
            method: "POST",
            form: true
          })
          console.log(resp)

          /* resp = await uploadData({variables: {
          }}) */


          break;
        } catch (error) {
          const errorObject = parseError(error);
          if (errorObject.networkError) {
            if (attempt < MAX_RETRIES - 1) {
              await new Promise(resolve => setTimeout(resolve, 1000 * (attempt + 1)));
              continue;
            } else {
              setErrorMessage("There was a network error when uploading the file.");
            }
          } else {
            const message = errorObject.message || Object.values(errorObject.validation)[0][0];
            setErrorMessage("There was a problem uploading the file: " + message);
          }
        }
      }
      dataId = resp.data.uploadData.dataId;
      if (canceled.current === true) {
        canceled.current = false;
        return
      }
      setProgress((chunkNum + 1) / chunkCount);
      if (isLast) {
        if (redirect) {
          navigate(`/data/${dataId}/`);
        } else {
          setFinalDataId(dataId);
        }
      }
    }
  }

  const cancel = () => {
    setProgress(null);
    setFinalDataId(null);
    setFile(null);
    setErrorMessage("");
    canceled.current = true;
  }

  const reset = () => {
    setProgress(null);
    setFinalDataId(null);
    setFile(null);
    setErrorMessage("");
  }

  return (
    <form onSubmit={onSubmit} className="w-full sm:w-96">
      <FileInput file={file} setFile={setFile} errorMessage={errorMessage} className="mb-4" />
      {progress === null && (
        <Button type="submit" className={`btn-primary w-full gap-1.5 ${Boolean(file) || "opacity-30 pointer-events-none"}`} disabled={!file}>
          <UploadIcon className="relative bottom-px" /> Upload
        </Button>
      )}

      {progress !== null && (
        <ProgressBar progress={progress} onCancel={cancel} />
      )}

      {finalDataId && (
        <div className="flex justify-between mt-2 text-sm text-[#3B59C3]">
          <Link to={`/data/${finalDataId}/`}>View file</Link>
          <div className="link" onClick={reset}>Upload another</div>
        </div>
      )}
    </form>
  );
};

DataUploader.propTypes = {
  redirect: PropTypes.bool
};

export default DataUploader;