import React, { useContext } from "react";
import { UserContext } from "../contexts";
import { Link } from "react-router-dom";

const GroupsList = () => {

  const [user,] = useContext(UserContext);

  const groups = [...user.memberships];
  groups.sort((a, b) => b.isAdmin - a.isAdmin);
  const visibleGroups = groups.length <= 3 ? groups : groups.slice(0, 2);

  return (
    <div className="-mt-4 mb-4 pb-4 border-b border-[#F2F2F2]">
      <div className="text-base font-semibold text-[#595959] mb-1">Your groups</div>
      <div className="text-sm text-[#848484] flex flex-col gap-0.5">
        {visibleGroups.map(group => (
          <Link to={`/groups/@${group.slug}/`} key={group.id} className="w-fit">@{group.slug}</Link>
        ))}
        {groups.length > 3 && <div className="text-xs pt-px italic">...and {groups.length - 2} more</div>}
      </div>
    </div>
  );
};

GroupsList.propTypes = {
  
};

export default GroupsList;