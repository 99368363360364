import { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { POSSIBLE_SAMPLES } from "../queries";
import { ClipLoader } from "react-spinners";
import { useLazyQuery, useQuery } from "@apollo/client";
import searchIcon from "../images/search.svg";
import SelectableSample from "./SelectableSample";

const SampleSelector = props => {

  const { value, setValue, inputClass, organismId, setOrganism, categories, forceIsOwned, alwaysOpaque } = props;

  const [show, setShow] = useState(false);
  const [location, setLocation] = useState([]);
  const [typedText, setTypedText] = useState("");
  const [possibleSamples, setPossibleSamples] = useState(null);
  const [count, setCount] = useState(null);
  const [page, setPage] = useState(1);
  const boxRef = useRef();
  const nameRef = useRef();
  const selectorRef = useRef();
  const timeout = useRef();
  const PER_PAGE = 10;

  const calculateLocation = () => {
    const main = document.querySelector("main");
    const rect = nameRef.current.getBoundingClientRect();
    const minimumTop = main.offsetTop;
    const maximumTop = window.innerHeight - (selectorRef.current?.offsetHeight || 0);
    const actualTop = Math.min(Math.max(minimumTop, rect.top), maximumTop);
    setLocation([rect.left, actualTop])
  }

  const clickOutside = e => {
    if (boxRef.current && !boxRef.current.contains(e.target)) setShow(false);
  }

  useEffect(() => {
    window.addEventListener("click", clickOutside);
    window.addEventListener("resize", calculateLocation);
    document.querySelector("main").addEventListener("scroll", calculateLocation);
    return () => {
      window.removeEventListener("click", clickOutside);
      window.removeEventListener("resize", calculateLocation);
      document.querySelector("main").removeEventListener("scroll", calculateLocation);
    }
  }, [])

  useEffect(calculateLocation, [show]);

  const { loading: initialLoading } = useQuery(POSSIBLE_SAMPLES, {
    variables: {
      name: typedText, first: PER_PAGE * page, last: PER_PAGE,
      organism: organismId, categories, isOwned: forceIsOwned
    },
    skip: !show || typedText.length || possibleSamples,
    onCompleted: data => {
      setPossibleSamples(data.searchSamples.edges.map(edge => edge.node));
      setCount(data.searchSamples.count);
    }
  })

  const [searchSample, { loading: lazyLoading }] = useLazyQuery(POSSIBLE_SAMPLES, {
    onCompleted: data => {
      const newSample = data.searchSamples.edges.map(edge => edge.node);
      setPossibleSamples(newSample);
      setCount(data.searchSamples.count);
    }
  });

  const [searchExtraSample, { loading: extraLoading }] = useLazyQuery(POSSIBLE_SAMPLES, {
    onCompleted: data => {
      const currentIds = possibleSamples.map(d => d.id);
      const newSample = data.searchSamples.edges.map(edge => edge.node).filter(
        d => !currentIds.includes(d.id)
      );
      setPossibleSamples([...possibleSamples, ...newSample]);
      setCount(data.searchSamples.count);
    }
  });

  const selectorClicked = () => {
    calculateLocation();
    setShow(true);
  }

  const sampleClicked = async sample => {
    setShow(false);
    setValue(sample);
    setOrganism(sample.organism);
    setPossibleSamples(null);
  }
  
  const loadMoreClicked = () => {
    searchExtraSample({variables: {
      name: typedText, first: PER_PAGE * (page + 1), last: PER_PAGE,
      organism: organismId, categories, isOwned: forceIsOwned
    }})
    setPage(page + 1);
  }

  const textEntered = e => {
    setTypedText(e.target.value);
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      searchSample({variables: {
        name: e.target.value, first: PER_PAGE, last: PER_PAGE,
        organism: organismId, categories, isOwned: forceIsOwned
      }})
    }, 500)
  }

  const isLoading = initialLoading || lazyLoading || extraLoading;
  const isMoreSamples = count !== null && possibleSamples && count > possibleSamples.length;

  return (
    <div ref={boxRef}>
      <div
        className={`${inputClass} ${(value || alwaysOpaque) ? "" : "opacity-30 text-sm italic"} flex items-center cursor-pointer`}
        onClick={selectorClicked}
        ref={nameRef}
      >
        {value ? value.name : alwaysOpaque ? "" : "Click to Select Sample..."}
      </div>
      {show && (
        <div
          className="border border-[#E8E8E8] fixed bg-[#FEFEFE] rounded pt-3 max-w-xl left-0 top-0 right-6 z-40 shadow"
          style={{left: location[0], top: location[1]}}
          ref={selectorRef}
        >
          <div className="relative px-3 mb-2">
            <input
              value={typedText}
              onChange={textEntered}
              placeholder="Search"
              className="bg-[#F3F3F3] font-medium py-2 pr-2 pl-8 w-full text-sm"
              autoFocus
            />
            <img src={searchIcon} className="w-3.5 top-3 -mt-px left-6 absolute z-40 " alt="" />
          </div>

          <div className="h-60 overflow-y-auto px-3 mb-1">
            {possibleSamples && (!isLoading || extraLoading) && possibleSamples.map(l => (
              <SelectableSample key={l.id} sample={l} onClick={sampleClicked} />
            ))}
            {possibleSamples && possibleSamples.length === 0 && !isLoading && (
              <div className="text-[#B5B5B5] text-lg font-light font-inter text-center py-3 mt-20">
                No Matching Files Found
              </div>
            )}
            {isLoading && (
              <div className={`mx-auto w-fit ${extraLoading ? "py-1" : "py-3"}`}>
                <ClipLoader color="#3C59C3" size={extraLoading ? 20 : 30} />
              </div>
            )}
            <div
              className={`px-1 py-2 flex cursor-pointer justify-center text-primary-500 font-medium text-xs hover:bg-gray-100 rounded-md ${isMoreSamples && !isLoading ? "" : "hidden"}`}
              onClick={loadMoreClicked}
            >Load More</div>
          </div>
        </div>
      )}
    </div>
  )
};

SampleSelector.propTypes = {
  value: PropTypes.object,
  setValue: PropTypes.func.isRequired,
  inputClass: PropTypes.string.isRequired,
  organismId: PropTypes.string,
  setOrganism: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.number),
  forceIsOwned: PropTypes.bool,
  alwaysOpaque: PropTypes.bool
};

export default SampleSelector;