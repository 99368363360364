import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { UPDATE_PROJECT_PERMISSION } from "../mutations";
import { UPDATE_SAMPLE_PERMISSION } from "../mutations";
import { UPDATE_EXECUTION_PERMISSION } from "../mutations";
import { UPDATE_DATA_PERMISSION } from "../mutations";
import { useMutation } from "@apollo/client";
import { DATA, EXECUTION, PROJECT, SAMPLE } from "../queries";
import UserSelector from "./UserSelector";
import GroupSelector from "./GroupSelector";
import { ReactComponent as ReadIcon } from "../images/read.svg";
import { ReactComponent as EditIcon } from "../images/pencil.svg";
import { ReactComponent as ShareIcon } from "../images/share.svg";

const PermissionEditor = props => {

  const { object, permission } = props;

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const MUTATION = {
    "ProjectType": UPDATE_PROJECT_PERMISSION,
    "SampleType": UPDATE_SAMPLE_PERMISSION,
    "ExecutionType": UPDATE_EXECUTION_PERMISSION,
    "DataType": UPDATE_DATA_PERMISSION,
  }[object.__typename];

  const QUERY = {
    "ProjectType": PROJECT,
    "SampleType": SAMPLE,
    "ExecutionType": EXECUTION,
    "DataType": DATA,
  }[object.__typename];

  const PermissionIcon = {
    1: ReadIcon,
    2: EditIcon,
    3: ShareIcon,
  }[permission];

  const [updatePermission, updatePermissionMutation] = useMutation(MUTATION, {
    refetchQueries: [{query: QUERY, variables: {id: object.id}}],
    awaitRefetchQueries: true,
  });

  const removeUser = user => {
    updatePermission({
      variables: {
        permission: 0,
        user: user.id,
        id: object.id,
      }
    })
  }

  const removeGroup = group => {
    updatePermission({
      variables: {
        permission: 0,
        group: group.id,
        id: object.id,
      }
    })
  }

  const addUser = () => {
    updatePermission({
      variables: {
        permission,
        user: selectedUser.id,
        id: object.id,
      }
    })
    setSelectedUser(null);
  }

  const addGroup = () => {
    updatePermission({
      variables: {
        permission,
        group: selectedGroup.id,
        id: object.id,
      }
    })
    setSelectedGroup(null);
  }

  const objectType = object.__typename.replace("Type", "").toLowerCase();

  const permissionName = ["read", "edit", "share"][permission - 1];
  const usersList = ["usersWithReadAccess", "usersWithEditAccess", "usersWithShareAccess"][permission - 1];
  const groupsList = ["groupsWithReadAccess", "groupsWithEditAccess", "groupsWithShareAccess"][permission - 1];
  const users = object[usersList];
  const groups = object[groupsList];

  const indirectUsersList = ["usersWithIndirectReadAccess", "usersWithIndirectEditAccess", "usersWithIndirectShareAccess"][permission - 1];
  const indirectGroupsList = ["groupsWithIndirectReadAccess", "groupsWithIndirectEditAccess", "groupsWithIndirectShareAccess"][permission - 1];
  const indirectUsers = object[indirectUsersList];
  const indirectGroups = object[indirectGroupsList];

  const text = {
    read: `This determines who can access the ${objectType} when it is private - they will be able to view, download and use it, but not modify it or share it.`,
    edit: `This determines who can edit the ${objectType} - they will be able to read and modify its attributes, though not share it.`,
    share: `This determines who can share the ${objectType} - they will be able to read, modify and alter its permissions.`,
  }

  const columnClass = "";
  const headingClass = "font-medium text-sm";
  const nameClass = "inline-block text-sm text-[#3B59C3] w-fit";
  const removeClass = "text-xs text-red-500 cursor-pointer opacity-60 w-fit hover:opacity-100";
  const inputClass = "rounded-none text-sm px-0";
  const addRowClass = "flex gap-2";
  const addButtonClass = "btn-primary py-0 px-2 text-xs opacity-60 hover:opacity-100";

  return (
    <div key={permission} className={`w-full max-w-md 2xl:max-w-lg ${!updatePermissionMutation.loading || "opacity-50 pointer-events-none"} ${props.className || ""}`}>
      <div className="font-medium mb-1 flex items-center gap-1 text-[#3B59C3]">
        <PermissionIcon className="inline-block w-4 h-auto mr-1" fill="#3B59C3" />
        {permissionName[0].toUpperCase()}{permissionName.slice(1)} Access
      </div>
      <div className="text-xs mb-2 sm:text-sm">{text[permissionName]}</div>
      <div className="max-w-md sm:grid sm:grid-cols-2">
        <div className={`${columnClass}`}>
          <div className={headingClass}>Users</div>
          <div className="flex flex-col gap-2">
            {users.map(user => (
              <div key={user.id}>
                <Link key={user.id} to={`/users/${user.username}/`} className={nameClass}>{user.name}</Link>
                <div onClick={() => removeUser(user)} className={removeClass}>
                  Remove
                </div>
              </div>
            ))}
            <div className={addRowClass}>
              <UserSelector
                user={selectedUser}
                setUser={setSelectedUser}
                inputClass={inputClass}
              />
              {selectedUser && (
                <button onClick={addUser} className={addButtonClass}>Add</button>
              )}
            </div>
          </div>
        </div>
        <div className={columnClass}>
          <div className={headingClass}>Groups</div>
          <div className="flex flex-col gap-2">
            {groups.map(group => (
              <div key={group.id}>
                <Link key={group.id} to={`/groups/@${group.slug}/`} className={nameClass}>{group.name}</Link>
                <div onClick={() => removeGroup(group)} className={removeClass}>Remove</div>
              </div>
            ))}
            <div className={addRowClass}>
              <GroupSelector
                group={selectedGroup}
                setGroup={setSelectedGroup}
                inputClass={inputClass}
              />
              {selectedGroup && (
                <button onClick={addGroup} className={addButtonClass}>Add</button>
              )}
            </div>
          </div>
        </div>
      </div>
      {indirectUsers && indirectGroups && indirectUsers.length + indirectGroups.length > 0 && (
        <div className="text-xs text-gray-600 mt-5 2xl:text-sm">
          <div className="mb-0.5">The following have {permissionName} access to this {objectType} via something else:</div>
          <div className="flex flex-col gap-1 text-xs">
            {indirectUsers.map(user => (
              <Link key={user.id} to={`/users/${user.username}/`} className={nameClass}>{user.name}</Link>
            ))}
            {indirectGroups.map(group => (
              <Link key={group.id} to={`/groups/@${group.slug}/`} className={nameClass}>{group.name}</Link>
            ))}
          </div>
        </div>
      )}
    </div>
  )
};

PermissionEditor.propTypes = {
  object: PropTypes.object.isRequired,
  permission: PropTypes.number.isRequired,
};

export default PermissionEditor;