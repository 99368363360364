import React from "react";
import PropTypes from "prop-types";
import { fileSize, formatTimestamp, processName } from "../utils";
import { Link } from "react-router-dom";
import Checkbox from "./Checkbox";

const DataRow2 = props => {

  const { data, index, checked, onCheck } = props;

  const Cell = props => {
    const link = props.link !== false;
    const Element = link ? Link : "div";
    return (
      <td className={`whitespace-nowrap text-xs ${props.className || ""}`} onClick={props.onClick}>
        <Element to={`/data/${data.id}/`} className="block px-4 py-3">
          {props.children}
        </Element>
      </td>
    )
  }

  return (
    <tr className={`bg-opacity-80 hover:bg-opacity-100 ${index % 2 ? "bg-[#E7E9F0]" : "bg-[#F6F6F6]"}`}>
      <Cell onClick={onCheck} link={false}>
        <Checkbox checked={checked} onChange={() => {}} />
      </Cell>
      <Cell className="w-full">{data.filename}</Cell>
      <Cell>{fileSize(data.size)}</Cell>
      <Cell>{formatTimestamp(data.created)}</Cell>
      {data.owner_name && <Cell>{data.owner_name}</Cell>}
      <Cell>
        {!data.process_execution_name && "Uploaded"}
        {data.process_execution_name && (
          <>
            {data.pipeline_name}{" "}
            <span className="text-2xs text-gray-600">
             [{processName(data.process_execution_name || "")}]
            </span>
          </>
        )}
      </Cell>
      <Cell>{data.sample_name}</Cell>
    </tr>
  );
};

DataRow2.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  checked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
};

export default DataRow2;