import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { fileSize, getProcessName } from "../utils";

const SelectableData = props => {

  const { data, onClick } = props;

  const ellipsise = (string, max) => {
    if (string.length < max) return string;
    const slices = parseInt((max - 5) / 2);
    return string.slice(0, slices) + "..." + string.slice(-slices);
  }
  
  const sampleName = ellipsise(data.sample?.name || "", 25);
  const filename = ellipsise(data.filename, 75);

  return (
    <div
      onClick={() => onClick(data)}
      className="group py-0.5 px-2 cursor-pointer rounded-md hover:bg-[#f7f7f7]"
    >
      <div className="text-base font-medium text-[#595959] group-hover:text-[#3B59C3]">{filename}</div>
      <div className="text-xs flex gap-5 leading-4 text-[#595F73] whitespace-nowrap">
        {sampleName && <span>{sampleName}</span>}
        <span>{moment(data.created * 1000).format("D MMM YYYY")}</span>
        <span>{getProcessName(data)}</span>
        <span>{data.owner.name}</span>
        <span>{fileSize(data.size)}</span>
      </div>
    </div>
  );
};

SelectableData.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SelectableData;