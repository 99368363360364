import { useContext, useState } from "react";
import PropTypes from "prop-types";
import PipelineInputsSection from "./PipelineInputsSection";
import Button from "./Button";
import { UserContext } from "../contexts";
import { useLazyFetch } from "../hooks";
import { useNavigate } from "react-router-dom";

const PipelineInputsForm2 = props => {

  const { schema, versionId, nextflowVersion, genomes, genomePipelineVersions } = props;

  const [openSections, setOpenSections] = useState(null);
  const [params, setParams] = useState({});
  const [dataParams, setDataParams] = useState({});
  const [sampleParams, setSampleParams] = useState({});
  const [genomeId, setGenomeId] = useState(null);
  const [organism, setOrganism] = useState(null);
  const [user,] = useContext(UserContext);

  const serializeParams = (params, addDefaults, removeObj) => {
    const serialized = {};
    for (const [key, value] of Object.entries(params || {})) {
      if (value?.id) {
        serialized[key] = value.id;
      } else if (value?.data) {
        serialized[key] = {...value, data: value.data.map(d => d.id)};
      } else {
        serialized[key] = value;
      }
      if (removeObj) {
        for (const k of Object.keys(serialized[key])) {
          serialized[key] = {...serialized[key], [k]: Object.entries(serialized[key][k]).reduce(
            (p, [k2, v2]) => k2 === "obj" ? p : {...p, [k2]: v2}, {}
          )};
        }
      }
    }
    if (addDefaults) {
      for (let category of Object.values(schema.inputs)) {
        for (let [name, property] of Object.entries(category.properties)) {
          if (property.default === undefined && serialized[name] === undefined && property.type === "boolean") {
            serialized[name] = "false";
          }
          if (property.default !== undefined && serialized[name] === undefined) {
            serialized[name] = property.default.toString();
          };
        }
      }
    }
    return serialized;
  }

  const navigate = useNavigate();

  const [{ loading }, runPipeline] = useLazyFetch(`/pipelines/versions/${versionId}/run`, {
    method: "POST", onCompleted: execution => navigate(`/executions/${execution.id}/`),
    params: {
      params: serializeParams(params, true),
      data_params: serializeParams(dataParams),
      sample_params: serializeParams(sampleParams, false, true),
      nextflow_version: nextflowVersion,
      genome: genomeId
    }
  });

  const requiredParams = [...Object.values(schema.inputs)].reduce(
    (p, c) => [...p, ...[...Object.entries(c.properties)].filter(
      ([,prop]) => prop.required && !prop.default && !(prop.type === "hidden") && ![
        "file", "directory", "sample", "lane"
      ].includes(prop.type)
    ).map(([name,]) => name)], []
  )
  const requiredDataParams = [...Object.values(schema.inputs)].reduce(
    (p, c) => [...p, ...[...Object.entries(c.properties)].filter(
      ([,prop]) => prop.required && !(prop.type === "hidden") && ["file", "directory", "lane"].includes(prop.type)
    ).map(([name,]) => name)], []
  )
  const requiredSampleParams = [...Object.values(schema.inputs)].reduce(
    (p, c) => [...p, ...[...Object.entries(c.properties)].filter(
      ([,prop]) => prop.required && !(prop.type === "hidden") && prop.type === "sample"
    ).map(([name,]) => name)], []
  )

  const gotNeededParams = requiredParams.filter(name => params[name]).length === requiredParams.length;
  const gotNeededDataParams = requiredDataParams.filter(name => dataParams[name] && (
    !dataParams[name].data || Object.keys(dataParams[name].data).length
  )).length === requiredDataParams.length;
  const gotNeededSampleParams = requiredSampleParams.filter(
    name => sampleParams[name]
  ).length === requiredSampleParams.length;
  const canRun = user && gotNeededParams && gotNeededDataParams && gotNeededSampleParams && user.canRunPipelines;

  return (
    <div>
      <div className="flex flex-col mb-20">
        {Object.entries(schema.inputs).map(([key, section], index) => (
          <div key={key} className={`max-w-7xl border-[#E8E8E8] pt-8 pb-10 border-y ${index === 0 ? "" : "-mt-px"}`}>
            <PipelineInputsSection
              section={section} name={key}
              params={params} setParams={setParams}
              dataParams={dataParams} setDataParams={setDataParams}
              sampleParams={sampleParams} setSampleParams={setSampleParams}
              openSections={openSections || Object.entries(schema.inputs).filter(([_, section]) => !section.advanced).map(([key, _]) => key)}
              setOpenSections={setOpenSections}
              genomes={genomes} genomeId={genomeId} setGenomeId={setGenomeId}
              organism={organism} setOrganism={setOrganism}
              genomePipelineVersions={genomePipelineVersions}
            />
          </div>
        ))}
      </div>
      <Button
        className={`btn-primary px-8 py-2 ${canRun || "bg-[#D4D4D4] border-[#D4D4D4] pointer-events-none"}`}
        loading={loading}
        onClick={runPipeline}
      >
        {user ? user.canRunPipelines ? "Run Pipeline" : "Your account cannot run pipelines" : "Sign up or log in to run"}
      </Button>
    </div>
  );
};

PipelineInputsForm2.propTypes = {
  schema: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired,
  nextflowVersion: PropTypes.string.isRequired,
  genomes: PropTypes.array,
  genomePipelineVersions: PropTypes.array,
};

export default PipelineInputsForm2;