import { useState } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import Button from "./Button";
import { DELETE_DATA } from "../mutations";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { fileSize } from "../utils";

const DeleteData = props => {

  const { data } = props;

  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [deleteData, deleteDataMutation] = useMutation(DELETE_DATA, {
    variables: {id: data.id},
    onCompleted: () => {
      setShowModal(false);
      navigate("/data/");
    }
  });

  const text = `This will permanently delete the data ${data.filename} (${fileSize(data.size)}). If it has been used in any analysis a record will be retained of its metadata, but the contents will be erased.`

  const primaryClass = "btn-primary bg-red-800 border-red-800 hover:bg-red-900 hover:border-red-900";
  const secondaryClass = "btn-secondary text-red-900 border-red-800 cursor-pointer hover:bg-red-800 hover:border-red-800";

  return (
    <div className="edit-section">
      <div className="edit-heading text-red-900">Deletion</div>
      <div className="text-sm mb-6 text-red-900">Permanently remove this data - this will remove the file from Flow.</div>
      <button
        onClick={() => setShowModal(true)}
        className={`w-fit ${secondaryClass}`}
      >
        Remove Data
      </button>
      {showModal && (
        <Modal setShowModal={setShowModal} title={"Remove Data?"} text={text} className="max-w-lg">
          <div className="flex gap-3 mx-auto w-fit">
            <Button className={primaryClass} loading={deleteDataMutation.loading} onClick={deleteData}>Delete</Button>
            <button className={secondaryClass} onClick={() => setShowModal(false)}>Go Back</button>
          </div>
        </Modal>
      )}
    </div>
  );
};

DeleteData.propTypes = {
  data: PropTypes.object.isRequired,
};

export default DeleteData;